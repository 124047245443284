import moment from 'moment'

export default {
  methods: {
    checkDocumentation (courseId, registrationId) {
      this.loading = true
      var goToDocumentation = this.$router.push({
        name: 'Apostilas da documentação',
        params: {
          course_id: courseId,
          registration_id: registrationId
        }
      }).catch(() => {})

      let url = '/student/documentation_student/check/'

      this.$api
        .get(`${url}${courseId}/${registrationId}`)
        .then((res) => {
          if (!res.data.access) {
            return goToDocumentation
          } else if (moment().isBefore(`${this.course_loaded.start_content} 00:00:00`) && this.course_loaded.course_type === 1) {
            return goToDocumentation
          }

          return this.$router.push({
            name: 'Disciplinas',
            params: {
              course_id: courseId,
              registration_id: registrationId
            }
          }).catch(() => {})
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
