import Vue from 'vue'
import $store from '../store'

Vue.use({
  install (Vue) {
    Vue.prototype.$version = {
      startVersioning: () => $store.dispatch('version/startVersioning'),
      checkIfHasUpdate: () => $store.dispatch('version/checkIfHasUpdate'),
      version: () => $store.state.version.version,
      hasUpdate: () => $store.state.version.hasUpdate
    }
  }
})
