<template>
  <v-row>
    <v-col cols="12">
      <v-divider></v-divider>
      <span class="d-flex justify-center headline mt-8">DADOS PESSOAIS</span>
    </v-col>

    <v-col cols="12" md="8" sm="12" style="border: 1px solid red;">
      <v-text-field v-model="form_data.address">
        <template v-slot:label>
          ENDEREÇO RESIDENCIAL
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
        <CEP v-model="form_data.cep" :uf="true" :label="
        `CEP <span style='color: red; font-size: 24px'>*</span>`
        " @find="setAddress($event)" />
    </v-col>

    <v-col cols="12" md="6" sm="12" style="border: 1px solid red;">
      <v-text-field v-model="form_data.city">
        <template v-slot:label>
          CIDADE
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="12" md="2" sm="12" style="border: 1px solid red;">
      <v-select v-model="form_data.uf" :items="states">
        <template v-slot:label>
          UF
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-select>
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
      <Phone v-model="form_data.phone" color="blue" :label="
      `
        TELEFONE <span style='color: red; font-size: 24px'>*</span>
      `" />
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
      <Date v-model="form_data.birthday" :label="
      `DATA DE NASCIMENTO <span style='color: red; font-size: 24px'>*</span>`
      " />
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
      <v-select v-model="form_data.marital_status" :items="['CASADO(A)', 'SOLTEIRO(A)', 'DIVORCIADO(A)']">
        <template v-slot:label>
          ESTADO CIVIL
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-select>
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
      <v-text-field v-model="form_data.nationality">
        <template v-slot:label>
          NATURALIDADE/NACIONALIDADE
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="12" md="6" sm="12" style="border: 1px solid red;">
      <v-text-field v-model="form_data.mothers_name">
        <template v-slot:label>
          NOME DA MÃE
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="12" md="6" sm="12" style="border: 1px solid red;">
      <v-text-field v-model="form_data.fathers_name">
        <template v-slot:label>
          NOME DO PAI
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="12" md="8" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.work_company" label="EMPRESA ONDE TRABALHA (se estiver trabalhando)" />
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.work_role" label="CARGO" />
    </v-col>

    <v-col cols="12" md="8" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.work_company_address" label="ENDEREÇO DA EMPRESA" />
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid #000;">
      <CEP v-model="form_data.work_company_cep" :uf="true" label="ENDEREÇO DA EMPRESA" @find="setAddressWork($event)" />
    </v-col>

    <v-col cols="12" md="6" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.work_company_city" label="CIDADE" />
    </v-col>

    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-select v-model="form_data.work_company_uf" :items="states" label="UF"></v-select>
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid #000;">
      <Phone v-model="form_data.work_company_phone" color="blue" label="TELEFONE" />
    </v-col>

    <v-col cols="12" md="6" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.work_company_fax" label="FAX" />
    </v-col>

    <v-col cols="12" md="6" sm="12" style="border: 1px solid #000;">
      <Email v-model="form_data.work_company_email" label="Email" />
    </v-col>
  </v-row>
</template>

<script>
import CEP from '@/shared/components/text-fields/Address/CEP'
import MatriculationPersonalDataMixin from '@/app/components/Course/mixins/MatriculationPersonalData'
import Phone from '@/shared/components/text-fields/Phone'
import Date from '@/shared/components/text-fields/Date'
import Email from '@/shared/components/text-fields/Email'

export default {
  components: { CEP, Phone, Date, Email },
  mixins: [MatriculationPersonalDataMixin],
  data: () => ({
    states: [
      'AC',
      'AL',
      'AP',
      'AM',
      'BA',
      'CE',
      'DF',
      'ES',
      'GO',
      'MA',
      'MT',
      'MS',
      'MG',
      'PR',
      'PB',
      'PA',
      'PE',
      'PI',
      'RN',
      'RS',
      'RJ',
      'RO',
      'RR',
      'SC',
      'SE',
      'SP',
      'TO'
    ]
  }),
  methods: {
    setAddress (val) {
      this.form_data.city = val.city
      this.form_data.uf = val.state
    },
    setAddressWork (val) {
      this.form_data.work_company_city = val.city
      this.form_data.work_company_uf = val.state
    }
  }
}
</script>
