<template>
  <v-card>
    <v-card-title>
      <span>Resumo do Curso</span>
      <v-spacer></v-spacer>
      <v-btn class="mr-1" fab elevation="0" @click="changeAirPlaneStatus()">
        <v-icon v-if="airplane_status === 'expand'">mdi-airplane-landing</v-icon>
        <v-icon v-else>mdi-airplane-takeoff</v-icon>
      </v-btn>

      <v-btn v-if="$vuetify.breakpoint.smAndDown" fab small elevation="1" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-title>
      <span class="subtitle-2" style="margin-top: -65px">{{ course_loaded.name }}</span>
    </v-card-title>
    <v-card-text
      style="margin-left: -25px; margin-top: -20px"
      v-show="airplane_status === 'expand'"
    >
      <v-timeline dense right>
        <v-timeline-item
          v-for="(item, index) in disciplines"
          :key="index"
          :icon="icon(item, index)"
          :color="color(item, index)"
          :fill-dot="true"
        >
          <a
            v-if="item.position !== discipline.position"
            style="text-decoration: none; color: #686868"
            link
            class="mr-2 body-1 font-weight-bold"
            @click="openDiscipline(item)"
          >{{ item.name }}</a>
          <a
            v-if="item.position === discipline.position"
            style="text-decoration: none"
            class="mr-2 body-1 font-weight-bold"
            link
          >
            <b>{{ item.name }}</b>
          </a>
          <span v-if="item.percentage !== '0%'">{{ item.percentage }}</span>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { courseDisciplineProgress } from '../utils/Discipline'
import { mapState } from 'vuex'

export default {
  props: ['discipline'],
  data: () => ({
    course_id: undefined,
    registration_id: undefined,
    disciplines: undefined,
    airplane_status: 'expand',
    discipline_position: null
  }),
  computed: {
    ...mapState('course', ['course_loaded'])
  },
  methods: {
    openDiscipline (item) {
      this.$emit('changeDiscipline', item.position)
    },
    changeAirPlaneStatus () {
      if (this.airplane_status === 'expand') {
        this.airplane_status = 'retract'
      } else {
        this.airplane_status = 'expand'
      }
    },
    async courseDisciplineProgress () {
      await courseDisciplineProgress(
        this.$api,
        this.course_id,
        this.registration_id
      ).then((res) => {
        this.disciplines = res.data
      })
    },
    icon (item, index) {
      if (!item.release) {
        return 'mdi-lock'
      }

      if (item.position === 1 || item.percentage !== '0%') {
        return item.percentage === '100%' ? 'mdi-check' : 'mdi-view-list'
      }

      if (item.position !== 1 && item.percentage === '0%') {
        return this.disciplines[index - 1].percentage === '100%'
          ? item.percentage === '100%'
            ? 'mdi-check'
            : 'mdi-view-list'
          : 'mdi-lock'
      }
    },
    color (item, index) {
      if (!item.release) {
        return 'error'
      }

      if (item.position === 1) {
        return item.percentage === '100%' ? 'success' : 'primary'
      }

      if (item.position !== 1) {
        return this.disciplines[index - 1].percentage === '100%'
          ? item.percentage === '100%'
            ? 'success'
            : 'primary'
          : 'error'
      }
    }
  },
  beforeMount () {
    this.course_id = this.$router.currentRoute.params.course_id
    this.registration_id = this.$router.currentRoute.params.registration_id
    this.courseDisciplineProgress()
  }
}
</script>
