<template>
  <v-row>
    <v-col cols="2">
      <v-text-field
        v-bind="bindDDI"
        @input="inputDDI"
        v-mask="mask_ddi"
        color="primary"
        label="DDI"
        required
        @keyup.enter="$emit('enter', true)"
      ></v-text-field>
    </v-col>
    <v-col cols="10">
      <v-text-field
        ref="phone"
        v-bind="bindNumber"
        @input="inputNumber"
        v-mask="mask_phone"
        :rules="[rules.required, rules.length(13)]"
        color="primary"
        label="Telefone"
        required
        @keyup.enter="$emit('enter', true)"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import TextFieldDefaultMixin from './Mixins/textfield-default-mixin'
import { mask } from 'vue-the-mask'

export default {
  props: ['item'],
  mixins: [TextFieldDefaultMixin],
  directives: { mask },
  data: () => ({
    mask_ddi: '+##',
    mask_phone: '## #####-####',
    rules: {
      length: len => v => (v || '').length >= len || `Mínimo ${len} caracteres`,
      required: v => !!v || 'Este campo é obrigatório!'
    }
  }),
  $parseOutput ($event) {
    return $event
  },
  $parseInput (value) {
    return value
  },
  computed: {
    bindDDI () {
      return {
        ...this.$attrs,
        ...this.$props,
        value: this.$options.$parseInput(this.$props.item.ddi)
      }
    },
    bindNumber () {
      return {
        ...this.$attrs,
        ...this.$props,
        value: this.$options.$parseInput(this.$props.item.number)
      }
    }
  },
  methods: {
    inputDDI ($event) {
      const ddi = this.$options.$parseOutput($event)
      const number = this.$options.$parseInput(this.item.number)
      this.$emit('output', { ddi, number })
    },
    inputNumber ($event) {
      const ddi = this.$options.$parseInput(this.item.ddi)
      const number = this.$options.$parseOutput($event)
      this.$emit('output', { ddi, number })
    }
  }
}
</script>
