export default {
  methods: {
    checkMatriculation () {
      this.loading = true
      if (this.course_loaded.matriculation === 1) {
        this.$api
          .get(
            `/student/matriculation/check/${this.course_loaded.course_id}/${this.course_loaded.registration_id}`
          )
          .then((res) => {
            if (res.data.access === true) {
              this.checkCommitmentTerms()
            } else {
              this.matriculation_dialog = true
              this.loading = false
            }
          })
      } else {
        this.checkCommitmentTerms()
      }
    }
  }
}
