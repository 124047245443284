<template>
  <div>
    <v-dialog v-model="dialog" fullscreen hide-overlay retain-focus transition="dialog-bottom-transition">
      <v-card v-if="exams !== undefined">
        <v-toolbar dark color="primary">
          <v-toolbar-title class="ml-10">{{ exams.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">Cancelar prova</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="subtitle-1 font-weight-regular red--text text--lighten-1"
              text
              v-if="!examStarted"
            >Tempo: {{ exams.time }}</v-btn>
            <v-btn
              class="subtitle-1 font-weight-regular red--text text--lighten-1"
              text
              v-else
            >Tempo: {{ time | hms }}</v-btn>
          </v-card-actions>
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col cols="12" class="d-flex justify-center mt-10">
                <span :class="$vuetify.breakpoint.smAndDown ? 'title' : 'display-1'">{{ exams.title }}</span>
              </v-col>

              <v-col cols="12" class="d-flex justify-center mt-10">
                <!-- <p style="width: 65%">{{ exams.description }}</p> -->
              </v-col>

              <v-col cols="12" v-if="examStarted">
                <exam-questionary :exam="questions" @examEnd="examEnd($event)" :examTime="time" />
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-center mt-5"
                style="padding: 4px 6px"
                v-if="!examStarted"
              >
                <v-dialog v-model="alertExamStart" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="green" dark>Começar</v-btn>
                  </template>
                  <v-card>
                    <v-card-title
                      class="d-flex justify-center"
                    >Você tem certeza que deseja iniciar a prova?</v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>Se você iniciar a prova, você gastará uma tentativa. Se está for a sua última tentativa, não poderá mais fazer esta proxa neste módulo!</p>
                      </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="examStart()">Quero fazer a prova!</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="alertExamStart = false"
                      >Não quero fazer a prova agora!</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ExamQuestionary from './ExamQuestionary'
import { secondsToTime, timeToSeconds } from '../../../utils/Timer'

export default {
  props: ['exams', 'dialog'],
  components: { ExamQuestionary },
  filters: {
    hms (val) {
      return secondsToTime(val, 'h:m:s')
    }
  },
  data: () => ({
    examStarted: false,
    alertExamStart: false,
    time: 0,
    interval: undefined,
    examResult: {},
    course_id: undefined,
    registration_id: undefined,
    attempt_id: null,
    questions: []
  }),
  methods: {
    examStart () {
      this.$api
        .post('/student/final_exam_attempts/register', {
          final_exam_id: this.exams.id,
          registration_id: this.registration_id
        })
        .then((res) => {
          if (res.data.access === false) {
            this.$snackbar.error('Você já fez esta prova!')
          } else {
            this.questions.push(...this.exams.blocks.block1)
            this.questions.push(...this.exams.blocks.block2)
            this.questions.push(...this.exams.blocks.block3)
            this.questions.push(...this.exams.blocks.block4)
            this.attempt_id = res.data.id
            this.examStarted = true
            this.time = timeToSeconds(this.exams.time)
            this.interval = setInterval(() => {
              if (this.examStarted && this.dialog) {
                this.time--
              }
            }, 1000)
          }
        })
    },
    examEnd (payload) {
      this.examResult = {
        attempt_id: this.attempt_id,
        examEnd: Math.floor(new Date().getTime() / 1000),
        time: 3600 - this.time,
        results: [...payload]
      }
      this.time = 0
      this.examStarted = false
      this.alertExamStart = false
      clearInterval(this.interval)
      this.$api.post('/student/final_exam_attempts/finish', {
        final_exam_id: this.exams.id,
        registration_id: this.registration_id,
        exam: this.examResult
      })
      this.$emit('updateDiscipline')
      this.questions = []
    }
  },
  watch: {
    dialog (val) {
      if (val === false) {
        this.time = 3600
        this.examStarted = false
        this.alertExamStart = false
        clearInterval(this.interval)
      }
    }
  },
  beforeMount () {
    this.course_id = this.$router.currentRoute.params.course_id
    this.registration_id = this.$router.currentRoute.params.registration_id
  }
}
</script>
