<template>
  <v-autocomplete
    v-model="select"
    :filled="filled"
    :loading="loading"
    :items="list"
    :allow-overflow="false"
    :item-text="itemText"
    :item-value="itemValue"
    cache-items
    class="mx-4"
    clearable
    flat
    hide-no-data
    hide-details
    color="primary"
    @click:clear="clear()"
    :label="label"
  ></v-autocomplete>
</template>

<script>
export default {
  props: ['list', 'item', 'itemText', 'itemValue', 'label', 'filled'],
  data: () => ({
    loading: false,
    allItems: [],
    select: null
  }),
  watch: {
    item () {
      this.getItem()
    }
  },
  methods: {
    getItem () {
      if (this.item !== undefined && this.item !== null) {
        this.select = this.item.id
      }
    },
    clear () {
      this.$emit('clear')
      this.select = null
    }
  },
  updated () {
    this.$emit('output', this.select)
  },
  beforeMount () {
    this.getItem()
  }
}
</script>
