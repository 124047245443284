<template>
  <div class="text-center">
    <v-menu :close-on-content-click="false" :nudge-width="300" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          v-bind="attrs"
          v-on="on"
          fab
          elevation="0"
          color="#D8DADF"
          class="mr-2"
          @click.stop="openNotify()"
        >
          <v-badge color="error" v-if="notifies_unread > 0" overlap>
            <template slot="badge">{{ notifies_unread }}</template>
            <v-icon size="20" color="tertiary">mdi-bell</v-icon>
          </v-badge>
          <v-icon
            v-bind="attrs"
            v-on="on"
            size="20"
            color="tertiary"
            v-if="notifies_unread === 0"
          >mdi-bell</v-icon>
        </v-btn>
      </template>

      <v-card max-width="350px">
        <v-list shaped>
          <v-subheader>Notificações</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in notifies"
              :key="i"
              @click.stop="openNotifyDialog(item)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span v-text="item.title"></span>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>

    <v-dialog v-if="notify_dialog" persistent max-width="500px" v-model="notify_dialog">
      <v-card>
        <v-card-title class="d-flex justify-center">
          {{ selected.title }}
          <v-spacer></v-spacer>
          <v-btn fab small elevation="1" @click="notify_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-html="selected.notify"></v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    notify_dialog: false,
    notifies_interval: null,
    notifies: [],
    notifies_unread: 0,
    selected: []
  }),
  methods: {
    getNotify () {
      this.$api.get('/student/notifies').then((res) => {
        this.notifies = res.data
        this.notifies_unread = res.data.filter(
          (item) => item.read === 0
        ).length
      })
    },
    openNotify () {
      this.$api.get('/student/notifies/toggle').finally(() => {
        this.getNotify()
      })
    },
    openNotifyDialog (item) {
      this.selected = { ...item }
      this.notify_dialog = true
    }
  },
  beforeMount () {
    this.getNotify()
    this.notifies_interval = setInterval(() => {
      this.getNotify()
    }, 30000)
  },
  beforeDestroy () {
    clearInterval(this.notifies_interval)
  }
}
</script>
