import Vue from 'vue'
import $store from '../store'

Vue.use({
  install (Vue) {
    Vue.prototype.$userHistories = {
      setUserTimeLogged: (payload) => $store.dispatch('userHistories/setUserTimeLogged', payload),
      setHistory: (payload) => $store.dispatch('userHistories/setHistory', payload),
      start: (payload) => $store.dispatch('userHistories/start', payload),
      end: (payload) => $store.dispatch('userHistories/end', payload)
    }
  }
})
