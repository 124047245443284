import * as timer from '@/shared/utils/Timer'

export default {
  namespaced: true,
  state: {
    userTimeLogged: undefined,
    userTimeLoggedSeconds: '',
    history: []
  },
  getters: {
    //
  },
  mutations: {
    setUserTimeLogged (state, payload) {
      state.userTimeLogged = payload
      if (state.userTimeLogged !== undefined) {
        state.userTimeLoggedSeconds = timer.timeToSeconds(state.userTimeLogged.total_time_logged)

        setInterval(() => {
          state.userTimeLoggedSeconds++
        }, 1000)
      }
    },
    setHistory (state, payload) {
      state.history.push({
        id: payload.id,
        history: {
          id: null,
          start_time: 0,
          end_time: 0,
          time: 0,
          history_info: payload.text,
          tag: payload.tag,
          interval: null,
          interval_update: null
        }
      })
    },
    start (state, id) {
      if (state.userTimeLogged !== undefined) {
        var history = state.history.find(item => item.id === id)
        history.history.start_time = state.userTimeLoggedSeconds
        history.history.interval = setInterval(() => {
          history.history.time++
        }, 1000)

        history.history.end_time = history.history.time + history.history.start_time

        this._vm.$api.post('/student/user_histories', {
          user_time_logged_id: state.userTimeLogged.id,
          history_info: history.history.history_info,
          start_time: timer.secondsToTime(history.history.start_time, 'h:m:s'),
          end_time: timer.secondsToTime(history.history.end_time, 'h:m:s'),
          total_time: timer.secondsToTime(history.history.time, 'h:m:s'),
          tag: history.history.tag
        }).then(res => {
          history.history.id = res.data.id
        })

        history.history.interval_update = setInterval(() => {
          history.history.end_time = history.history.time + history.history.start_time

          this._vm.$api.patch('/student/user_histories/' + history.history.id, {
            user_time_logged_id: state.userTimeLogged.id,
            history_info: history.history.history_info,
            start_time: timer.secondsToTime(history.history.start_time, 'h:m:s'),
            end_time: timer.secondsToTime(history.history.end_time, 'h:m:s'),
            total_time: timer.secondsToTime(history.history.time, 'h:m:s')
          })
        }, 30000)
      }
    },
    end (state, id) {
      let history = state.history.find(item => item.id === id)

      if (history !== undefined) {
        clearInterval(history.history.interval)
        clearInterval(history.history.interval_update)
        state.history = state.history.filter(item => item.id !== id)
      }
    }
  },
  actions: {
    setUserTimeLogged ({ commit }, userTimeLogged) {
      commit('setUserTimeLogged', userTimeLogged)
    },
    setHistory ({ commit }, id, text) {
      commit('setHistory', id, text)
    },
    start ({ commit }, id) {
      commit('start', id)
    },
    end ({ commit }, id) {
      commit('end', id)
    }
  }
}
