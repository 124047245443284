export default {
  namespaced: true,
  state: {
    started: false,
    errorInExam: false,
    time: 0,
    timeInExam: 0,
    intervalTime: null,
    intervalTimeInExam: null,
    attempt_id: null,
    result: null,
    resultLoaded: false,
    intervalResult: null
  },
  getters: {
    //
  },
  mutations: {
    async start (state, payload) {
      var selfState = state

      this._vm.$loading.load('Iniciando a prova...')

      if (this._vm.$pingModule.status() === 'high') {
        selfState.errorInExam = true
        this._vm.$loading.loaded()
        return
      }

      await this._vm.$api.post('/student/exam/attempts', {
        exam_id: payload.opened_exam_id,
        registration_id: payload.registration_id
      }).then(res => {
        selfState.attempt_id = res.data.id
      })
        .catch(() => {
          selfState.errorInExam = true
          this._vm.$loading.loaded()
        })
        .finally(() => {
          if (selfState.attempt_id !== null) {
            selfState.started = true
            this._vm.$loading.loaded()
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          }
        })
    },
    async end (state, payload) {
      state.started = false
      var selfState = state

      await this._vm.$api.post('/student/exam/report', {
        exam_id: payload.opened_exam_id,
        registration_id: payload.registration_id,
        attempt_id: state.attempt_id
      }).then(res => {
        selfState.result = res.data
      }).finally(() => {
        selfState.resultLoaded = true
        selfState.intervalResult = setTimeout(() => {
          selfState.result = null
        }, 180000)
        this._vm.$loading.loaded()
      })
    },
    clearResult (state) {
      clearInterval(state.intervalResult)
      state.result = null
    },
    cancelExam (state) {
      state.started = false
    },
    setTime (state, payload) {
      state.time = payload
    },
    startTime (state) {
      state.intervalTime = setInterval(() => {
        if (state.time > 0) {
          state.time--
        }
      }, 1000)

      state.intervalTimeInExam = setInterval(() => {
        state.timeInExam++
      }, 1000)
    },
    endTime (state) {
      clearInterval(state.intervalTime)
      clearInterval(state.intervalTimeInExam)
      state.time = 0
      state.timeInExam = 0
    },
    setErrorInExam (state, payload) {
      state.errorInExam = payload
    }
  },
  actions: {
    start ({ commit }, payload) {
      commit('start', payload)
    },
    end ({ commit }, payload) {
      commit('end', payload)
    },
    clearResult ({ commit }) {
      commit('clearResult')
    },
    cancelExam ({ commit }) {
      commit('cancelExam')
    },
    setTime ({ commit }, message) {
      commit('setTime', message)
    },
    startTime ({ commit }) {
      commit('startTime')
    },
    endTime ({ commit }) {
      commit('endTime')
    },
    setErrorInExam ({ commit }, message) {
      commit('setErrorInExam', message)
    }
  }
}
