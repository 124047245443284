export default {
  $parseOutput ($event) {
    return $event
  },
  $parseInput (value) {
    return value
  },
  computed: {
    bind () {
      return {
        ...this.$attrs,
        ...this.$props,
        value: this.$options.$parseInput(this.$props.value)
      }
    }
  },
  methods: {
    input ($event) {
      this.$emit('input', this.$options.$parseOutput($event))
    }
  }
}
