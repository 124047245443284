<template>
  <v-form>
    <v-card v-if="startedPayment === false">
      <v-card-title v-if="!onlySubscription"
        >Compra do curso: {{ course_loaded.name }}</v-card-title
      >
      <v-card-title v-else>Assinatura {{ course_loaded.name }}</v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <span class="body-1 d-flex justify-center">Valor a pagar</span>
        <span class="title d-flex justify-center primary--text">{{
          value | currency
        }}</span>
        <br />
        <span class="d-flex justify-center"
          >Selecione o método de pagamento</span
        >
        <v-radio-group v-model="paymentMethod" :mandatory="false">
          <v-radio v-if="!(course_loaded.id === 21)" label="Cartão de Crédito" :value="1"></v-radio>
          <v-radio
            v-if="!(course_loaded.id === 42)"
            label="Boleto"
            :value="2"
          ></v-radio>
        </v-radio-group>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="!isValid"
          @click.stop="startPayment()"
        >
          <span v-if="!onlySubscription">COMPRAR</span>
          <span v-else>Assinar</span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.stop="closeDialog()">Fechar</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-card v-if="paymentMethod === 1 && startedPayment === true">
      <v-card-title></v-card-title>
      <v-card-text>
        <CreditCard
          :selected="{
            cost: Math.round(value * 100),
            installments: course_mode.installment === false
             ? course_loaded.credit_card_installments : 1,
          }"
          :extend="true"
          @output="creditCard = $event"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" width="200px" dark x-large @click.stop="payment()">
          <span v-if="!onlySubscription">COMPRAR</span>
          <span v-else>Assinar</span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" x-large @click.stop="back()">Voltar</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="successDialog" max-width="500px" persistent>
      <v-card>
        <v-card-text>
          <br />
          <v-row
            style="
              background-color: #4caf50;
              border: 1px solid #66bb6a;
              border-radius: 5px;
            "
          >
            <v-col cols="2" class="d-flex justify-center">
              <v-icon x-large dark>mdi-check-circle-outline</v-icon>
            </v-col>
            <v-col cols="10" class="d-flex justify-center">
              <p class="body-2" style="color: #fff">
                Pagamento realizado com sucesso! Estamos processando seu
                pagamento e isso pode levar até 40 minutos para suas moedas
                serem creditadas na sua conta.
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text large color="primary" @click.stop="closeDialog()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import CreditCard from '@/shared/components/Card/CreditCard'
import { mapState, mapGetters } from 'vuex'

export default {
  components: { CreditCard },
  data: () => ({
    credits: 0,
    creditCard: {
      cardNumber: '',
      cardName: '',
      cardMonth: '',
      cardYear: '',
      cvv: '',
      name: '',
      email: '',
      cpf: '',
      phone: '',
      cep: ''
    },
    paymentMethod: null,
    startedPayment: false,
    successDialog: false
  }),
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('courseStore', ['course_loaded', 'course_mode', 'cupom']),
    ...mapGetters('courseStore', [
      'paymentInfo',
      'cost',
      'discount',
      'entryPayment',
      'onlySubscription'
    ]),
    value () {
      return this.course_mode.installment === true
        ? this.entryPayment
        : this.cost - this.discount
    },
    isValid () {
      return this.paymentType !== null
    }
  },
  methods: {
    startPayment () {
      if (this.paymentMethod === 1) {
        this.startedPayment = true
      }

      if (this.paymentMethod === 2) {
        this.payment()
      }
    },
    creditCardIsValid () {
      return (
        this.creditCard.cardNumber.length > 0 &&
        this.creditCard.cardName.length > 0 &&
        this.creditCard.cardMonth.length > 0 &&
        this.creditCard.cardYear > 0 &&
        this.creditCard.cvv > 0 &&
        this.creditCard.name.length > 0 &&
        this.creditCard.email.length > 0 &&
        this.creditCard.cpf.length > 0 &&
        this.creditCard.phone.length > 0 &&
        this.creditCard.cep.length > 0
      )
    },
    async payment () {
      this.$loading.load('Prcessando pagamento...')
      const url = '/student/store/purchase'

      if (this.paymentMethod === 1 && !this.creditCardIsValid()) {
        this.$snackbar.error(
          'Transação não autorizada. Verifique os dados do cartão de crédito e tente novamente.'
        )
        this.$loading.loaded()
        return
      }

      const params = {
        credit_card: this.creditCard,
        payment_method: this.paymentMethod,
        subscription: this.onlySubscription,
        client_name:
          this.paymentMethod === 1
            ? this.creditCard.name
            : this.userData.personal_data.full_name,
        course_id: this.course_loaded.id,
        course_class: this.course_mode.course_class,
        installment: this.course_mode.installment,
        installment_number: this.course_mode.installment_number,
        cupom: this.cupom !== null ? this.cupom[0].cupom : null
      }

      await this.$api.post(url, params).then((res) => {
        if (this.paymentMethod === 1 && res.data.error) {
          this.$snackbar.error(res.data.error)
          this.$loading.loaded()
          this.$emit('close')
          return
        }

        if (
          this.paymentMethod === 1 &&
          res.data.response &&
          (res.data.response.status === 'CONFIRMED' ||
            res.data.response.status === 'ACTIVE')
        ) {
          this.closeDialog()
          this.$snackbar.success(
            'Pagamento realizado com sucesso! Estamos processando seu pagamento e isso pode levar até 40 minutos para seu curso estar disponível em sua conta.'
          )
          this.$loading.loaded()
          this.$router.push('/compras/historico')
        }

        if (this.paymentMethod === 2 && res.data.response) {
          this.closeDialog()
          window.open(res.data.response.bankSlipUrl)
          this.$loading.loaded()
          this.$router.push('/compras/historico')
        }
      })
    },
    back () {
      this.startedPayment = false
    },
    closeDialog () {
      this.paymentMethod = null
      this.creditCard = null
      this.startedPayment = false
      this.$emit('close')
    }
  }
}
</script>
