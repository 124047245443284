<template>
  <v-row>
    <v-col cols="12">
      <v-divider></v-divider>
      <span class="d-flex justify-center headline mt-8">IDIOMAS ESTRANGEIROS</span>
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.language1" label="IDIOMA" />
    </v-col>
    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-select v-model="form_data.language1_reading" :items="understanding_types" label="Leitura"></v-select>
    </v-col>
    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-select v-model="form_data.language1_writing" :items="understanding_types" label="Escrita"></v-select>
    </v-col>
    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-select v-model="form_data.language1_speech" :items="understanding_types" label="Escrita"></v-select>
    </v-col>
    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-select v-model="form_data.language1_understanding" :items="understanding_types" label="Entendimento"></v-select>
    </v-col>
    <v-col cols="12" md="4" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.language2" label="IDIOMA" />
    </v-col>
    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-select v-model="form_data.language2_reading" :items="understanding_types" label="Leitura"></v-select>
    </v-col>
    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-select v-model="form_data.language2_writing" :items="understanding_types" label="Escrita"></v-select>
    </v-col>
    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-select v-model="form_data.language2_speech" :items="understanding_types" label="Fala"></v-select>
    </v-col>
    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-select v-model="form_data.language2_understanding" :items="understanding_types" label="Entendimento"></v-select>
    </v-col>
  </v-row>
</template>

<script>
import MatriculationForeignLanguagesMixin from '@/app/components/Course/mixins/MatriculationForeignLanguages'

export default {
  mixins: [MatriculationForeignLanguagesMixin],
  data: () => ({
    understanding_types: [
      'BÁSICO',
      'INTERMEDIÁRIO',
      'AVANÇADO'
    ]
  })
}
</script>
