<template>
  <v-col
    cols="12"
    :class="$vuetify.breakpoint.smAndDown ? '' : 'd-flex justify-center'"
  >
    <div
      :class="$vuetify.breakpoint.smAndDown ? '' : 'd-flex justify-center'"
      :style="$vuetify.breakpoint.smAndDown ? '' : 'width: 65%'"
    >
      <v-row>
        <v-col cols="12">
          <span class="title text-left">AVALIAÇÃO</span>
        </v-col>

        <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'">
          <span class="body-2">
            A avaliação acontece ao final do curso.
            <br />
            Você terá uma prova com {{ openedExam.questions.length }} questões
            objetivas.
            <br />
            A nota final deverá ser superior a
            {{ openedExam.percentage_correct_answers }} para aprovação.
            <br />
          </span>
        </v-col>

        <v-col cols="12">
          <span class="title text-left">É IMPORTANTE OBSERVAR QUE:</span>
        </v-col>

        <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'">
          <span class="body-2">
            os prazos para conclusão dos cursos são estabelecidos de acordo como
            ritmo próprio do aluno obedecendo a carga horária fixada pelo CEAB.
            <br />em educação á distância não existe ilusão: é necessário
            estudar; <br />é bom conhecer qual o tempo que você dispõe para
            estudo;
            <br />
          </span>
        </v-col>

        <v-col cols="12">
          <span class="title text-left">PRAZO DE CONCLUSÃO:</span>
        </v-col>

        <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'">
          <span class="body-2"
            >O prazo para conclusão dos cursos a distancia são estabelecidos de
            acordo com ritmo próprio do aluno obedecendo a carga horária fixada
            pelo CEAB.</span
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-center">
          <span class="title text-center">
            Portanto ao realizar a primeira tentativa, a faça como sendo sua
            última opção de prova.
            <br />Boa prova! <br />Coordenação CEAB
          </span>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
export default {
  props: ['openedExam']
}
</script>
