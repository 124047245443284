export default class Cache {
  constructor (storage) {
    this.storage = storage
    this.tag = []
  }

  hasTag (tags) {
    if (localStorage.getItem(`CACHE:${JSON.stringify(tags)}`) === null) {
      return false
    }

    return true
  }

  tags (tags) {
    this.tag = tags
    return this
  }

  async getValue (value) {
    var callback = []
    switch (typeof value) {
      case 'function':
        const newValue = await value()
        callback = {
          callback: JSON.stringify(newValue),
          typeof: typeof newValue
        }
        break
      case 'Object':
        callback = {
          callback: JSON.stringify(value),
          typeof: typeof value
        }
        break
      default:
        callback = {
          callback: value,
          typeof: typeof value
        }
    }

    return callback
  }

  async remember (cache, time, value) {
    if (this.hasTag(this.tag)) {
      let arr = []
      let tag = localStorage.getItem(`CACHE:${JSON.stringify(this.tag)}`)
      arr = JSON.parse(tag)

      if (arr.filter(item => item.key === cache && parseInt(new Date().getTime()) >= item.time).length === 1) {
        arr = arr.filter(item => item.key !== cache)
      }

      if (arr.filter(item => item.key === cache).length === 1) {
        let item = arr.find(item => item.key === cache)
        return (item.value.typeof === 'object') ? JSON.parse(item.value.callback) : item.value.callback
      }

      arr.push({
        key: cache,
        value: await this.getValue(value),
        time: parseInt(new Date().getTime()) + parseInt(time * 1000)
      })

      localStorage.setItem(`CACHE:${JSON.stringify(this.tag)}`, JSON.stringify(arr))

      let item = arr.find(item => item.key === cache)
      return (item.value.typeof === 'object') ? JSON.parse(item.value.callback) : item.value.callback
    }

    let arr = []
    arr.push({
      key: cache,
      value: await this.getValue(value),
      time: parseInt(new Date().getTime()) + parseInt(time * 1000)
    })

    localStorage.setItem(`CACHE:${JSON.stringify(this.tag)}`, JSON.stringify(arr))
    return (arr[0].value.typeof === 'object') ? JSON.parse(arr[0].value.callback) : arr[0].value.callback
  }

  async rememberForever (cache, value) {
    if (this.hasTag(this.tag)) {
      let arr = []
      let tag = localStorage.getItem(`CACHE:${JSON.stringify(this.tag)}`)
      arr = JSON.parse(tag)

      if (arr.filter(item => item.key === cache).length === 1) {
        return arr.find(item => item.key === cache)
      }

      arr.push({
        key: cache,
        value: await this.getValue(value),
        time: 0
      })

      localStorage.setItem(`CACHE:${JSON.stringify(this.tag)}`, JSON.stringify(arr))
      return arr
    }

    let arr = []
    arr.push({
      key: cache,
      value: await this.getValue(value),
      time: 0
    })

    localStorage.setItem(`CACHE:${JSON.stringify(this.tag)}`, JSON.stringify(arr))
    return arr[0]
  }

  flush () {
    const keys = Object.keys(localStorage)
    var tagQuantity = 0

    for (const items of keys) {
      if (items.search('CACHE:') !== -1) {
        tagQuantity = 0
        for (const tags of this.tag) {
          if (items.search('CACHE:') !== -1 && items.search(tags) !== -1) {
            tagQuantity++
          }

          if (this.tag.length === tagQuantity) {
            localStorage.removeItem(items)
          }
        }
      }
    }
  }

  clear () {
    const keys = Object.keys(localStorage)

    for (let items of keys) {
      if (items.search('CACHE:') !== -1) { localStorage.removeItem(items) }
    }
  }
}
