<template>
  <v-text-field
    ref="phone"
    v-bind="bind"
    @input="input"
    v-mask="mask_phone"
    :rules="[rules.required, rules.length(13)]"
    :color="color"
    label="Telefone"
    required
    @keyup.enter="$emit('enter', true)"
  >
    <template v-slot:label>
      <span v-html="label"></span>
    </template>
  </v-text-field>
</template>

<script>
import TextFieldDefaultMixin from './Mixins/textfield-default-mixin'
import { mask } from 'vue-the-mask'

export default {
  props: ['value', 'color', 'label'],
  mixins: [TextFieldDefaultMixin],
  directives: { mask },
  data: () => ({
    mask_phone: '## #####-####',
    rules: {
      length: len => v => (v || '').length >= len || `Mínimo ${len} caracteres`,
      required: v => !!v || 'Este campo é obrigatório!'
    }
  })
}
</script>
