<template>
  <v-snackbar v-model="show" :color="color" :timeout="2000" top right style="z-index: 1000000000">
    <span v-html="text"></span>
    <v-btn color="white" text @click.stop="snackbar_edited = false">Fechar</v-btn>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('snackbar', ['show', 'color', 'text'])
  }
}
</script>
