import { mapState } from 'vuex'

export default {
  data: () => ({
    matriculationInfo: {
      habilitation: '',
      cvv_validation: '',
      gender: ''
    },
    matriculationPersonalData: {
      address: '',
      cep: '',
      city: '',
      uf: '',
      phone: '',
      birthday: '',
      marital_status: '',
      nationality: '',
      mothers_name: '',
      fathers_name: '',
      work_company: '',
      work_role: '',
      work_company_address: '',
      work_company_cep: '',
      work_company_city: '',
      work_company_uf: '',
      work_company_phone: '',
      work_company_fax: '',
      work_company_email: ''
    },
    matriculationDocumentation: {
      issuing_body: '',
      issuance_date: '',
      cpf: '',
      reservist: '',
      category: '',
      voter_title: '',
      zone: '',
      section: ''
    },
    matriculationInstructionLevel: {
      instruction_level: '',
      period: '',
      course: ''
    },
    matriculationImprovementCourses: {
      course1_name: '',
      course1_entity: '',
      course1_uf: '',
      course1_of_period: '',
      course1_to_period: '',
      course2_name: '',
      course2_entity: '',
      course2_uf: '',
      course2_of_period: '',
      course2_to_period: ''
    },
    matriculationForeignLanguages: {
      language1: '',
      language1_reading: '',
      language1_writing: '',
      language1_speech: '',
      language1_understanding: '',
      language2_reading: '',
      language2_writing: '',
      language2_speech: '',
      language2_understanding: ''
    },
    matriculationAnacLicense: {
      type_license: '',
      license_number: '',
      anac_habilitation: '',
      type_of_flighted_aircraft: '',
      flighted_aircraft_hours: ''
    },
    matriculationEmergency: {
      blood_type: '',
      allergic: '',
      name: '',
      degree_of_kinship: '',
      address: '',
      phone: '',
      extra_info: ''
    },
    signature_finished: false,
    snackbar_error: false,
    errors: ''
  }),
  computed: {
    ...mapState('course', ['course_loaded'])
  },
  methods: {
    register () {
      this.$api
        .post('/student/matriculation/register', {
          registration_id: this.course_loaded.registration_id,
          course_id: this.course_loaded.course_id,
          matriculationInfo: this.matriculationInfo,
          matriculationPersonalData: this.matriculationPersonalData,
          matriculationDocumentation: this.matriculationDocumentation,
          matriculationInstructionLevel: this.matriculationInstructionLevel,
          matriculationImprovementCourses: this.matriculationImprovementCourses,
          matriculationForeignLanguages: this.matriculationForeignLanguages,
          matriculationAnacLicense: this.matriculationAnacLicense,
          matriculationEmergency: this.matriculationEmergency,
          ip: this.$ipModule.getIp()
        })
        .then(() => {
          this.$emit('matriculation_finished', true)
        })
        .catch(e => {
          let errors = Object.values(e.response.data.errors)
            .toString()
            .replace(/,/g, '<br />')
          this.errors = errors
          this.snackbar_error = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
