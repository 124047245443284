export default {
  data: () => ({
    rules: {
      length_min: (len) => (v) =>
        (v || '').length >= len || `Mínimo ${len} caracteres`,
      length_max: (len) => (v) =>
        (v || '').length <= len || `Máximo ${len} caracteres`,
      required: (v) => !!v || 'Este campo é obrigatório!'
    }
  }),
  $parseOutput ($event) {
    return $event
  },
  $parseInput (value) {
    return value
  },
  computed: {
    bind () {
      return {
        ...this.$attrs,
        ...this.$props,
        value: this.$options.$parseInput(this.$props.value),
        counter: this.counter > 0 ? this.counter : false,
        disabled: this.disabled,
        required: this.required,
        label: this.label,
        rules: this.componentRules,
        type: this.type,
        color: this.color
      }
    },
    componentRules () {
      return [
        this.required === true ? this.rules.required : true,
        this.max > 0 ? this.rules.length_max(this.max) : true,
        this.min > 0 ? this.rules.length_min(this.min) : true
      ]
    }
  },
  methods: {
    input ($event) {
      this.$emit('input', this.$options.$parseOutput($event))
    }
  }
}
