import { mapState } from 'vuex'

export default {
  data: () => ({
    form_data: {
      habilitation: '',
      cvv_validation: '',
      gender: null
    }
  }),
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('course', ['course_loaded'])
  },
  watch: {
    'form_data.habilitation' (val) {
      this.$emit('habilitation', val)
    },
    'form_data.cvv_validation' (val) {
      this.$emit('cvv_validation', val)
    },
    'form_data.gender' (val) {
      this.$emit('gender', val)
    }
  }
}
