import axios from 'axios'

export default {
  namespaced: true,
  state: {
    version: '',
    hasUpdate: false
  },
  getters: {
    //
  },
  mutations: {
    startVersioning (state) {
      var selfState = state
      axios.get('/version.json').then(res => {
        selfState.version = res.data.version
      })
    },
    checkIfHasUpdate (state) {
      var selfState = state
      axios.get('/version.json').then(res => {
        if (selfState.version !== res.data.version) {
          selfState.hasUpdate = true
        }
      })
    }
  },
  actions: {
    startVersioning ({ commit }) {
      commit('startVersioning')
    },
    checkIfHasUpdate ({ commit }) {
      commit('checkIfHasUpdate')
    }
  }
}
