<template>
  <div v-if="show && !isPossibleAccessCourse">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container style="margin-top: 50px" v-if="!loading">
      <v-card>
        <v-card-title>Documentação: Comissário de Voo</v-card-title>
        <v-card-text>
          <v-alert type="info"
            >Sua documentação COMPLETA deverá estar registrada na plataforma do
            curso até a data de início. Caso não esteja, o seu acesso à
            plataforma será BLOQUEADO.</v-alert
          >

          <div v-if="$vuetify.breakpoint.smAndDown">
            <v-row v-for="(item, index) in documentations" :key="index">
              <v-col cols="12" class="d-flex justify-center">
                <span class="body-1 font-weight-black">{{ item.title }}</span>
              </v-col>

              <v-col cols="12" class="d-flex justify-center">
                <v-btn :color="status(item.id).color">{{
                  status(item.id).message
                }}</v-btn>
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-center"
                v-if="!item.front_verse && status(item.id).status < 3"
              >
                <v-file-input
                  accept="image/png, image/jpeg, image/bmp, application/pdf"
                  label="Adicione uma foto ou arquivo"
                  style="width: 450px"
                  @change="filesync($event, item.id, null)"
                ></v-file-input>
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-center"
                v-if="item.front_verse && status(item.id).status < 3"
              >
                <v-file-input
                  accept="image/png, image/jpeg, image/bmp, application/pdf"
                  label="Frente"
                  style="width: 450px"
                  @change="filesync($event, item.id, 'front')"
                ></v-file-input>
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-center"
                v-if="item.front_verse && status(item.id).status < 3"
                style="margin-top: -20px"
              >
                <v-file-input
                  accept="image/png, image/jpeg, image/bmp, application/pdf"
                  label="Verso"
                  style="width: 450px"
                  @change="filesync($event, item.id, 'verse')"
                ></v-file-input>
              </v-col>

              <v-col
                cols="12"
                v-if="status(item.id).status < 3"
                class="d-flex justify-center"
              >
                <v-btn class="mt-3" color="primary" @click="send(item.id)"
                  >Enviar</v-btn
                >
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </div>

          <v-simple-table v-if="show && !$vuetify.breakpoint.smAndDown">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Documentos</th>
                  <th class="text-left">Arquivo</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in documentations" :key="index">
                  <td>{{ item.title }}</td>
                  <td>
                    <v-tooltip
                      top
                      v-if="!item.front_verse && status(item.id).status < 3"
                    >
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-row>
                            <v-col cols="7">
                              <v-file-input
                                accept="image/png, image/jpeg, image/bmp, application/pdf"
                                label="Adicione uma foto ou arquivo"
                                style="width: 450px"
                                @change="filesync($event, item.id, null)"
                              ></v-file-input>
                            </v-col>

                            <v-col>
                              <v-btn
                                class="mt-3"
                                color="primary"
                                @click="send(item.id)"
                                >Enviar</v-btn
                              >
                            </v-col>
                          </v-row>
                        </span>
                      </template>
                      <span>
                        <h3 class="d-flex justify-center mb-2">Importante</h3>
                        <p>
                          Caso o documento contenha mais de uma página, deve-se
                          escanear e enviar todas as páginas em um único arquivo
                          (PDF).
                        </p>
                      </span>
                    </v-tooltip>

                    <v-tooltip
                      top
                      v-if="item.front_verse && status(item.id).status < 3"
                    >
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-row>
                            <v-col cols="7">
                              <v-file-input
                                accept="image/png, image/jpeg, image/bmp"
                                label="Frente"
                                style="width: 450px"
                                @change="filesync($event, item.id, 'front')"
                              ></v-file-input>
                            </v-col>

                            <v-col cols="7" style="margin-top: -20px">
                              <v-file-input
                                accept="image/png, image/jpeg, image/bmp"
                                label="Verso"
                                style="width: 450px"
                                @change="filesync($event, item.id, 'verse')"
                              ></v-file-input>
                            </v-col>

                            <v-col>
                              <v-btn
                                style="margin-top: -50px"
                                color="primary"
                                @click="send(item.id)"
                                >Enviar</v-btn
                              >
                            </v-col>
                          </v-row>
                        </span>
                      </template>
                      <span>
                        <h3 class="d-flex justify-center mb-2">Importante</h3>
                        <p>
                          Caso o documento contenha mais de uma página, deve-se
                          escanear e enviar todas as páginas em um único arquivo
                          (PDF).
                        </p>
                      </span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-btn :color="status(item.id).color">{{
                      status(item.id).message
                    }}</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="d-flex justify-center mt-10 mb-5">
            <v-btn
              color="primary"
              @click.stop="EnterDiscipline()"
              :disabled="!isPossibleAccessCourse"
              >Ir para o curso</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-container>

    <v-dialog
      v-model="alert_dialog"
      :scrollable="true"
      min-width="500px"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Atenção</v-card-title>
        <v-card-text>
          <p>
            Sua documentação COMPLETA deverá estar registrada na plataforma do
            curso até a data de início. Caso não esteja, o seu acesso à
            plataforma será BLOQUEADO.
          </p>

          <p>
            Qualquer dúvida sobre DOCUMENTAÇÃO, pode nos chamar pelo WhatsApp
            abaixo: WhatsApp Suporte Secretaria: Segunda-Feira à Sexta-Feira das
            16:00hrs às 21:00hrs.
          </p>

          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                href="https://api.whatsapp.com/send?phone=5511955901600&text=Poderia%20me%20orientar%3F"
                target="_blank"
                color="green darken-3"
                dark
              >
                <v-icon class="mr-2">mdi-whatsapp</v-icon>&nbsp;
                <span>(11) 95590-1600</span>
              </v-btn>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <h3>Secretaria CEAB</h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.stop="alert_dialog = false"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data: () => ({
    loading: true,
    alert_dialog: false,
    documentations: [],
    studentDocumentations: [],
    files: [],
    course_info: [],
    show: false
  }),
  computed: {
    studentDocumentationApproved () {
      return this.studentDocumentations.filter((item) => item.status === 4)
    },
    isPossibleAccessCourse () {
      return (
        this.studentDocumentationApproved.length ===
          this.documentations.length &&
        moment().isAfter(`${this.course_info.start_content} 00:00:00`)
      )
    }
  },
  methods: {
    async findCourse () {
      let url = '/student/find_student_course/'
      await this.$api
        .get(
          `${url}${this.$router.currentRoute.params.course_id}/${this.$router.currentRoute.params.registration_id}`
        )
        .then((res) => {
          this.course_info = res.data
        })
        .finally(() => {
          if (this.course_info.course_type === 2 || this.course_info.documentation === 0) {
            this.EnterDiscipline()
            return
          }

          this.getDocumentation()

          this.$userHistories.setHistory({
            id: `Documentação do curso`,
            text: `Abriu a página da documentação`,
            tag: this.course_info.name
          })
          this.$userHistories.start(`Documentação do curso`)
        })
    },
    EnterDiscipline () {
      this.loading = true
      return this.$router.push({
        name: 'Disciplinas',
        params: {
          course_id: this.$router.currentRoute.params.course_id,
          registration_id: this.$router.currentRoute.params.registration_id
        }
      }).catch(e => {})
    },
    getDocumentation () {
      this.loading = true
      const url = '/student/documentation/findByCourseId/'
      const courseId = this.$router.currentRoute.params.course_id

      this.$api
        .get(url + courseId)
        .then((res) => {
          this.documentations = res.data
        })
        .finally(() => {
          this.getStudentDocumentation()
        })
    },
    async getStudentDocumentation () {
      this.loading = true
      const url = '/student/documentation_student/'
      const registrationId = this.$router.currentRoute.params.registration_id
      const courseId = this.$router.currentRoute.params.course_id

      await this.$api
        .get(`${url}${registrationId}/${courseId}`)
        .then((res) => {
          this.studentDocumentations = res.data
        })
        .finally(() => {
          if (this.isPossibleAccessCourse) {
            this.EnterDiscipline()
          } else {
            this.show = true
            this.loading = false
            this.alert_dialog = true
          }
        })
    },
    convertFile (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    filesync (file, id, type) {
      const accepts = ['pdf', 'image']
      const registrationId = this.$router.currentRoute.params.registration_id

      if (
        accepts.includes(file.type.split('/')[0]) ||
        accepts.includes(file.type.split('/')[1])
      ) {
        return this.convertFile(file).then((res) => {
          let documentation = this.documentations.find(
            (item) => item.id === id
          )
          if (documentation.front_verse && type !== null) {
            for (let item in this.files) {
              if (
                this.files[item].documentation_id === id &&
                this.files[item].front_verse === type
              ) {
                this.files = this.files.slice(0, item)
              }
            }
            this.files.push({
              documentation_id: id,
              registration_id: registrationId,
              file_type: file.type,
              front_verse: type,
              base64: res
            })
          } else {
            this.files = Object.keys(
              this.files.filter((item) => item.documentation_id !== id)
            )
            this.files.push({
              documentation_id: id,
              registration_id: registrationId,
              file_type: file.type,
              base64: res
            })
          }
        })
      }
    },
    send (id) {
      this.loading = true
      let file = this.files.filter((item) => item.documentation_id === id)
      let documentation = this.documentations.find((item) => item.id === id)

      if (file === undefined || file === [] || file.length === 0) {
        this.loading = false
        return this.$snackbar.error(
          'Você ainda não especificou nenhuma imagem'
        )
      }

      if (documentation.front_verse) {
        this.$api
          .post('/student/documentation/send_files/front_verse', {
            files: file
          })
          .then(() => {
            this.getDocumentation()
            this.getStudentDocumentation()
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$api
          .post(
            '/student/documentation/send_files',
            this.files.find((item) => item.documentation_id === id)
          )
          .then(() => {
            this.getDocumentation()
            this.getStudentDocumentation()
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    status (id) {
      const documentation = this.studentDocumentations.find(
        (item) => item.documentation_id === id
      )
      let result
      if (documentation) {
        switch (documentation.status) {
          case 1:
            result = {
              status: documentation.status,
              color: 'primary',
              message: 'Pendente'
            }
            break

          case 2:
            result = {
              status: documentation.status,
              color: 'error',
              message: 'Negado'
            }
            break

          case 3:
            result = {
              status: documentation.status,
              color: 'warning',
              message: 'Em análise'
            }
            break

          case 4:
            result = {
              status: documentation.status,
              color: 'success',
              message: 'Aprovado'
            }
            break

          default:
            result = {
              status: documentation.status,
              color: 'primary',
              message: 'Pendente'
            }
            break
        }
        return result
      }

      return { status: 1, color: 'primary', message: 'Pendente' }
    }
  },
  beforeMount () {
    this.$loading.load('Carregando documentação...')
  },
  mounted () {
    setTimeout(() => {
      if (!this.isPossibleAccessCourse) {
        this.$api
          .get(
            `/student/documentation_doc/get_progress/${this.$router.currentRoute.params.course_id}/${this.$router.currentRoute.params.registration_id}`
          )
          .then((res) => {
            if (res.data.percentage === 100) {
              this.findCourse()
            } else {
              return this.$router.push({
                name: 'Apostilas da documentação',
                params: {
                  course_id: this.$router.currentRoute.params.course_id,
                  registration_id: this.$router.currentRoute.params
                    .registration_id
                }
              }).catch(() => {})
            }
          })
      }

      this.$loading.loaded()
    }, 3000)
  },
  beforeDestroy () {
    this.$userHistories.end(`Documentação do curso`)
  }
}
</script>
