<template>
  <v-row>
    <v-col cols="12">
      <v-divider></v-divider>
      <span class="d-flex justify-center headline mt-8">PARA PORTADORES DE LICENÇA ANAC</span>
    </v-col>

    <v-col cols="12" md="8" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.type_license" label="TIPO DE LICENÇA" />
    </v-col>
    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.license_number" label="Nº LICENÇA" />
    </v-col>
    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.habilitation" label="HABILITAÇÃO" />
    </v-col>

    <v-col cols="12" md="8" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.type_of_flighted_aircraft" label="TIPO(S) DE AERONAVE(S) VOADA(S)" />
    </v-col>
    <v-col cols="12" md="4" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.flighted_aircraft_hours" label="HORAS DE VÔO EM CASO DE PILOTO" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    form_data: {
      type_license: '',
      license_number: '',
      habilitation: '',
      type_of_flighted_aircraft: '',
      flighted_aircraft_hours: ''
    }
  }),
  watch: {
    'form_data.type_license' (val) {
      this.$emit('type_license', val)
    },
    'form_data.license_number' (val) {
      this.$emit('license_number', val)
    },
    'form_data.habilitation' (val) {
      this.$emit('habilitation', val)
    },
    'form_data.type_of_flighted_aircraft' (val) {
      this.$emit('type_of_flighted_aircraft', val)
    },
    'form_data.flighted_aircraft_hours' (val) {
      this.$emit('flighted_aircraft_hours', val)
    }
  }
}
</script>
