<template>
  <div>
    <v-text-field
      v-if="maskData === ''"
      v-bind="bind"
      @input="input"
      @keyup.enter="$emit('enter', true)"
    ></v-text-field>
    <v-text-field
      v-else
      v-bind="bind"
      @input="input"
      @keyup.enter="$emit('enter', true)"
      v-mask="maskData"
    ></v-text-field>
  </div>
</template>

<script>
import textfieldMixin from './Mixins/textfieldMixin'
import { mask } from 'vue-the-mask'

export default {
  props: {
    value: { default: '' },
    label: { type: String, default: '' },
    type: { type: String, default: 'text' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 0 },
    counter: { type: Number, default: 0 },
    color: { type: String, default: 'primary' },
    maskData: { type: String, default: '' }
  },
  mixins: [textfieldMixin],
  data: () => ({
    model: ''
  }),
  directives: { mask }
}
</script>
