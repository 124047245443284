<template>
  <v-row>
    <v-col
      cols="12"
      class="d-flex justify-center mt-5"
      style="padding: 4px 6px"
      v-if="!started"
    >
      <v-dialog
        v-model="alertExamStart"
        max-width="500px"
        v-if="openedExam.attempts > 0"
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="green" dark>Começar</v-btn>
        </template>
        <v-card>
          <v-card-title class="d-flex justify-center"
            >Você tem certeza que deseja iniciar a prova?</v-card-title
          >
          <v-card-text>
            <v-container>
              <p>
                Se você iniciar a prova, você gastará uma tentativa. Se está for
                a sua última tentativa, não poderá mais fazer esta prova neste
                módulo!
              </p>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="startExam()"
              >Quero fazer a prova!</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="alertExamStart = false"
              >Não quero fazer a prova agora!</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

export default {
  props: ['openedExam'],
  data: () => ({
    alertExamStart: false
  }),
  computed: {
    ...mapState('Exam', ['started', 'attempt_id'])
  },
  watch: {
    started (val) {
      if (val) {
        this.setTime(moment.duration(this.openedExam.time).asSeconds())
        this.startTime()
        this.openedExam.attempts--
      }
    }
  },
  methods: {
    ...mapActions('Exam', ['start', 'setTime', 'startTime']),
    startExam () {
      this.alertExamStart = false
      const registrationId = this.$router.currentRoute.params.registration_id
      const openedExamId = this.openedExam.id

      this.start({
        registration_id: registrationId,
        opened_exam_id: openedExamId
      })
    }
  }
}
</script>
