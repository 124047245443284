<template>
  <div>
    <v-card-actions class="d-flex justify-center">
      <v-btn
        class="subtitle-1 font-weight-light"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'margin-right: 40%'"
        text
        >Tentativas: {{ openedExam.attempts }}</v-btn
      >
      <v-btn
        class="subtitle-1 font-weight-regular red--text text--lighten-1"
        text
        v-if="started"
        >Tempo: {{ time | hms }}</v-btn
      >
      <v-btn
        class="subtitle-1 font-weight-regular red--text text--lighten-1"
        text
        v-else
        >Tempo: {{ openedExam.time }}</v-btn
      >
    </v-card-actions>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import 'moment-duration-format'

export default {
  props: ['openedExam'],
  filters: {
    hms (val) {
      return moment
        .duration(val, 'seconds')
        .format('HH:mm:ss', { trim: false })
    }
  },
  computed: {
    ...mapState('Exam', ['started', 'time'])
  }
}
</script>
