<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <banner-magazine class="d-flex justify-center" />
    <v-container>
      <v-card>
        <v-tabs
          v-model="tab"
          background-color="white"
          color="blue"
          right
          show-arrows
        >
          <v-tab v-if="main_courses.length > 0">Cursos Principais</v-tab>
          <!-- <v-tab v-if="complementaryCourses.length > 0">Cursos Complementares</v-tab> -->

          <v-tab-item v-if="main_courses.length > 0">
            <v-container fluid>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  sm="4"
                  xs="12"
                  v-for="(item, index) in main_courses"
                  :key="index"
                >
                  <a @click.stop="openCourse(item)">
                    <v-img
                      :src="$storage + item.image"
                      aspect-ratio="1"
                    ></v-img>
                    <v-btn
                      block
                      color="primary"
                      class="body-1 font-weight-bold mt-1"
                      >Comprar</v-btn
                    >
                  </a>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <!-- <v-tab-item v-if="complementaryCourses.length > 0">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="3" sm="4" xs="12" v-for="(item, index) in complementaryCourses" :key="index">
                  <a @click.stop="openCourse(item)">
                    <v-img :src="$storage + item.image" aspect-ratio="1"></v-img>
                  </a>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item> -->
        </v-tabs>
      </v-card>
    </v-container>

    <v-dialog
      v-if="coursePreview_dialog"
      v-model="coursePreview_dialog"
      :scrollable="true"
    >
      <v-card>
        <v-card-title class="d-flex justify-center">{{
          course_selected.name
        }}</v-card-title>
        <v-card-text>
          <v-container v-html="course_selected.description"></v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            width="250px"
            height="50px"
            @click.stop="openPaymentCourse()"
            :disabled="!course_selected.available_for_sale"
            >Comprar curso</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="coursePayment_dialog"
      v-model="coursePayment_dialog"
      :scrollable="true"
    >
      <CoursePayment
        v-if="!$vuetify.breakpoint.smAndDown"
        :selected="course_selected"
        :acceptedTerms="acceptedTerms"
        @change-payment-method="acceptedTerms = false"
        @apply="openPaymentTerms($event)"
        @close="coursePayment_dialog = false"
      />
      <CoursePaymentMobile
        v-if="$vuetify.breakpoint.smAndDown"
        :selected="course_selected"
        :acceptedTerms="acceptedTerms"
        @change-payment-method="acceptedTerms = false"
        @apply="openPaymentTerms($event)"
        @close="coursePayment_dialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="courseTerms_dialog"
      v-model="courseTerms_dialog"
      :scrollable="true"
    >
      <CourseTerms />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CoursePayment from '@/app/components/CourseStore/CoursePayment'
import CoursePaymentMobile from '@/app/components/CourseStore/CoursePaymentMobile'
import CourseTerms from '@/app/components/CourseStore/CourseTerms'
import BannerMagazine from '@/app/components/BannerMagazine'

export default {
  components: {
    CoursePayment,
    CoursePaymentMobile,
    CourseTerms,
    BannerMagazine
  },
  data: () => ({
    loading: true,
    tab: 0,
    course_selected: [],
    acceptedTerms: false,
    coursePreview_dialog: false,
    coursePayment_dialog: false,
    courseTerms_dialog: false
  }),
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('courseStore', ['main_courses'])
  },
  methods: {
    ...mapActions('courseStore', ['loadCourseStore', 'selectCourse']),
    async getCourses () {
      this.loading = true
      this.loadCourseStore().finally(() => {
        this.loading = false
      })
    },
    openCourse (item) {
      this.selectCourse(item)

      if (item.description !== null) {
        this.coursePreview_dialog = true
      } else {
        this.coursePayment_dialog = true
      }
    },
    openPaymentCourse () {
      this.coursePreview_dialog = false
      this.coursePayment_dialog = true
    },
    openPaymentTerms (payload) {
      this.courseTerms_dialog = true
    }
  },
  beforeMount () {
    this.getCourses()
    this.$userHistories.setHistory({
      id: `Página de Vendas`,
      text: `Abriu a página de vendas de cursos`,
      tag: 'Plataforma'
    })
    this.$userHistories.start(`Página de Vendas`)
  },
  beforeDestroy () {
    this.$userHistories.end(`Página de Vendas`)
  }
}
</script>

<style scoped>
.button-clicked {
  border-radius: 0px;
  border-bottom: 3px solid #1877f2;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

div.scroll {
  width: auto;
  height: auto;
  overflow-y: scroll;
}
</style>
