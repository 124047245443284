import Vue from 'vue'
import $store from '../store'

Vue.use({
  install (Vue) {
    Vue.prototype.$loading = {
      load: (text) => $store.dispatch('loading/load', text),
      loaded: () => $store.dispatch('loading/loaded')
    }
  }
})
