import Vue from 'vue'
import $store from '../store'

console.log($store)

Vue.use({
  install (Vue) {
    Vue.prototype.$pingModule = {
      startPing: () => $store.dispatch('pingModule/startPing'),
      getPing: () => $store.state.pingModule.ping,
      color: () => $store.getters['pingModule/color'],
      status: () => $store.getters['pingModule/status'],
      checkAccessInternet: () => $store.state.pingModule.accessInternet
    }
  }
})
