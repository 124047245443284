<template>
  <v-row v-if="resultLoaded">
    <v-col cols="12">
      <div class="d-flex justify-center text-center title">
        NOTA: {{ getNoteAttempt() }}
        <br />
        ACERTOS: {{ result.correct_answers }}/{{
          result.total_correct_answers
        }}
      </div>

      <br />
      <div v-for="(item, index) in result.results" :key="index">
        <span class="body-2">
          <b>{{ index + 1 }}) {{ item.question }}</b>
        </span>
        <br />

        <div v-for="(answers, index) in item.answers" :key="index">
          <div v-for="(item, index) in answers.answers" :key="index">
            <div v-if="answers.correct_answer">
              <div
                v-if="item.is_answer_correct && item.your_answer"
                class="d-flex align-center mb-2 mt-2"
                style="
                  background: #d9eecb;
                  background: rgba(129, 211, 72, 0.25);
                  border: 1px solid #b3e68b;
                  border: 1px solid rgba(129, 211, 72, 0.4);
                "
              >
                <v-container class="d-flex align-center">
                  <img
                    src="https://ceabonline.com.br/images/success.png"
                    width="20px"
                    class="mr-3"
                  />
                  <span
                    >{{ position[index] }}.
                    {{
                      item.answer.charAt(0).toUpperCase() + item.answer.slice(1)
                    }}</span
                  >
                </v-container>
              </div>
              <div v-else>
                {{ position[index] }}.
                {{ item.answer.charAt(0).toUpperCase() + item.answer.slice(1) }}
              </div>
            </div>
            <div v-else>
              <div
                v-if="!item.is_answer_correct && item.your_answer"
                class="d-flex align-center mb-2 mt-2"
                style="
                  background: #f8d9cf;
                  background: rgba(255, 132, 89, 0.25);
                  border: 1px solid #fac0ad;
                  border: 1px solid rgba(255, 132, 89, 0.4);
                "
              >
                <v-container class="d-flex align-center">
                  <img
                    src="@/shared/assets/error.png"
                    width="20px"
                    class="mr-3"
                  />
                  <span
                    >{{ position[index] }}.
                    {{
                      item.answer.charAt(0).toUpperCase() + item.answer.slice(1)
                    }}</span
                  >
                </v-container>
              </div>
              <div
                v-else-if="item.is_answer_correct"
                class="d-flex align-center mb-2 mt-2"
                style="
                  background: #d9eecb;
                  background: rgba(129, 211, 72, 0.25);
                  border: 1px solid #b3e68b;
                  border: 1px solid rgba(129, 211, 72, 0.4);
                "
              >
                <v-container class="d-flex align-center">
                  <img
                    src="@/shared/assets/success.png"
                    width="20px"
                    class="mr-3"
                  />
                  <span
                    >{{ position[index] }}.
                    {{
                      item.answer.charAt(0).toUpperCase() + item.answer.slice(1)
                    }}</span
                  >
                </v-container>
              </div>
              <div v-else>
                {{ position[index] }}.
                {{ item.answer.charAt(0).toUpperCase() + item.answer.slice(1) }}
              </div>
            </div>
          </div>
        </div>
        <br />
        <v-divider></v-divider>
        <br />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Exam', ['result', 'resultLoaded'])
  },
  data: () => ({
    position: [
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'x',
      'z'
    ]
  }),
  methods: {
    getNoteAttempt () {
      const note = (this.result.percentage_correct_answers / 10).toString()
      let newNote = note.split('.')

      if (newNote.length === 1) {
        newNote = note
      }

      if (parseInt(newNote[1]) === 5) {
        newNote = `${parseInt(newNote[0])}.${parseInt(newNote[1])}`
      }

      if (parseInt(newNote[1]) < 5) {
        newNote = newNote[0]
      }

      if (parseInt(newNote[1]) > 5) {
        newNote = parseInt(newNote[0]) + 1
      }

      return note === '10' ? note : newNote
    }
  }
}
</script>
