<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-center">Curso {{ selected.name }} encerrado!</v-card-title>
      <v-card-text>
        <Certified
          v-if="registration.liberated_certified"
          :course_info="selected"
          :registration="registration"
          :elevation="0"
          @noCertified="noCertified = $event"
          @expiredCertificate="expiredCertificate = $event"
        />
        <div v-if="!registration.liberated_certified">Perdoe-nos, mas seu certificado ainda não está disponível.</div>
        <div v-if="expiredCertificate">Seu certificado está expirado!</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Certified from '../../components/Certified'

export default {
  props: ['selected'],
  components: { Certified },
  data: () => ({
    registration: [],
    noCertified: false,
    expiredCertificate: false
  }),
  methods: {
    async getRegistrationInfo () {
      const url = '/student/registration/getRegistrationInfo/'

      await this.$api
        .get(
          `${url}${this.selected.course_id}/${this.selected.registration_id}`
        )
        .then(res => {
          this.registration = res.data
        })
    }
  },
  watch: {
    selected (val) {
      this.getRegistrationInfo()
    }
  },
  beforeMount () {
    this.getRegistrationInfo()
  }
}
</script>
