export default {
  namespaced: true,
  state: {
    show: false,
    color: '',
    text: ''
  },
  getters: {
    //
  },
  mutations: {
    success (state, payload) {
      state.show = true
      state.text = payload
      state.color = 'success'

      setTimeout(() => {
        state.show = false
        state.text = ''
        state.color = ''
      }, 2000)
    },
    error (state, payload) {
      state.show = true
      state.text = payload
      state.color = 'error'

      setTimeout(() => {
        state.show = false
        state.text = ''
        state.color = ''
      }, 2000)
    }
  },
  actions: {
    success ({ commit }, message) {
      commit('success', message)
    },
    error ({ commit }, message) {
      commit('error', message)
    }
  }
}
