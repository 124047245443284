<template>
  <div style="overflow: hidden">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- <div
      style="
        background-color: #f4f4f4;
        width: 70%;
        float: left;
        overflow-y: auto;
        height: 100%;
      "
    >
      <v-container>

      </v-container>
    </div> -->

    <div
      style="
        background-color: #e0e0e0;
        width: 100%;
        float: left;
        overflow-y: scroll;
        height: 100%;
      "
    >
      <v-container>
        <OrderSummary :mobile="true" @close="$emit('close')">
          <template v-slot:courseMode>
            <v-container>
                <CourseMode :mobile="true" />
            </v-container>
          </template>
        </OrderSummary>
      </v-container>
    </div>

    <v-dialog
      v-if="!loading && accepted_terms === false"
      @click:outside="dialogCourseTerms(false)"
      :value="course_terms_dialog"
      :scrollable="true"
    >
      <CourseTerms />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CourseMode from '@/app/components/CourseStore/CourseMode'
import OrderSummary from '@/app/components/CourseStore/OrderSummary'
import CourseTerms from '@/app/components/CourseStore/CourseTerms'

export default {
  components: { CourseMode, OrderSummary, CourseTerms },
  data: () => ({
    loading: true
  }),
  computed: {
    ...mapState('courseStore', ['course_terms_dialog', 'accepted_terms'])
  },
  methods: {
    ...mapActions('courseStore', ['dialogCourseTerms', 'loadCourseTerms'])
  },
  mounted () {
    this.loadCourseTerms().finally(() => {
      this.loading = false
    })
  }
}
</script>
