<template>
  <v-text-field
    ref="model"
    v-bind="bind"
    @input="input"
    v-mask="mask_cnpj"
    :rules="[rules.required, rules.length(11)]"
    counter="14"
    :color="color"
    required
    :disabled="disabled"
    @keyup.enter="$emit('enter', true)"
  >
    <template v-slot:label>
      <span v-html="label"></span>
    </template>
  </v-text-field>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  props: {
    item: { type: String, default: '' },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    color: { type: String, default: 'primary' }
  },
  directives: { mask },
  data: () => ({
    mask_cnpj: '###.###.###-##',
    rules: {
      length: len => v => (v || '').length >= len || `Mínimo ${len} caracteres`,
      required: v => !!v || 'Este campo é obrigatório!'
    }
  }),
  $parseOutput ($event) {
    return $event
  },
  $parseInput (value) {
    return value
  },
  computed: {
    bind () {
      return {
        ...this.$attrs,
        ...this.$props,
        value: this.$options.$parseInput(this.$props.item)
      }
    },
    validCPF () {
      if (this.item.length > 14) return

      const cpf = this.item.replace(/(\.|\/|-)/g, '').split('')

      const soma1 =
        parseInt(cpf[0]) * 10 +
        parseInt(cpf[1]) * 9 +
        parseInt(cpf[2]) * 8 +
        parseInt(cpf[3]) * 7 +
        parseInt(cpf[4]) * 6 +
        parseInt(cpf[5]) * 5 +
        parseInt(cpf[6]) * 4 +
        parseInt(cpf[7]) * 3 +
        parseInt(cpf[8]) * 2
      let resto1 = parseInt(soma1 * 10) % 11

      if (resto1 === 10) {
        resto1 = 0
      }

      const soma2 =
        parseInt(cpf[0]) * 11 +
        parseInt(cpf[1]) * 10 +
        parseInt(cpf[2]) * 9 +
        parseInt(cpf[3]) * 8 +
        parseInt(cpf[4]) * 7 +
        parseInt(cpf[5]) * 6 +
        parseInt(cpf[6]) * 5 +
        parseInt(cpf[7]) * 4 +
        parseInt(cpf[8]) * 3 +
        parseInt(cpf[9]) * 2
      let resto2 = (parseInt(soma2) * 10) % 11

      if (resto2 === 10) {
        resto2 = 0
      }

      return parseInt(cpf[9]) === resto1 && parseInt(cpf[10]) === resto2
        ? true
        : 'CPF inválido'
    },
    checkCPF () {
      const cpf = this.item.replace(/(\.|\/|-)/g, '')
      return cpf === '00000000000' ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999'
        ? 'CPF é inválido'
        : true
    }
  },
  methods: {
    input ($event) {
      this.$emit('output', this.$options.$parseOutput($event))
    }
  }
}
</script>
