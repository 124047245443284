<template>
  <v-text-field
    v-bind="bind"
    @input="input"
    v-mask="mask_cep"
    :rules="[rules.required, rules.length(9)]"
    :append-icon="withfinder ? 'mdi-search-web' : ''"
    @click:append="findCEP(bind.value)"
    :color="color"
    autocomplete="off"
    required
    @keyup.enter="$emit('enter', true)"
  >
    <template v-slot:label>
      <span v-html="label"></span>
    </template>
  </v-text-field>
</template>

<script>
import TextFieldDefaultMixin from '../Mixins/textfield-default-mixin'
import { mask } from 'vue-the-mask'

export default {
  directives: { mask },
  mixins: [TextFieldDefaultMixin],
  props: {
    value: { type: String },
    label: { type: String },
    color: { type: String, default: 'primary' },
    uf: { type: Boolean, default: false },
    withfinder: { type: Boolean, default: true }
  },
  data: () => ({
    mask_cep: '#####-###',
    rules: {
      length: len => v => (v || '').length >= len || `Mínimo ${len} caracteres`,
      required: v => !!v || 'Este campo é obrigatório!'
    },
    states: {
      AC: 'Acre',
      AL: 'Alagoas',
      AP: 'Amap\u00e1',
      AM: 'Amazonas',
      BA: 'Bahia',
      CE: 'Cear\u00e1',
      DF: 'Distrito Federal',
      ES: 'Esp\u00edrito Santo',
      GO: 'Goi\u00e1s',
      MA: 'Maranh\u00e3o',
      MT: 'Mato Grosso',
      MS: 'Mato Grosso do Sul',
      MG: 'Minas Gerais',
      PR: 'Paran\u00e1',
      PB: 'Para\u00edba',
      PA: 'Par\u00e1',
      PE: 'Pernambuco',
      PI: 'Piau\u00ed',
      RN: 'Rio Grande do Norte',
      RS: 'Rio Grande do Sul',
      RJ: 'Rio de Janeiro',
      RO: 'Rond\u00f4nia',
      RR: 'Roraima',
      SC: 'Santa Catarina',
      SE: 'Sergipe',
      SP: 'S\u00e3o Paulo',
      TO: 'Tocantins'
    }
  }),
  methods: {
    findCEP (cep) {
      this.$viacep.get(`/ws/${cep}/json/`, { headers: {} }).then(res => {
        this.$emit('find', {
          state: this.uf === true ? res.data.uf : this.states[res.data.uf],
          city: res.data.localidade,
          neighborhood: res.data.bairro,
          street: res.data.logradouro
        })
      })
    }
  },
  watch: {
    value () {
      if (this.value && this.value.length === 9 && this.withfinder === true) {
        this.findCEP(this.value)
      }
    }
  }
}
</script>
