<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-center">TERMO DE COMPROMISSO E CIENCIA.</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <p>
              Declaro para os devidos fins que conforme determina a IS Nº 141-007 Revisão A aprovada pela
              Portaria nº 1.529/SPO, de 12 de junho de 2020 tabela 2-1 , estou ciente de que no momento da
              solicitação de concessão da licença de Comissário(a) de Voo terei que ser titular de CMA de 2ª
              classe válido, e que a reprovação no mesmo poderá indicar incapacidade para o exercício da
              atividade aérea. Declaro ainda que isento o CEAB- ESCOLA DE AVIAÇÃO e a ANAC
              (Agência Nacional de Aviação Civil) de quaisquer responsabilidades decorrentes de uma
              eventual não obtenção do CMA. Declaro ter lido o Regulamento Curso, concordando com os
              termos propostos. E por ter fé e verdade o declarado acima, assino a presente declaração.
            </p>
          </v-col>

          <v-col cols="12" style="border: 1px solid #000;">
            <Signature
              @conclusion="signature_finished = $event"
              :button="$vuetify.breakpoint.xsOnly ? 'Assinatura Digital' : 'Clique aqui para assinar o TERMO DE COMPROMISSO E CIENCIA'"
            />
          </v-col>

          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              :disabled="!signature_finished"
              color="success"
              style="width: 200px; height: 75px"
              class="body-1"
              @click.stop="register()"
            >Gravar Dados</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Signature from '@/app/components/Course/Signature'
import { mapState } from 'vuex'

export default {
  components: { Signature },
  data: () => ({
    signature_finished: false
  }),
  computed: {
    ...mapState('course', ['course_loaded'])
  },
  methods: {
    register () {
      this.$api
        .post('/student/commitment_terms/register', {
          registration_id: this.course_loaded.registration_id,
          course_id: this.course_loaded.course_id,
          ip: this.$ipModule.getIp()
        })
        .then(() => {
          this.$emit('commitmentTerm_finished', true)
        })
        .catch((e) => {
          let errors = Object.values(e.response.data.errors)
            .toString()
            .replace(/,/g, '<br />')
          this.errors = errors
          this.snackbar_error = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
