<template>
  <div>
    <v-card>
      <v-card-title
        class="d-flex justify-center"
      >Antes de Continuar Você Deve Preencher Também, Sua FICHA DE MATRÍCULA/INSCRIÇÃO.</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-icon class="mr-2">mdi-information</v-icon>
              <span>Preencha todas informações solicitadas.</span>
            </v-col>

            <v-col cols="12">
              <v-icon class="mr-2">mdi-information</v-icon>
              <span>
                Campos marcados com asterisco(
                <span style="color: red;">*</span>) e em
                <span style="color: red;">VERMELHO</span> são de preenchimento obrigatório.
              </span>
            </v-col>
          </v-row>

          <MatriculationInfo
            :course="course_loaded"
            @habilitation="matriculationInfo.habilitation = $event"
            @cvv_validation="matriculationInfo.cvv_validation = $event"
            @gender="matriculationInfo.gender = $event"
          />

          <MatriculationPersonalData
            @address="matriculationPersonalData.address = $event"
            @cep="matriculationPersonalData.cep = $event"
            @city="matriculationPersonalData.city = $event"
            @uf="matriculationPersonalData.uf = $event"
            @phone="matriculationPersonalData.phone = $event"
            @birthday="matriculationPersonalData.birthday = $event"
            @marital_status="matriculationPersonalData.marital_status = $event"
            @nationality="matriculationPersonalData.nationality = $event"
            @mothers_name="matriculationPersonalData.mothers_name = $event"
            @fathers_name="matriculationPersonalData.fathers_name = $event"
            @work_company="matriculationPersonalData.work_company = $event"
            @work_role="matriculationPersonalData.work_role = $event"
            @work_company_address="matriculationPersonalData.work_company_address = $event"
            @work_company_cep="matriculationPersonalData.work_company_cep = $event"
            @work_company_city="matriculationPersonalData.work_company_city = $event"
            @work_company_uf="matriculationPersonalData.work_company_uf = $event"
            @work_company_phone="matriculationPersonalData.work_company_phone = $event"
            @work_company_fax="matriculationPersonalData.work_company_fax = $event"
            @work_company_email="matriculationPersonalData.work_company_email = $event"
          />

          <MatriculationDocumentation
            @rg="matriculationDocumentation.rg = $event"
            @issuing_body="matriculationDocumentation.issuing_body = $event"
            @issuance_date="matriculationDocumentation.issuance_date = $event"
            @cpf="matriculationDocumentation.cpf = $event"
            @reservist="matriculationDocumentation.reservist = $event"
            @category="matriculationDocumentation.category = $event"
            @voter_title="matriculationDocumentation.voter_title = $event"
            @zone="matriculationDocumentation.zone = $event"
            @section="matriculationDocumentation.section = $event"
          />
          <MatriculationInstructionLevel
            @instruction_level="matriculationInstructionLevel.instruction_level = $event"
            @period="matriculationInstructionLevel.period = $event"
            @course="matriculationInstructionLevel.course = $event"
          />
          <MatriculationImprovementCourses
            @course1_name="matriculationImprovementCourses.course1_name = $event"
            @course1_entity="matriculationImprovementCourses.course1_entity = $event"
            @course1_uf="matriculationImprovementCourses.course1_uf = $event"
            @course1_of_period="matriculationImprovementCourses.course1_of_period = $event"
            @course1_to_period="matriculationImprovementCourses.course1_to_period = $event"
            @course2_name="matriculationImprovementCourses.course2_name = $event"
            @course2_entity="matriculationImprovementCourses.course2_entity = $event"
            @course2_uf="matriculationImprovementCourses.course2_uf = $event"
            @course2_of_period="matriculationImprovementCourses.course2_of_period = $event"
            @course2_to_period="matriculationImprovementCourses.course2_to_period = $event"
          />
          <MatriculationForeignLanguages
            @language1="matriculationForeignLanguages.language1 = $event"
            @language1_reading="matriculationForeignLanguages.language1_reading = $event"
            @language1_writing="matriculationForeignLanguages.language1_writing = $event"
            @language1_speech="matriculationForeignLanguages.language1_speech = $event"
            @language1_understanding="matriculationForeignLanguages.language1_understanding = $event"
            @language2="matriculationForeignLanguages.language2 = $event"
            @language2_reading="matriculationForeignLanguages.language2_reading = $event"
            @language2_writing="matriculationForeignLanguages.language2_writing = $event"
            @language2_speech="matriculationForeignLanguages.language2_speech = $event"
            @language2_understanding="matriculationForeignLanguages.language2_understanding = $event"
          />
          <MatriculationAnacLicense
            @type_license="matriculationAnacLicense.type_license = $event"
            @license_number="matriculationAnacLicense.license_number = $event"
            @habilitation="matriculationAnacLicense.habilitation = $event"
            @type_of_flighted_aircraft="matriculationAnacLicense.type_of_flighted_aircraft = $event"
            @flighted_aircraft_hours="matriculationAnacLicense.flighted_aircraft_hours = $event"
          />
          <MatriculationEmergency
            @blood_type="matriculationEmergency.blood_type = $event"
            @allergic="matriculationEmergency.allergic = $event"
            @name="matriculationEmergency.name = $event"
            @degree_of_kinship="matriculationEmergency.degree_of_kinship = $event"
            @address="matriculationEmergency.address = $event"
            @phone="matriculationEmergency.phone = $event"
            @extra_info="matriculationEmergency.extra_info = $event"
          />
          <br />
          <br />
          <v-row>
            <v-col cols="12" style="border: 1px solid #000;">
              <MatriculationSignature @conclusion="signature_finished = $event" :button="$vuetify.breakpoint.xsOnly ? 'Assinatura Digital' : 'Clique aqui para assinar a ficha de matrícula'" />
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                :disabled="!signature_finished"
                color="success"
                style="width: 200px; height: 75px"
                class="body-1"
                @click.stop="register()"
              >Gravar Dados</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar_error"
      color="error"
      :timeout="10000"
      top
      center
    >
      <v-row>
        <v-col cols="12">
          <span v-html="errors"></span>
        </v-col>

        <v-col cols="11" class="d-flex justify-center">
          <v-btn block @click.stop="snackbar_error = false">Fechar</v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import Matriculation from '@/app/components/Course/mixins/Matriculation'
import MatriculationInfo from '@/app/components/Course/MatriculationInfo'
import MatriculationPersonalData from '@/app/components/Course/MatriculationPersonalData'
import MatriculationDocumentation from '@/app/components/Course/MatriculationDocumentation'
import MatriculationInstructionLevel from '@/app/components/Course/MatriculationInstructionLevel'
import MatriculationImprovementCourses from '@/app/components/Course/MatriculationImprovementCourses'
import MatriculationForeignLanguages from '@/app/components/Course/MatriculationForeignLanguages'
import MatriculationAnacLicense from '@/app/components/Course/MatriculationAnacLicense'
import MatriculationEmergency from '@/app/components/Course/MatriculationEmergency'
import MatriculationSignature from '@/app/components/Course/Signature'

export default {
  mixins: [Matriculation],
  components: {
    MatriculationInfo,
    MatriculationPersonalData,
    MatriculationDocumentation,
    MatriculationInstructionLevel,
    MatriculationImprovementCourses,
    MatriculationForeignLanguages,
    MatriculationAnacLicense,
    MatriculationEmergency,
    MatriculationSignature
  }
}
</script>
