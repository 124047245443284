export default {
  data: () => ({
    form_data: {
      course1_name: '',
      course1_entity: '',
      course1_uf: '',
      course1_of_period: '',
      course1_to_period: '',
      course2_name: '',
      course2_entity: '',
      course2_uf: '',
      course2_of_period: '',
      course2_to_period: ''
    }
  }),
  watch: {
    'form_data.course1_name' (val) {
      this.$emit('course1_name', val)
    },
    'form_data.course1_entity' (val) {
      this.$emit('course1_entity', val)
    },
    'form_data.course1_uf' (val) {
      this.$emit('course1_uf', val)
    },
    'form_data.course1_of_period' (val) {
      this.$emit('course1_of_period', val)
    },
    'form_data.course1_to_period' (val) {
      this.$emit('course1_to_period', val)
    },
    'form_data.course2_name' (val) {
      this.$emit('course2_name', val)
    },
    'form_data.course2_entity' (val) {
      this.$emit('course2_entity', val)
    },
    'form_data.course2_uf' (val) {
      this.$emit('course2_uf', val)
    },
    'form_data.course2_of_period' (val) {
      this.$emit('course2_of_period', val)
    },
    'form_data.course2_to_period' (val) {
      this.$emit('course2_to_period', val)
    }
  }
}
