<template>
  <v-container
    class="mt-1"
    v-if="exam.questions.length > 0"
    :style="
      $vuetify.breakpoint.smAndDown ? '' : 'width: 75%; margin-top: -25px'
    "
  >
    <v-row>
      <v-col
        cols="12"
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'd-flex justify-start body-1 font-weight-bold'
            : 'd-flex justify-start headline font-weight-bold'
        "
        >{{ index + 1 }}) {{ exam.questions[index].question }}</v-col
      >
      <v-col
        cols="12"
        class="d-flex justify-center title font-weight-light"
      ></v-col>
      <div>
        <v-radio-group
          v-for="(item, index) in exam.questions[index].answers"
          :key="index"
          v-model="radios"
          :mandatory="false"
        >
          <v-radio :value="item.id">
            <template v-slot:label>
              <span>{{
                item.answer.charAt(0).toUpperCase() + item.answer.slice(1)
              }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn @click.stop="nextAnswer()" color="green" dark large
          >Próximo</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import 'moment-duration-format'

export default {
  props: ['exam'],
  data: () => ({
    index: 0,
    time: 0,
    start_answer: 0,
    radios: null,
    questionsAnswered: []
  }),
  computed: {
    ...mapState('Exam', ['started', 'attempt_id', 'timeInExam'])
  },
  methods: {
    ...mapActions('Exam', ['setErrorInExam']),
    nextAnswer () {
      const index = this.exam.questions.length - 1

      if (this.radios === null) {
        this.$snackbar.error(
          'É necessário responder a pergunta para poder continuar.'
        )
        return
      }

      this.questionsAnswered.push({
        exam_question_id: this.exam.questions[this.index].id,
        exam_answer_id: this.radios,
        start_answer: this.start_answer,
        end_answer: moment().unix(),
        time_to_answer: this.time
      })

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })

      if (index === -1 || index === this.index) {
        // Registra as questões do aluno no banco
        this.$loading.load('Salvando dados da prova...')
        this.examEnd()
        return
      }

      if (index >= 0) {
        this.start_answer = moment().unix()
        this.index++
        this.radios = null
        this.time = 0
      }
    },
    async examEnd () {
      // Registra as questões do aluno no banco
      await this.$api
        .post('/student/exam/finish', {
          exam_id: this.exam.id,
          registration_id: this.$router.currentRoute.params.registration_id,
          exam: {
            attempt_id: this.attempt_id,
            examEnd: moment().unix(),
            time: moment
              .duration(this.timeInExam, 'seconds')
              .format('HH:mm:ss', { trim: false }),
            results: this.questionsAnswered
          }
        })
        .then(() => {
          this.$loading.load('Calculando resultado...')

          this.questionsAnswered = []
          this.radios = null
          this.time = 0
          clearInterval(this.interval)
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
            this.$loading.loaded()
            this.$emit('endExam')
          }, 5000)
        })
        .catch(() => {
          this.setErrorInExam(true)
          this.$loading.loaded()
        })
    }
  },
  mounted () {
    if (this.started) {
      this.start_answer = moment().unix()
      this.interval = setInterval(() => {
        this.time++
      }, 1000)
    }
  }
}
</script>
