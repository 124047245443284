<template>
  <v-toolbar dark color="primary">
    <v-toolbar-title class="ml-10" v-if="!$vuetify.breakpoint.smAndDown">{{
      openedExam.name
    }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn
        dark
        text
        @click.stop="started ? (exit_dialog = true) : $emit('close')"
        >Fechar prova</v-btn
      >
    </v-toolbar-items>

    <v-dialog v-model="exit_dialog" max-width="500px">
      <CloseDialog @close="exit_dialog = false" @close-exam="closeExam()" />
    </v-dialog>
  </v-toolbar>
</template>

<script>
import { mapState } from 'vuex'
import CloseDialog from './CloseExamDialog'

export default {
  components: { CloseDialog },
  props: ['openedExam'],
  data: () => ({
    exit_dialog: false
  }),
  computed: {
    ...mapState('Exam', ['started'])
  },
  methods: {
    closeExam () {
      this.$emit('close-exam')
    }
  }
}
</script>
