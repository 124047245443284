<template>
  <div>
    <v-text-field
      v-bind="bindPassword"
      @input="inputPassword"
      :rules="[rules.required, rules.length(8)]"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="showPassword = !showPassword"
      color="primary"
      label="Senha"
      prepend-icon="mdi-lock-outline"
      :type="showPassword ? 'text' : 'password'"
      @keyup.enter="$emit('enter', true)"
    ></v-text-field>

    <v-text-field
      v-bind="bindPasswordConfirmation"
      @input="inputPasswordConfirmation"
      :rules="[rules.required, rules.length(8), passwordCheck]"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="showPassword = !showPassword"
      color="primary"
      label="Confirmação da senha"
      prepend-icon="mdi-lock-outline"
      :type="showPassword ? 'text' : 'password'"
      @keyup.enter="$emit('enter', true)"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data: () => ({
    showPassword: false,
    rules: {
      length: len => v => (v || '').length >= len || `Mínimo ${len} caracteres`,
      required: v => !!v || 'Este campo é obrigatório!'
    }
  }),
  $parseOutput ($event) {
    return $event
  },
  $parseInput (value) {
    return value
  },
  computed: {
    bindPassword () {
      return {
        ...this.$attrs,
        ...this.$props,
        value: this.$options.$parseInput(this.$props.item.password)
      }
    },
    bindPasswordConfirmation () {
      return {
        ...this.$attrs,
        ...this.$props,
        value: this.$options.$parseInput(this.$props.item.passwordConfirmation)
      }
    },
    passwordCheck (v) {
      return v =>
        v === this.item.password ||
        'A confirmação não bate com a senha digitada anteriormente.'
    }
  },
  methods: {
    inputPassword ($event) {
      const password = this.$options.$parseOutput($event)
      const passwordConfirmation = this.$options.$parseInput(
        this.item.passwordConfirmation
      )
      this.$emit('output', { password, passwordConfirmation })
    },
    inputPasswordConfirmation ($event) {
      const password = this.$options.$parseInput(this.item.password)
      const passwordConfirmation = this.$options.$parseOutput($event)
      this.$emit('output', { password, passwordConfirmation })
    }
  }
}
</script>
