<template>
  <v-file-input
    v-model="model"
    accept="image/png, image/jpeg, image/bmp"
    placeholder="Adicione uma foto"
    prepend-icon="mdi-camera"
    :label="label"
  ></v-file-input>
</template>

<script>
export default {
  props: ['label'],
  data: () => ({
    model: null,
    photo_base64: ''
  }),
  watch: {
    model () {
      this.photo_base64 = ''
      if (this.model) {
        let reader = new FileReader()
        reader.onloadend = e => {
          this.photo_base64 = e.target.result
        }
        reader.readAsDataURL(this.model)
      }
    },
    photo_base64 () {
      this.$emit('output', this.photo_base64)
    }
  }
}
</script>
