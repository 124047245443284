<template>
  <div>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      id="sidebar"
      v-model="drawer"
      app
      dark
      src="../assets/appbar-wallpaper.jpg"
      width="300"
    >
      <div id="sidebar-overlay"></div>
      <div id="sidebar-content">
        <v-list-item style>
          <v-list-item-title
            class="title font-weight-light"
            style="margin-bottom: 10px"
            >MENU</v-list-item-title
          >
          <v-list-item-title class="float-right">
            <CeabCredits @close_drawer="drawer = false" />
          </v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-btn
          text
          rounded
          class="mr-3 mt-3 mb-3"
          v-if="userData.personal_data"
        >
          <v-avatar
            width="28"
            height="28"
            style="border-radius: 1px solid #ccc"
          >
            <img
              v-if="userData.personal_data.photo"
              :src="$storage + userData.personal_data.photo"
            />
            <img v-else src="../assets/avatar.png" />
          </v-avatar>
          <b v-if="userData.personal_data">{{
            userData.personal_data.first_name
          }} <br /> {{ userData.personal_data.last_name }}</b>
        </v-btn>

        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item-group :value="item_index" :mandatory="true">
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              id="btn-spacing"
              @click.stop="changeRoute(item.path, 'item')"
            >
              <v-list-item-icon>
                <v-icon style="margin-left: 10px">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="subtitle-2">{{
                  item.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-app-bar color="white" height="60px" dense>
      <v-btn
        @click.stop="drawer = !drawer"
        v-if="$vuetify.breakpoint.smAndDown"
        small
        text
        fab
        class="mr-5"
        style="margin-left: -10px"
      >
        <v-icon color="grey darken-2">mdi-menu</v-icon>
      </v-btn>

      <v-toolbar-title style="margin-right: 60px">
        <v-img src="@/app/assets/logo.png" width="100px" />
      </v-toolbar-title>

      <v-tooltip bottom v-for="(item, index) in menu" :key="index">
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="!$vuetify.breakpoint.smAndDown"
            :class="item.active === 1 ? 'button-clicked' : ''"
            height="100%"
            x-large
            text
            v-on="on"
            @click="setActive(item)"
          >
            <v-icon size="28" :color="item.active === 1 ? '#1877F2' : '#000'">{{
              item.icon
            }}</v-icon>
          </v-btn>
        </template>
        <span v-if="!$vuetify.breakpoint.smAndDown">{{ item.name }}</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-btn
        text
        rounded
        class="mr-3"
        v-if="userData.personal_data && !$vuetify.breakpoint.xsOnly"
      >
        <v-avatar width="28" height="28" style="border-radius: 1px solid #ccc">
          <img
            v-if="userData.personal_data.photo"
            :src="$storage + userData.personal_data.photo"
          />
          <img v-else src="../assets/avatar.png" />
        </v-avatar>
        <b v-if="userData.personal_data">{{
          userData.personal_data.full_name
        }}</b>
      </v-btn>

      <CeabCredits v-if="!$vuetify.breakpoint.xsOnly" />

      <Notify />

      <v-btn small fab elevation="0" color="#D8DADF" class="mr-2">
        <v-icon size="20" @click.stop="$router.push({ name: 'Editar Perfil' }).catch(() => {})"
          >mdi-settings</v-icon
        >
      </v-btn>

      <v-btn small fab elevation="0" color="#D8DADF">
        <v-icon size="20" @click.stop="userLogout(true)">mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Notify from './Notify'
import CeabCredits from './CeabCredits/CeabCredits'

export default {
  components: { Notify, CeabCredits },
  data: () => ({
    drawer: null,
    drawerRight: null,
    right: false,
    left: false,
    menu: [
      {
        id: 1,
        name: 'Página Inicial',
        path: '/',
        icon: 'mdi-home',
        active: 1
      },
      {
        id: 2,
        name: 'Cursos',
        path: '/cursos',
        icon: 'mdi-school',
        active: 0
      },
      {
        id: 3,
        name: 'Loja',
        path: '/loja',
        icon: 'mdi-store',
        active: 0
      },
      {
        id: 4,
        name: 'Histórico de Compras',
        path: '/compras/historico',
        icon: 'mdi-cart',
        active: 0
      }
    ],
    item_index: undefined,
    items: [
      {
        id: 1,
        name: 'Página Inicial',
        path: '/',
        icon: 'mdi-home'
      },
      {
        id: 2,
        name: 'Cursos',
        path: '/cursos',
        icon: 'mdi-school'
      },
      {
        id: 3,
        name: 'Loja',
        path: '/loja',
        icon: 'mdi-store'
      },
      {
        id: 4,
        name: 'Histórico de Compras',
        path: '/compras/historico',
        icon: 'mdi-cart'
      }
    ]
  }),
  computed: {
    ...mapState('user', ['userData'])
  },
  methods: {
    ...mapActions('user', ['userLogout']),
    setActive (item) {
      for (const i in this.menu) {
        if (this.menu[i].active === 1) {
          this.menu[i].active = 0
        }

        if (this.menu[i].id === item.id) {
          this.menu[i].active = 1
        }
      }

      this.$router.push(item.path).catch(() => {})
    },
    changeRoute (path, type) {
      let currentPath = this.$router.currentRoute.path
      if (path !== currentPath) {
        switch (type) {
          case 'item':
            this.$router.push(path).catch(() => {})
            break

          case 'profile':
            this.$router.push(path).catch(() => {})
            break
        }
      }
    }
  },
  mounted () {
    // alert(this.$vuetify.breakpoint.xsOnly)
  }
}
</script>

<style scoped>
.button-clicked {
  border-radius: 0px;
  border-bottom: 3px solid #1877f2;
}

#sidebar {
  position: fixed;
  z-index: 10000000;
}

#sidebar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0.4));
}

#sidebar-content {
  position: relative;
  z-index: 30000;
  padding: 10px;
  width: 100%;
}

#btn-spacing {
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 7px;
}
</style>
