import Vue from 'vue'
import $store from '../store'

Vue.use({
  install (Vue) {
    Vue.prototype.$ipModule = {
      updateIp: () => $store.dispatch('ipModule/updateIp'),
      getIp: () => $store.state.ipModule.ip
    }
  }
})
