<template>
  <v-container fluid>
    <v-simple-table :fixed-header="true">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Curso</th>
            <th class="text-left">Valor</th>
            <th class="text-left">Status</th>
            <th class="text-left">Boleto</th>
            <th class="text-left">Vencimento</th>
            <th class="text-left">Criado em</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.course.name }}</td>
            <td>{{ item.cost | currency }}</td>
            <td>{{ item.status | status }}</td>
            <td>
              <v-btn color="primary" v-if="item.billet !== null" :href="item.billet" target="_blank">Clique aqui</v-btn>
            </td>
            <td>
              {{ item.due_date | date }}
            </td>
            <td>
              {{ item.created_at | date }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y')
    },
    status (val) {
      let message

      switch (val) {
        case 0:
          message = 'Pendente'
          break

        case 1:
          message = 'Vencido'
          break

        case 2:
          message = 'Confirmado'
      }

      return message
    }
  },
  data: () => ({
    items: []
  }),
  methods: {
    async getCeabCreditsHistory () {
      const url = '/student/purchases/courses'

      await this.$api.get(url).then((res) => {
        this.items = res.data
      })
    }
  },
  beforeMount () {
    this.getCeabCreditsHistory()
  }
}
</script>
