export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('_token'),
    userData: [],
    permissions: []
  },
  getters:
  {
    hasToken (state) {
      return !!state.token
    },
    isLogged (state, getters) {
      return state.userData.id > 0 && getters.hasToken
    },
    isAdmin (state, getters) {
      return state.userData.id > 0 && getters.hasToken && state.userData.student === 0 && state.userData.seller === 0
    }
  },
  mutations: {
    updateUserData (state, payload) {
      state.userData = payload
      if (payload.roles.length > 0) {
        state.permissions = payload.roles[0].permissions.map(item => {
          return item.name
        })
      }
    },
    userLogout (state) {
      localStorage.removeItem('_token')
      state.userData = []
    }
  },
  actions: {
    async setUser ({ commit }) {
      await this._vm.$api.post('/auth/user').then(res => {
        commit('updateUserData', res.data)
      })
        .catch(() => {
          localStorage.removeItem('_token')
          window.location.href = '/login?#'
        })
    },
    async updateUser ({ getters, commit }) {
      if (getters.isLogged) {
        await this._vm.$api.post('/auth/user').then(res => {
          commit('updateUserData', res.data)
        }).catch(() => {
          localStorage.removeItem('_token')
          window.location.href = '/login?#'
        })
      }
    },
    userLogout ({ commit }, redirect) {
      commit('userLogout')

      if (redirect === true) {
        window.location.href = '/login?#'
      }
    }
  }
}
