export default {
  methods: {
    checkContract (item) {
      if (item.has_contract) {
        this.item_selected = { ...item.contract }

        const url = '/student/course_contract/check/'
        const courseId = this.course_loaded.course_type === 1 ? this.course_loaded.course_id : this.course_loaded.course_father.id

        return this.$api
          .get(
            `${url}${courseId}/${this.course_loaded.registration_id}`
          )
          .then((res) => {
            if (!res.data.access) {
              this.contract_dialog = true
              return
            }

            if (this.course_loaded.name === 'Prisma Club') {
              this.$router.push({
                name: 'Prisma Club',
                params: {
                  course_id: this.course_loaded.course_id,
                  registration_id: this.course_loaded.registration_id
                }
              }).catch(() => { })
              return
            }

            this.checkMatriculation()
          })
          .finally(() => {
            this.loading = false
          })
      }

      if (this.course_loaded.name === 'Prisma Club') {
        this.$router.push({
          name: 'Prisma Club',
          params: {
            course_id: this.course_loaded.course_id,
            registration_id: this.course_loaded.registration_id
          }
        }).catch(() => { })
        return
      }

      return this.checkMatriculation()
    }
  }
}
