export default {
  install (Vue) {
    Vue.directive('destroy', {
      inserted (el, binding, vnode) {
        const remove = (vnode) => vnode.elm.parentElement.removeChild(vnode.elm)
        remove(vnode)
      }
    })
  }
}
