<template>
  <v-row>
    <v-col cols="12">
      <v-divider></v-divider>
      <span class="d-flex justify-center headline mt-8">DOCUMENTAÇÃO</span>
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
      <RG
        v-model="form_data.rg"
        :label="
      `
      IDENTIDADE (RG)
          <span style='color: red; font-size: 24px'>*</span>
      `"
      />
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
      <v-text-field v-model="form_data.issuing_body">
        <template v-slot:label>
          ÓRGÃO EXPEDITOR
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
      <Date v-model="form_data.issuance_date" :label="
      `
        DATA EMISSÃO <span style='color: red; font-size: 24px'>*</span>
      `
      " />
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
      <CPF :item="form_data.cpf" @output="form_data.cpf = $event" :label="`
        CPF <span style='color: red; font-size: 24px'>*</span>
      `" />
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.reservist" label="RESERVISTA" />
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.category" label="CATEGORIA" />
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.voter_title">
        <template v-slot:label>
          TÍTULO DE ELEITOR
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.zone" label="ZONA" />
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.section" label="SEÇÃO" />
    </v-col>
  </v-row>
</template>

<script>
import MatriculationDocumentationMixin from '@/app/components/Course/mixins/MatriculationDocumentation'
import CPF from '@/shared/components/text-fields/CPF'
import RG from '@/shared/components/text-fields/RG'
import Date from '@/shared/components/text-fields/Date'

export default {
  components: { RG, CPF, Date },
  mixins: [MatriculationDocumentationMixin]
}
</script>
