export default {
  namespaced: true,
  state: {
    show: false,
    text: ''
  },
  getters: {
    //
  },
  mutations: {
    load (state, payload) {
      state.show = true
      state.text = payload
    },
    loaded (state) {
      state.show = false
      state.text = ''
    }
  },
  actions: {
    load ({ commit }, message) {
      commit('load', message)
    },
    loaded ({ commit }) {
      commit('loaded')
    }
  }
}
