<template>
  <v-row>
    <v-col cols="12" class="mt-2">
      <span style="color: #424242" class="font-weight-bold title"
        >Selecione a modalidade do curso</span
      >
      <br />
      <span style="color: #424242" class="font-weight-bold body-2"
        >Observação: Dependendo da modalidade que escolher o preço pode variar,
        você pode assistir essa mudança ao ver o valor total da compra no
        carrinho antes de finalizar a compra.</span
      >
    </v-col>
    <v-col>
      <v-radio-group
        @change="setCourseClass($event)"
        v-model="course_class_selected"
        v-if="course_loaded.has_course_class === 1"
      >
        <div
          style="background-color: #e0e0e0"
          class="mb-5"
          v-for="(item, index) in course_loaded.course_class"
          :key="index"
        >
          <v-radio style="height: 100px" :value="item.id" class="ml-5">
            <template v-slot:label>
              <span
                style="text-transform: uppercase"
                :class="
                  mobile
                    ? 'body-1 font-weight-regular'
                    : 'ml-10 body-1 font-weight-regular'
                "
                >{{ item.name }}</span
              >
              <v-btn
                :style="
                  mobile
                    ? $vuetify.breakpoint.xsOnly
                      ? 'position: absolute; left: 55%; top: 110%;'
                      : 'position: absolute; left: 55%;'
                    : 'position: absolute; left: 80%'
                "
                text
                rounded
              >
                <v-avatar
                  width="28"
                  height="28"
                  style="border-radius: 1px solid #ccc"
                >
                  <v-icon>mdi-coin-outline</v-icon>
                </v-avatar>
                <b>{{ item.cost_credits }}</b>
              </v-btn>
            </template>
          </v-radio>
        </div>
      </v-radio-group>
      <v-radio-group :value="1" v-if="course_loaded.has_course_class === 0">
        <div style="background-color: #e0e0e0" class="mb-5">
          <v-radio style="height: 100px" :value="1" class="ml-5">
            <template v-slot:label>
              <span
                v-if="!onlySubscription"
                style="text-transform: uppercase"
                :class="
                  mobile
                    ? 'body-1 font-weight-regular'
                    : 'ml-10 body-1 font-weight-regular'
                "
                >Ensino a distância</span
              >
              <span
                else="!onlySubscription"
                style="text-transform: uppercase"
                class="ml-10 body-1 font-weight-regular"
                >{{ course_loaded.name }} (Assinatura)</span
              >
              <v-btn :style="
                  mobile
                    ? $vuetify.breakpoint.xsOnly
                      ? 'position: absolute; left: 55%; top: 110%;'
                      : 'position: absolute; left: 55%;'
                    : 'position: absolute; left: 80%'
                " text rounded>
                <v-avatar
                  width="28"
                  height="28"
                  style="border-radius: 1px solid #ccc"
                >
                  <v-icon>mdi-coin-outline</v-icon>
                </v-avatar>
                <b v-if="!onlySubscription">{{
                  course_loaded.course_ead.cost_credits | currency("")
                }}</b>
                <b v-else>{{ cost | currency("") }}</b>
              </v-btn>
            </template>
          </v-radio>
        </div>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    course_class_selected: []
  }),
  computed: {
    ...mapState('courseStore', ['course_loaded']),
    ...mapGetters('courseStore', ['onlySubscription', 'cost'])
  },
  methods: {
    ...mapActions('courseStore', ['setCourseClass'])
  },
  mounted () {
    if (this.course_loaded.has_course_class === 1) {
      let courseClassId = Object.values(this.course_loaded.course_class)[0].id
      this.course_class_selected = courseClassId
      this.setCourseClass(courseClassId)
    }
  }
}
</script>
