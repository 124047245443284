<template>
  <v-container v-if="this.exam.length > 0" style="width: 75%; margin-top: -25px">
    <v-row>
      <v-col
        cols="12"
        :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-start body-1 font-weight-bold' : 'd-flex justify-start headline font-weight-bold'"
      >{{ index + 1 }}) {{ questions[index].question }}</v-col>
      <v-col cols="12" class="d-flex justify-center title font-weight-light"></v-col>
      <div>
        <v-radio-group
          v-for="(item, index) in questions[index].answers"
          :key="index"
          v-model="radios"
          :mandatory="false"
        >
          <v-radio :label="item.answer" :value="item.id"></v-radio>
        </v-radio-group>
      </div>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn @click.stop="nextAnswer()" color="green" dark large>Próximo</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { TimeUTCSeconds, secondsToTime } from '../../../utils/Timer'

export default {
  props: ['exam', 'examTime'],
  data: () => ({
    questions: undefined,
    index: 0,
    time: 0,
    start_answer: undefined,
    radios: undefined,
    questionsAnswered: [],
    interval: undefined
  }),
  methods: {
    nextAnswer () {
      const index = this.questions.length - 1

      if (this.radios === undefined) {
        this.$snackbar.error(
          'É necessário responder a pergunta para poder continuar.'
        )
        return
      }

      this.questionsAnswered.push({
        exam_question_id: this.questions[this.index].id,
        exam_id: this.questions[this.index].exam_id,
        block_number: this.questions[this.index].block_number,
        exam_answer_id: this.radios,
        start_answer: this.start_answer,
        end_answer: TimeUTCSeconds(),
        time_to_answer: secondsToTime(this.time)
      })

      console.log(this.questionsAnswered)

      if (index === -1 || index === this.index) {
        this.radios = undefined
        this.time = 0
        clearInterval(this.interval)
        this.$snackbar.success('Finalizado!')
        this.$emit('examEnd', this.questionsAnswered)
        return
      }

      if (index >= 0) {
        this.start_answer = TimeUTCSeconds()
        this.index++
        this.radios = undefined
        this.time = 0
      }
    }
  },
  watch: {
    examTime (val) {
      if (val === 0) {
        this.$emit('examEnd', this.questionsAnswered)
      }
    }
  },
  beforeMount () {
    this.questions = this.exam
  },
  mounted () {
    if (this.questions !== undefined) {
      this.start_answer = TimeUTCSeconds()
      setInterval(() => {
        this.time++
      }, 1000)
    }
  }
}
</script>
