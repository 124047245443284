// Converte segundos para horas
export const secondsToTime = (seconds, format) => {
  if (format === 'd:h:m:s') {
    return [
      parseInt(seconds / 86400),
      parseInt(seconds / 60 / 60 % 24),
      parseInt(seconds / 60 % 60),
      parseInt(seconds % 60)
    ]
      .join(':')
      .replace(/\b(\d)\b/g, '0$1')
  }

  if (format === 'h:m:s') {
    return [
      parseInt(seconds / 60 / 60),
      parseInt(seconds / 60 % 60),
      parseInt(seconds % 60)
    ]
      .join(':')
      .replace(/\b(\d)\b/g, '0$1')
  }

  if (format === 'h:m') {
    return [
      parseInt(seconds / 60 / 60),
      parseInt(seconds / 60 % 60)
    ]
      .join(':')
      .replace(/\b(\d)\b/g, '0$1')
  }
}

// converte horas para segundos
export const timeToSeconds = (hms) => {
  var time = hms.split(':')
  return (+time[0]) * 60 * 60 + (+time[1]) * 60 + (+time[2])
}

// Pega a hora em UTC
export const timeNow = () => {
  return new Date().toISOString().slice(11, 19)
}

// Pega a hora em locale
export const timeNowLocale = (locale) => {
  return new Date().toLocaleTimeString(locale)
}

// Converte a hora em outro fuso para o fuso UTC
export const changeTimezone = (date, hms, GMT) => {
  if (date === 'now') {
    return {
      date: new Date(`${dateNow()} ${hms} GMT${GMT}`).toISOString().slice(0, 10),
      time: new Date(`${dateNow()} ${hms} GMT${GMT}`).toISOString().slice(11, 19)
    }
  }

  return {
    date: new Date(`${date} ${hms} GMT${GMT}`).toISOString().slice(0, 10),
    time: new Date(`${date} ${hms} GMT${GMT}`).toISOString().slice(11, 19)
  }
}

// Pega a data em UTC
export const dateNow = () => {
  return new Date().toISOString().substr(0, 10)
}

// Adiciona tempo
export const increaseTime = (hms, add) => {
  return hms === 'now'
    ? secondsToTime(timeToSeconds(new Date().toLocaleString().slice(11, 19)) + timeToSeconds(add))
    : secondsToTime(timeToSeconds(hms) + timeToSeconds(add))
}

// remove tempo
export const decreaseTime = (hms, add) => {
  return hms === 'now'
    ? secondsToTime(timeToSeconds(new Date().toLocaleString().slice(11, 19)) - timeToSeconds(add))
    : secondsToTime(timeToSeconds(hms) - timeToSeconds(add))
}

// Pega a data e hora em UTC
export const TimeUTC = () => {
  const date = new Date()
  const UTCSeconds = (Math.floor(date.getTime() / 1000) + date.getTimezoneOffset() * 60)
  return new Date(UTCSeconds * 1000).toLocaleString()
}

// Pega a data e hora em UTC e converte para segundos
export const TimeUTCSeconds = () => {
  const date = new Date()
  const UTCSeconds = (Math.floor(date.getTime() / 1000) + date.getTimezoneOffset() * 60)

  return UTCSeconds
}

// Pega a data e hora em UTC e converte para o horário local informado
export const UTCTimeToLocale = (date, hms, locale) => {
  new Date(`${date} ${hms} GMT+00:00`).toLocaleTimeString(locale)
}

export const dateDiff = (date1, date2) => {
  let _date1 = new Date(`${date1} GMT+00:00`).getTime()
  let _date2 = new Date(`${date2} GMT+00:00`).getTime()

  return Math.abs(_date1 - _date2) / 1000
}

// verifica se a data1 é maior que data2
export const dateBigger = (date1, date2) => {
  let _date1 = new Date(`${date1} GMT+00:00`).getTime()
  let _date2 = new Date(`${date2} GMT+00:00`).getTime()

  return _date1 >= _date2
}

// verifica se a data1 é menor que data2
export const dateLess = (date1, date2) => {
  let _date1 = new Date(`${date1} GMT+00:00`).getTime()
  let _date2 = new Date(`${date2} GMT+00:00`).getTime()

  return _date1 <= _date2
}

export const format = (date, format) => {
  const [year, month, day] = date.split('-')
  var newDate = ''

  switch (format) {
    case 'd/m/y':
      newDate = `${day}/${month}/${year}`
      break
    case 'd-m-y':
      newDate = `${day}-${month}-${year}`
      break
    default:
      newDate = date
  }

  return newDate
}

export const TimeFormat = (time, format) => {
  const [HH, MM, DD] = time.split(':')

  var date = ''

  switch (format) {
    case 'H:M:S':
      date = `${HH}:${MM}:${DD}`
      break

    case 'H:M':
      date = `${HH}:${MM}`
      break

    case 'M:S':
      date = `${MM}:${DD}`
      break
  }

  return date
}

// A data passada deve estar no padrão:
// DD/MM/YYYY HH:mm
export const toISOFormat = (dateTimeString) => {
  // Primeiro, dividimos a data completa em duas partes:
  const [date, time] = dateTimeString.split(' ')

  // Dividimos a data em dia, mês e ano:
  const [DD, MM, YYYY] = date.split('/')

  // Dividimos o tempo em hora e minutos:
  const [HH, mm, dd] = time.split(':')

  // Retornamos a data formatada em um padrão compatível com ISO:
  return `${YYYY}-${MM}-${DD}T${HH}:${mm}:${dd}`
}

// A data passada deve estar no padrão:
// DD/MM/YYYY HH:mm
export const toDBFormat = (dateTimeString) => {
  // Primeiro, dividimos a data completa em duas partes:
  const [date, time] = dateTimeString.split(' ')

  // Dividimos a data em dia, mês e ano:
  const [DD, MM, YYYY] = date.split('/')

  // Dividimos o tempo em hora e minutos:
  const [HH, mm, dd] = time.split(':')

  // Retornamos a data formatada em um padrão compatível com ISO:
  return `${YYYY}-${MM}-${DD} ${HH}:${mm}:${dd}`
}
