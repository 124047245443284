<template>
  <v-col v-if="faq_channel_questions.length > 0 && show" cols="12" md="3">
    <div>
      <v-img
        width="200px"
        @click.stop="openFAQ()"
        src="@/app/assets/forum.png"
      ></v-img>
      <v-dialog v-model="faq_dialog" persistent max-width="600px">
        <v-card v-if="faq_dialog">
          <v-card-title class="ml-5">
            <v-spacer></v-spacer>
            <span>Fórum</span>
            <v-spacer></v-spacer>
            <v-btn fab small elevation="1" @click="closeFaq()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-tabs
              class="d-flex justify-center"
              v-model="tab"
              background-color="white"
              color="blue"
              center
            >
              <span v-for="(item, index) in faq_channel" :key="index">
                <v-tab
                  :style="
                    item.channel_name === 'Aula ao Vivo'
                      ? 'height: 50px; color: red'
                      : 'height: 50px'
                  "
                  >{{ item.channel_name }}</v-tab
                >
              </span>
            </v-tabs>

            <v-divider></v-divider>

            <span
              v-if="
                faq_channel_questions[tab] !== null &&
                faq_channel_questions[tab] !== undefined &&
                faq_channel_questions[tab].my_questions !== null
              "
              class="body-1 d-flex justify-center font-weight-bold mt-5"
              >Minhas Perguntas</span
            >
            <div
              class="mt-5"
              v-if="
                faq_channel_questions[tab] !== null &&
                faq_channel_questions[tab] !== undefined &&
                faq_channel_questions[tab].my_questions !== null
              "
            >
              <v-card
                v-for="(item, index) in faq_channel_questions[tab].my_questions"
                class="mb-2"
                :key="index"
              >
                <v-card-title
                  class="body-1"
                  style="cursor: pointer"
                  v-html="item.question"
                  @click.stop="openQuestion(item)"
                ></v-card-title>
                <v-divider></v-divider>
                <div v-if="question_show[item.id]">
                  <v-card-text
                    v-if="item.answer !== null"
                    v-html="'&nbsp;&nbsp;&nbsp;&nbsp;' + item.answer"
                  >
                  </v-card-text>
                  <v-card-text class="caption text-center" v-else>
                    Ops! Parece que você ainda está sem resposta :(
                  </v-card-text>
                </div>
              </v-card>
            </div>

            <v-divider></v-divider>

            <span
              v-if="
                faq_channel_questions[tab] !== null &&
                faq_channel_questions[tab] !== undefined &&
                faq_channel_questions[tab].questions !== null
              "
              class="body-1 d-flex justify-center font-weight-bold mt-5"
              >Perguntas da Disciplina</span
            >
            <div
              class="mt-5"
              multiple
              v-if="
                faq_channel_questions[tab] !== null &&
                faq_channel_questions[tab] !== undefined
              "
            >
              <div
                v-for="(item, index) in faq_channel_questions[tab].questions"
                :key="index"
              >
                <div class="body-1" v-html="item.question"></div>
                <div v-if="item.answer !== null">
                  <v-container>
                    {{ item.answer }}
                  </v-container>
                </div>
                <div class="caption text-center" v-else>
                  Ops! Parece que você ainda está sem resposta :(
                </div>
              </div>
            </div>

            <div class="d-flex justify-center mt-5">
              <v-btn color="primary" @click.stop="create_question = true"
                >Quero fazer uma pergunta!</v-btn
              >
            </div>

            <v-dialog
              v-if="create_question"
              v-model="create_question"
              persistent
              max-width="500px"
            >
              <v-card>
                <v-card-title>
                  <v-spacer></v-spacer>
                  <span>Fórum</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    fab
                    small
                    elevation="1"
                    @click="create_question = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-select
                    disabled
                    label="Canal da Pergunta"
                    :items="faq_channel"
                    item-value="id"
                    item-text="channel_name"
                    v-model="channel_id"
                  ></v-select>
                  <v-textarea
                    label="Digite sua pergunta aqui!"
                    v-model="question"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click.stop="sendQuestion()"
                    >Enviar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-col>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['discipline'],
  data: () => ({
    show: false,
    question_show: [],
    settedTab: false,
    tab: 0,
    create_question: false,
    faq_dialog: false,
    selected_lesson: undefined,
    course_id: undefined,
    registration_id: undefined,
    faq_channel: null,
    faq_channel_questions: [],
    question: '',
    channel_id: null,
    interval: null
  }),
  watch: {
    tab (val) {
      if (this.show && this.faq_channel.length > 0) {
        this.channel_id = this.faq_channel[val].id
      }
    }
  },
  computed: {
    ...mapState('course', ['course_loaded'])
  },
  methods: {
    openQuestion (item) {
      this.question_show[item.id] = !this.question_show[item.id]
    },
    openFAQ () {
      this.faq_dialog = true
      this.interval = setInterval(() => {
        this.getFaqChannel()
      }, 30000)
    },
    closeFaq () {
      this.faq_dialog = false
      clearInterval(this.interval)
    },
    async getFaqChannel () {
      await this.$api
        .get(
          `/student/faq_channel/questions/${this.$router.currentRoute.params.course_id}/${this.discipline.discipline_id}/${this.$router.currentRoute.params.registration_id}`
        )
        .then((res) => {
          this.faq_channel = res.data
          var data = this.faq_channel.map((item, index) => {
            return {
              ...item,
              index: index
            }
          })

          const tab =
            data.filter(
              (item) => item.questions !== null || item.my_questions !== null
            ).length > 0
              ? data.filter(
                (item) =>
                  item.questions !== null || item.my_questions !== null
              )
              : data

          if (data.length > 0 && this.settedTab === false) {
            this.settedTab = true
            this.tab = tab[0].index
          }

          this.faq_channel_questions = this.faq_channel

          const questionsId = this.faq_channel
            .filter((item) => item.my_questions !== null)
            .map((item) => item.my_questions.map((item) => item.id))
            .flat()

          let arr = {}
          for (let questions in questionsId) {
            arr[questionsId[questions]] = false
          }

          this.question_show = arr
        })
        .finally(() => {
          this.channel_id = this.faq_channel[this.tab].id
        })
    },
    async sendQuestion () {
      const channelType = this.faq_channel
        .filter((item) => item.id === this.faq_channel[this.tab].id)
        .map((item) => item.channel_type)[0]

      await this.$api.post(`/student/faq_channel/send`, {
        course_id: this.$router.currentRoute.params.course_id,
        discipline_id: this.discipline.discipline_id,
        registration_id: this.$router.currentRoute.params.registration_id,
        course_class_id: this.course_loaded.course_class_id,
        question: this.question,
        channel_id: this.channel_id,
        channel_type: channelType
      })

      this.create_question = false
      this.getFaqChannel()

      this.clear()
    },
    clear () {
      this.question = ''
    }
  },
  beforeMount () {
    this.getFaqChannel()
  },
  mounted () {
    setTimeout(() => {
      this.show = true
    }, 2000)
  }
}
</script>
