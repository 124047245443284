<template>
  <div>
    <v-card class="mb-5" :elevation="elevation">
      <v-card-title class="d-flex justify-center"
        >Certificado disponível!</v-card-title
      >
      <v-card-text>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn color="grey darken-4" dark @click="openCertified('front')"
            >Frente</v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-center">
          <v-btn color="grey darken-4" dark @click="openCertified('verse')"
            >Verso</v-btn
          >
        </v-col>
      </v-card-text>
    </v-card>

    <v-dialog v-model="certified_alert_dialog" max-width="500px">
      <v-card>
        <v-card-title class="d-flex justify-center"
          >Imprimir Certificado</v-card-title
        >
        <v-card-text>
          <v-container>
            <p>
              Ao marcar essa opção, você confirma que leu a mensagem abaixo e
              está de acordo com a afirmação contida na mesma.
            </p>
            <p>
              Eu, {{ userData.personal_data.first_name }}
              {{ userData.personal_data.last_name }}, CPF: {{ userData.cpf }},
              declaro que retirei o certificado referente ao curso
              {{ course_loaded.name }} no dia {{ time }}.
            </p>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click.stop="certified_alert_dialog = false"
            >Cancelar</v-btn
          >
          <v-btn text color="primary" @click="registerCertified()"
            >Confirmar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  props: ['registration', 'elevation'],
  data: () => ({
    certificate: [],
    selected: '',
    userData: [],
    time: '',
    show: false,
    certified_alert_dialog: false
  }),
  computed: {
    ...mapState('course', ['course_loaded'])
  },
  methods: {
    getTime () {
      this.time =
        moment().format('DD/MM/YYYY') +
        ' as ' +
        moment().format('HH:mm')
    },
    async getCertificate () {
      this.$loading.load('Obtendo dados do certificado...')
      let url = '/student/main_course/certified/findByCourseIdToRegId/'

      await this.$api
        .get(
          `${url}${this.course_loaded.course_id}/${this.registration.student_id}/${this.course_loaded.registration_id}`
        )
        .then((res) => {
          this.certificate = res.data
          let timeToGetCertifed =
            new Date(res.data.created_at).getTime() + 86400 * 1000
          let dateToGetCertified = new Date(timeToGetCertifed)
            .toISOString()
            .substr(0, 10)
          if (moment().isAfter(dateToGetCertified)) {
            this.$emit('expiredCertificate', true)
            this.show = false
          } else {
            this.show = true
          }
        })
        .finally(() => {
          if (this.certificate.status === 'certified_not_found') {
            this.show = true
          }

          this.$loading.loaded()
        })
    },
    async registerCertified () {
      this.certified_alert_dialog = false
      await this.$api
        .post('/student/main_course/certified/register', {
          registration_id: this.course_loaded.registration_id,
          course_id: this.course_loaded.course_id
        })
        .then(() => {
          this.getCertificate().then(() => {
            if (this.selected === 'front') {
              this.openFront()
            }

            if (this.selected === 'verse') {
              this.openVerse()
            }
          })
        })
    },
    openCertified (type) {
      if (type === 'front') {
        if (this.certificate.status !== 'certified_not_found') {
          this.openFront()
        } else {
          this.selected = 'front'
          this.certified_alert_dialog = true
        }
      }

      if (type === 'verse') {
        if (this.certificate.status !== 'certified_not_found') {
          this.openVerse()
        } else {
          this.selected = 'verse'
          this.certified_alert_dialog = true
        }
      }
    },
    openFront () {
      window.open(
        `${this.$api_url}/student/main_course/certified/front/${this.course_loaded.course_id}/${this.registration.student_id}/${this.course_loaded.registration_id}/${this.certificate['authentication']}`
      )
    },
    openVerse () {
      window.open(
        `${this.$api_url}/student/main_course/certified/verse/${this.course_loaded.course_id}/${this.registration.student_id}/${this.course_loaded.registration_id}/${this.certificate['authentication']}`
      )
    }
  },
  watch: {
    registration (val) {
      if (val !== undefined) {
        this.getCertificate()
      }
    },
    certified_alert_dialog (val) {
      if (val === true) {
        this.getTime()
      }
    },
    'registration.liberated_certified' (val) {
      if (val !== 1) {
        this.show = false
        this.$emit('noCertified', true)
      }
    }
  },
  beforeMount () {
    this.userData = this.$store.state.user.userData

    if (this.registration !== undefined) {
      this.getCertificate()
    }
  }
}
</script>
