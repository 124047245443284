<template>
  <div>
    <banner-magazine class="d-flex justify-center" />
    <div
      class="grandParentContaniner"
      v-if="settings.home_video !== null || settings.home_video !== ''"
    >
      <div class="parentContainer">
        <v-container :style="videoWidth">
          <v-card>
            <v-card-text>
              <div class="embed-container">
                <iframe
                  width="100%"
                  height="100%"
                  :src="`https://www.youtube.com/embed/${settings.home_video}`"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  webkitAllowFullScreen
                  mozallowfullscreen
                ></iframe>
              </div>
            </v-card-text>
          </v-card>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BannerMagazine from '@/app/components/BannerMagazine'
import whatsapp from '@/app/utils/whatsapp'

export default {
  components: { BannerMagazine },
  data: () => ({
    settings: {}
  }),
  computed: {
    videoWidth () {
      var width = ''

      if (this.$vuetify.breakpoint.xsOnly) {
        width = 'width: 350px'
      }

      if (this.$vuetify.breakpoint.smOnly) {
        width = 'width: 480px'
      }

      if (this.$vuetify.breakpoint.mdAndUp) {
        width = 'width: 800px'
      }

      return width
    }
  },
  methods: {
    ...mapActions('course', ['loadCourses', 'selectCourse']),
    async getSettings () {
      await this.$api.get('/admin/settings').then((res) => {
        this.settings = res.data
      })
    }
  },
  beforeMount () {
    this.getSettings()
    this.loadCourses()

    whatsapp()
  }
}

</script>

<style scoped>
.grandParentContaniner {
  display: table;
  height: 100%;
  margin: 0 auto;
}
.parentContainer {
  display: table-cell;
  vertical-align: middle;
}
.parentContainer #loginForm {
  background: #ddd;
  border: 1px solid #eee;
  padding: 10px;
  width: auto; /*  your login form width */
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
