<template>
  <div v-if="course_loaded !== null">
    <v-card class="mb-2" v-if="course_loaded.link_treinamento_pratico !== null && course_loaded.link_treinamento_pratico !== ''">
      <v-card-text>
          <v-row>
            <v-col
              :class="$vuetify.breakpoint.xsOnly ? 'd-flex justify-center d-flex justify-center' : 'd-flex justify-center'"
              cols="12"
              v-if="
                course_loaded.course_type === 1 && course_loaded.course_id === 1
              "
            >
              <a :href="course_loaded.link_treinamento_pratico" target="_blank">
                <v-img
                  style="border: 1px solid #ccc; border-radius: 7px"
                  width="200px"
                  src="@/app/assets/treinamento-pratico.jpg"
                ></v-img>
              </a>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title class="d-flex justify-center"
        >Informações Complementares</v-card-title
      >

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center"
              style="margin-top: -25px"
            >
              <v-btn @click.stop="openLink()" dark :color="setColor"
                >LINK DA AULA AO VIVO</v-btn
              >
              <v-btn
                v-if="course_loaded.start_meeting.how_access_pdf !== null"
                @click.stop="openHelp()"
                fab
                color="primary"
                class="ml-1"
                small
              >
                <v-icon>mdi-comment-question-outline</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <v-btn @click.stop="openPDF()" color="primary"
                >Cronograma da Turma</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    interval: null,
    show: false
  }),
  computed: {
    ...mapState('course', ['course_loaded']),
    setColor () {
      return this.course_loaded.start_meeting.channel_url !== null &&
        this.course_loaded.start_meeting.channel_url !== undefined &&
        this.course_loaded.start_meeting.channel_url !== ''
        ? 'green'
        : 'red'
    }
  },
  methods: {
    openLink () {
      if (
        this.course_loaded.start_meeting.channel_url !== null &&
        this.course_loaded.start_meeting.channel_url !== undefined
      ) {
        window.open(this.course_loaded.start_meeting.channel_url)
      }
    },
    openPDF () {
      if (
        this.course_loaded.schedule_course_class !== null &&
        this.course_loaded.schedule_course_class !== undefined
      ) {
        window.open(this.$storage + this.course_loaded.schedule_course_class)
      }
    },
    openHelp () {
      if (
        this.course_loaded.start_meeting.how_access_pdf !== null &&
        this.course_loaded.start_meeting.how_access_pdf !== undefined
      ) {
        window.open(
          this.$storage + this.course_loaded.start_meeting.how_access_pdf
        )
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>
