var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.show)?_c('div',[_c('v-container',[_c('v-row',[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{attrs:{"cols":"12"}},[(
              _vm.course_loaded.course_type === 1 &&
              _vm.course_loaded.has_course_class === 1
            )?_c('StartMeeting'):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.changeDisciplineLoaded)?_c('v-card',[_c('v-card-text',[[_c('v-card-title',{staticClass:"d-flex justify-center",staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(_vm.discipline.name))]),_c('v-container',[_c('v-row',[(Object.keys(_vm.content.books).length > 0)?_c('v-col',{class:_vm.$vuetify.breakpoint.xsOnly
                          ? 'd-flex justify-center'
                          : '',attrs:{"cols":"12","md":"3","sm":"4"}},[_c('Books',{attrs:{"discipline":_vm.discipline,"course_loaded":_vm.course_loaded,"books":_vm.content.books},on:{"updateDiscipline":function($event){return _vm.updateDiscipline()}}})],1):_vm._e(),(Object.keys(_vm.content.summaries).length > 0)?_c('v-col',{class:_vm.$vuetify.breakpoint.xsOnly
                          ? 'd-flex justify-center'
                          : '',attrs:{"cols":"12","md":"3","sm":"4"}},[_c('Summaries',{attrs:{"discipline":_vm.discipline,"course_loaded":_vm.course_loaded,"summaries":_vm.content.summaries},on:{"updateDiscipline":function($event){return _vm.updateDiscipline()}}})],1):_vm._e(),(Object.keys(_vm.content.videoLesson).length > 0)?_c('v-col',{class:_vm.$vuetify.breakpoint.xsOnly
                          ? 'd-flex justify-center'
                          : '',attrs:{"cols":"12","md":"3","sm":"4"}},[_c('VideoLessons',{attrs:{"discipline":_vm.discipline,"course_loaded":_vm.course_loaded,"videoLessons":_vm.content.videoLesson},on:{"updateDiscipline":function($event){return _vm.updateDiscipline()}}})],1):_vm._e(),(Object.keys(_vm.mock_exam).length > 0)?_c('v-col',{class:_vm.$vuetify.breakpoint.xsOnly
                          ? 'd-flex justify-center'
                          : '',attrs:{"cols":"12","md":"3","sm":"4"}},[_c('Exam',{attrs:{"discipline":_vm.discipline,"course_loaded":_vm.course_loaded,"exams":_vm.mock_exam,"mock":true},on:{"open-exam":function($event){return _vm.openExam($event)},"updateDiscipline":function($event){return _vm.updateDiscipline()}}})],1):_vm._e(),(Object.keys(_vm.exam).length > 0)?_c('v-col',{class:_vm.$vuetify.breakpoint.xsOnly
                          ? 'd-flex justify-center'
                          : '',attrs:{"cols":"12","md":"3","sm":"4"}},[_c('Exam',{attrs:{"discipline":_vm.discipline,"course_loaded":_vm.course_loaded,"exams":_vm.exam,"mock":false},on:{"open-exam":function($event){return _vm.openExam($event)},"updateDiscipline":function($event){return _vm.updateDiscipline()}}})],1):_vm._e(),(Object.keys(_vm.content.curiosity).length > 0)?_c('v-col',{class:_vm.$vuetify.breakpoint.xsOnly
                          ? 'd-flex justify-center'
                          : '',attrs:{"cols":"12","md":"3","sm":"4"}},[_c('Curiosities',{attrs:{"course_loaded":_vm.course_loaded,"discipline":_vm.discipline,"curiosities":_vm.content.curiosity},on:{"updateDiscipline":function($event){return _vm.updateDiscipline()}}})],1):_vm._e(),(
                        Object.keys(_vm.content.complementaryMaterial).length > 0
                      )?_c('v-col',{class:_vm.$vuetify.breakpoint.xsOnly
                          ? 'd-flex justify-center'
                          : '',attrs:{"cols":"12","md":"3","sm":"4"}},[_c('ComplementaryMaterial',{attrs:{"course_loaded":_vm.course_loaded,"discipline":_vm.discipline,"complementaryMaterial":_vm.content.complementaryMaterial},on:{"updateDiscipline":function($event){return _vm.updateDiscipline()}}})],1):_vm._e(),(
                        _vm.course_loaded.course_type === 1 &&
                        _vm.course_loaded.course_id === 1
                      )?_c('FaqChannel',{class:_vm.$vuetify.breakpoint.xsOnly
                          ? 'd-flex justify-center'
                          : '',attrs:{"course_loaded":_vm.course_loaded,"discipline":_vm.discipline}}):_vm._e(),(
                        _vm.course_loaded.course_type === 1 &&
                        _vm.course_loaded.course_id === 1
                      )?_c('v-col',{class:_vm.$vuetify.breakpoint.xsOnly
                          ? 'd-flex justify-center'
                          : '',attrs:{"cols":"12","md":"3","sm":"4"}},[_c('a',{attrs:{"href":"skype:suporteceabonline?call"}},[_c('v-img',{attrs:{"width":"200px","src":require("@/app/assets/skype.png")}})],1)]):_vm._e()],1)],1)]],2)],1):_vm._e(),_c('banner-magazine',{staticClass:"d-flex justify-center"})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[((_vm.course_loaded.name).search('(Prisma Club)') === -1)?_c('CourseTimerEnd',{staticClass:"mb-5"}):_vm._e(),(
              _vm.course_loaded.course_type === 1 &&
              _vm.course_loaded.has_course_class === 1 &&
              !_vm.$vuetify.breakpoint.smAndDown
            )?_c('StartMeeting'):_vm._e(),(
              (_vm.course_loaded.course_type === 1 &&
                _vm.registration.liberated_certified === 1 &&
                _vm.registration.approved &&
                _vm.course_loaded.has_course_class === 1 &&
                _vm.course_loaded.course_id !== 1 &&
                _vm.course_loaded.course_id !== 19 &&
                !_vm.$vuetify.breakpoint.smAndDown) ||
              (_vm.course_loaded.course_type === 1 &&
                _vm.registration.frequency === 100 &&
                _vm.registration.approved &&
                _vm.course_loaded.has_course_class === 0 &&
                _vm.course_loaded.course_id !== 1 &&
                _vm.course_loaded.course_id !== 19 &&
                !_vm.$vuetify.breakpoint.smAndDown)
            )?_c('Certified',{staticClass:"mb-5",attrs:{"registration":_vm.registration}}):_vm._e(),(
              _vm.course_loaded.course_type === 2 &&
              _vm.registration.frequency === 100 &&
              _vm.registration.approved &&
              _vm.course_loaded.certified === 1 &&
              !_vm.$vuetify.breakpoint.smAndDown
            )?_c('ComplementaryCourseCertified',{staticClass:"mb-5",attrs:{"registration":_vm.registration}}):_vm._e(),(_vm.courseResumeReset && !_vm.$vuetify.breakpoint.smAndDown)?_c('FinalExam',{staticClass:"mb-5",attrs:{"blocks":_vm.final_exams},on:{"updateDiscipline":function($event){return _vm.updateDiscipline()}}}):_vm._e(),(_vm.courseResumeReset && !_vm.$vuetify.breakpoint.smAndDown)?_c('course-resume',{staticClass:"mb-5",attrs:{"discipline":_vm.discipline},on:{"changeDiscipline":function($event){return _vm.changeDiscipline($event, true)}}}):_vm._e()],1)],1),(_vm.courseResumeReset && _vm.$vuetify.breakpoint.smAndDown)?_c('v-dialog',{model:{value:(_vm.courseResumeDialog),callback:function ($$v) {_vm.courseResumeDialog=$$v},expression:"courseResumeDialog"}},[_c('course-resume',{attrs:{"course_loaded":_vm.course_loaded,"discipline":_vm.discipline},on:{"changeDiscipline":function($event){return _vm.changeDiscipline($event, true)},"close":function($event){_vm.courseResumeDialog = false}}})],1):_vm._e(),(_vm.courseResumeReset && _vm.show && _vm.$vuetify.breakpoint.smAndDown)?_c('v-dialog',{model:{value:(_vm.finalExamDialog),callback:function ($$v) {_vm.finalExamDialog=$$v},expression:"finalExamDialog"}},[(_vm.courseResumeReset)?_c('FinalExam',{staticClass:"mb-5",attrs:{"blocks":_vm.final_exams},on:{"updateDiscipline":function($event){return _vm.updateDiscipline()},"close":function($event){_vm.finalExamDialog = false}}}):_vm._e()],1):_vm._e(),(_vm.courseResumeReset && _vm.show && _vm.$vuetify.breakpoint.smAndDown)?_c('v-dialog',{model:{value:(_vm.certifiedDialog),callback:function ($$v) {_vm.certifiedDialog=$$v},expression:"certifiedDialog"}},[_c('v-card',[_c('v-card-text',[(
                (_vm.course_loaded.course_type === 1 &&
                  _vm.registration.liberated_certified === 1 &&
                  _vm.registration.approved &&
                  _vm.course_loaded.has_course_class === 1 &&
                  _vm.course_loaded.course_id !== 1 &&
                  _vm.course_loaded.course_id !== 19) ||
                (_vm.course_loaded.course_type === 1 &&
                  _vm.registration.frequency === 100 &&
                  _vm.registration.approved &&
                  _vm.course_loaded.has_course_class === 0 &&
                  _vm.course_loaded.course_id !== 1 &&
                  _vm.course_loaded.course_id !== 19)
              )?_c('Certified',{staticClass:"mb-5",attrs:{"registration":_vm.registration},on:{"expiredCertificate":function($event){_vm.expiredCertificate = $event}}}):_vm._e(),(_vm.expiredCertificate)?_c('span',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"title"},[_vm._v("Seu certificado expirou!")])])],1)],1):_vm._e(),(
                _vm.course_loaded.course_type === 2 &&
                _vm.course_loaded.certified === 1
              )?_c('ComplementaryCourseCertified',{staticClass:"mb-5",attrs:{"registration":_vm.registration,"show":_vm.complementary_course_certified}}):_vm._e()],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown && _vm.show)?_c('v-bottom-navigation',{staticClass:"overflow-hidden",attrs:{"app":"","grow":"","fixed":"","dark":""},model:{value:(_vm.bottom_nav),callback:function ($$v) {_vm.bottom_nav=$$v},expression:"bottom_nav"}},[_c('v-btn',{on:{"click":function($event){$event.stopPropagation();_vm.courseResumeDialog = true}}},[_c('span',[_vm._v("Disciplinas")]),_c('v-icon',[_vm._v("mdi-view-list")])],1),(
            (_vm.course_loaded.course_type === 1 &&
              _vm.registration.liberated_certified === 1 &&
              _vm.registration.approved &&
              _vm.course_loaded.has_course_class === 1 &&
              _vm.course_loaded.course_id !== 1 &&
              _vm.course_loaded.course_id !== 19) ||
            (_vm.course_loaded.course_type === 1 &&
              _vm.registration.frequency === 100 &&
              _vm.registration.approved &&
              _vm.course_loaded.has_course_class === 0 &&
              _vm.course_loaded.course_id !== 1 &&
              _vm.course_loaded.course_id !== 19) ||
            (_vm.course_loaded.course_type === 2 &&
              _vm.registration.frequency === 100 &&
              _vm.registration.approved &&
              _vm.course_loaded.certified === 1)
          )?_c('v-btn',{on:{"click":function($event){$event.stopPropagation();_vm.certifiedDialog = true}}},[_c('span',[_vm._v("Certificado")]),_c('v-icon',[_vm._v("mdi-certificate")])],1):_vm._e(),(_vm.final_exams.length > 0)?_c('v-btn',{on:{"click":function($event){$event.stopPropagation();_vm.finalExamDialog = true}}},[_c('span',[_vm._v("Provas Finais")]),_c('v-icon',[_vm._v("mdi-battlenet")])],1):_vm._e()],1):_vm._e()],1),(_vm.openedExam_container)?_c('v-container',{attrs:{"fluid":"","id":"overlay-exam"}}):_vm._e(),(_vm.openedExam_container)?_c('v-container',{attrs:{"fluid":"","id":"exam"}},[_c('ExamLoaded',{attrs:{"id":"openedExam","discipline":_vm.discipline,"openedExam":_vm.openedExam_content,"course_id":_vm.$router.currentRoute.params.course_id,"registration_id":_vm.$router.currentRoute.params.registration_id},on:{"close-update":function($event){return _vm.closeExamUpdate()},"close":function($event){return _vm.closeExam()},"update":function($event){return _vm.updateDiscipline()}}})],1):_vm._e(),_c('ExamError',{attrs:{"registration":_vm.registration}})],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.course_ended),callback:function ($$v) {_vm.course_ended=$$v},expression:"course_ended"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_vm._v("Curso encerrado!")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push('/cursos')}}},[_vm._v("Voltar a página de cursos")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }