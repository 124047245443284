import Vue from 'vue'
import $store from '../store'

console.log($store)

Vue.use({
  install (Vue) {
    Vue.prototype.$user = {
      userData: () => $store.state.user.userData
    }
  }
})
