<template>
  <v-text-field
    v-bind="bind"
    @input="input"
    :rules="[rules.required, rules.email, rules.length(10)]"
    :color="color"
    :label="label"
    required
    :disabled="disabled"
    @keyup.enter="$emit('enter', true)"
  ></v-text-field>
</template>

<script>
import TextFieldDefaultMixin from './Mixins/textfield-default-mixin'

export default {
  // props: ['value', 'label', 'color', 'disabled'],
  props: {
    value: { type: String, default: '' },
    label: { type: String, default: '' },
    disabled: { type: Boolean },
    color: { type: String, default: 'primary' }
  },
  mixins: [TextFieldDefaultMixin],
  data: () => ({
    rules: {
      email: v => /.+@.+\..+/.test(v) || 'Por favor, coloque um e-mail válido!',
      length: len => v => (v || '').length >= len || `Mínimo ${len} caracteres`,
      required: v => !!v || 'Este campo é obrigatório!'
    }
  })
}
</script>
