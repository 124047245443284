<template>
  <v-dialog v-model="show" persistent width="300" style="z-index: 1000000000">
    <v-card color="primary" dark>
      <v-card-text>
        <br />
        <span>{{ text }}</span>
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('loading', ['show', 'text'])
  }
}
</script>
