import $store from '../store'

export default {
  install (Vue) {
    Vue.directive('can', {
      inserted (el, binding, vnode) {
        const remove = (vnode) => vnode.elm.parentElement.removeChild(vnode.elm)

        const permission = $store.state.user.permissions
        if (permission === []) {
          remove(vnode)
          return
        }

        if (binding.value === 'null') {
          return
        }

        if (permission.includes(binding.value)) {
          return
        }

        remove(vnode)
      }
    })
  }
}
