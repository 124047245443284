<template>
  <div>
    <v-card>
      <v-card-text>
        <v-btn
          :elevation="1"
          style="
            z-index: 10000000;
            position: fixed;
            top: 75px;
            right: 10px;
            background-color: rgba(255, 255, 255, 0.7);
            color: rgba(0, 0, 0, 0.7);
          "
        >
          <Ping />
        </v-btn>

        <ExamToolbar
          :openedExam="openedExam"
          @close="close()"
          @close-exam="closeExam()"
        />

        <ExamHeader :openedExam="openedExam" />

        <ExamNote
          v-if="
            !started &&
            openedExam.content_already_finished.content_already_finished &&
            result === null
          "
          :openedExam="openedExam"
        />

        <ExamDescription
          v-if="!started && result === null"
          :openedExam="openedExam"
        />

        <ExamQuestionary
          v-if="started && result === null"
          :exam="openedExam"
          @endExam="endExam()"
        />

        <ExamResult v-if="!started && result !== null" />

        <ExamActions :openedExam="openedExam" />

        <ErrorInExam />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Ping from '@/shared/components/Ping'
import ExamToolbar from './ExamToolbar'
import ExamHeader from './ExamHeader'
import ExamNote from './ExamNote'
import ExamDescription from './ExamDescription'
import ExamQuestionary from './ExamQuestionary'
import ExamResult from './ExamResult'
import ExamActions from './ExamActions'
import ErrorInExam from './ErrorInExam'

export default {
  components: {
    Ping,
    ExamToolbar,
    ExamHeader,
    ExamNote,
    ExamDescription,
    ExamQuestionary,
    ExamResult,
    ExamActions,
    ErrorInExam
  },
  props: ['openedExam', 'discipline'],
  data: () => ({
    exit_dialog: false
  }),
  computed: {
    ...mapState('Exam', [
      'started',
      'attempt_id',
      'result',
      'time',
      'resultLoaded'
    ])
  },
  methods: {
    ...mapActions('Exam', ['end', 'endTime', 'clearResult', 'cancelExam']),
    close () {
      if (this.result !== null) {
        this.clearResult()
        this.$emit('close-update')
        return
      }
      this.$emit('close')
    },
    closeExam () {
      this.cancelExam()
      this.endTime()
      this.clearResult()
      this.$emit('close-update')
    },
    endExam () {
      this.$loading.load('Carregando resultados da prova...')
      this.end({
        registration_id: this.$router.currentRoute.params.registration_id,
        opened_exam_id: this.openedExam.id
      })
      this.endTime()
    }
  }
}
</script>
