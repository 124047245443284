export default () => {
  var options = {
    whatsapp: '+5511955901600', // Número do WhatsApp
    company_logo_url:
      'https://ceabbrasil.com.br/wp-content/uploads/2020/06/logo_ceab_color-110x50.png', // URL com o logo da empresa
    greeting_message: 'Olá! estou com dúvida.', // Texto principal
    call_to_action: 'Posso te ajudar? ', // Chamada para ação
    position: 'right' // Posição do widget na página 'right' ou 'left'
  }
  var proto = document.location.protocol
  var host = 'whatshelp.io'
  var url = proto + '//static.' + host
  var s = document.createElement('script')
  s.type = 'text/javascript'
  s.async = true
  s.src = url + '/widget-send-button/js/init.js'
  s.onload = function () {
    // eslint-disable-next-line no-undef
    WhWidgetSendButton.init(host, proto, options)
  }
  var x = document.getElementsByTagName('script')[0]
  x.parentNode.insertBefore(s, x)
}
