<template>
  <v-row>
    <v-col cols="12">
      <v-divider></v-divider>
      <span class="d-flex justify-center headline mt-8">INFORMAÇÕES PARA CASOS DE EMERGÊNCIAS</span>
    </v-col>

    <v-col cols="12" md="6" sm="12" style="border: 1px solid #000;">
      <v-select
        v-model="form_data.blood_type"
        :items="blood_types_list"
        label="TIPO SANGUÍNEO/FATOR RH"
      />
    </v-col>
    <v-col cols="12" md="6" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.allergic" label="É ALERGICO(A) A ALGUM TIPO DE MEDICAMENTO" />
    </v-col>

    <v-col cols="12" style="border: 1px solid #000;">EM CASO DE ACIDENTE, AVISAR A:</v-col>

    <v-col cols="12" md="8" sm="12" style="border: 1px solid red;">
      <v-text-field v-model="form_data.name">
        <template v-slot:label>
          NOME
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
      <v-text-field v-model="form_data.degree_of_kinship">
        <template v-slot:label>
          GRAU DE PARENTESCO
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="12" md="8" sm="12" style="border: 1px solid red;">
      <v-text-field v-model="form_data.address">
        <template v-slot:label>
          ENDEREÇO
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
        <Phone v-model="form_data.phone" color="blue" :label="
        `Telefone <span style='color: red; font-size: 24px'>*</span>`
        " />
    </v-col>

    <br />
    <br />

    <v-col cols="12" style="border: 1px solid #000;">
      <v-container>
        <span>
          <b>OUTRAS INFORMAÇÕES</b>
        </span>
        <v-textarea v-model="form_data.extra_info">//</v-textarea>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Phone from '@/shared/components/text-fields/Phone'

export default {
  components: { Phone },
  data: () => ({
    blood_types_list: [
      'Não sei',
      'A+',
      'A-',
      'B+',
      'B-',
      'AB+',
      'AB-',
      'O+',
      'O-'
    ],
    form_data: {
      blood_type: '',
      allergic: '',
      name: '',
      degree_of_kinship: '',
      address: '',
      phone: '',
      extra_info: ''
    }
  }),
  watch: {
    'form_data.blood_type' (val) {
      this.$emit('blood_type', val)
    },
    'form_data.allergic' (val) {
      this.$emit('allergic', val)
    },
    'form_data.name' (val) {
      this.$emit('name', val)
    },
    'form_data.degree_of_kinship' (val) {
      this.$emit('degree_of_kinship', val)
    },
    'form_data.address' (val) {
      this.$emit('address', val)
    },
    'form_data.phone' (val) {
      this.$emit('phone', val)
    },
    'form_data.extra_info' (val) {
      this.$emit('extra_info', val)
    }
  }
}
</script>
