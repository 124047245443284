<template>
  <div>
    <v-menu :close-on-content-click="false" :nudge-width="200" :nudge-right="10" offset-x>
      <template v-slot:activator="{ on }">
        <v-img v-on="on" width="200px" src="@/app/assets/simulado.png" v-if="mock"></v-img>
        <v-img v-on="on" width="200px" src="@/app/assets/prova.png" v-else></v-img>
      </template>

      <v-card>
        <v-card-title rounded class="body-1 d-flex justify-center">Provas</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item-group>
              <v-list-item v-for="(item, index) in exams" :key="index" @click="$emit('open-exam', item)">
                <v-list-item-icon>
                  <span v-if="item.content_already_finished.content_already_finished">
                    <img src="@/shared/assets/success.png" width="15px" />
                  </span>
                  <v-icon v-else>mdi-table-of-contents</v-icon>
                </v-list-item-icon>
                <v-list-item-content color="red">
                  <span class="body-2">{{ item.name }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ['exams', 'mock', 'discipline']
}
</script>
