export default {
  data: () => ({
    form_data: {
      language1: '',
      language1_reading: '',
      language1_writing: '',
      language1_speech: '',
      language1_understanding: '',
      language2: '',
      language2_reading: '',
      language2_writing: '',
      language2_speech: '',
      language2_understanding: ''
    }
  }),
  watch: {
    'form_data.language1' (val) {
      this.$emit('language1', val)
    },
    'form_data.language1_reading' (val) {
      this.$emit('language1_reading', val)
    },
    'form_data.language1_writing' (val) {
      this.$emit('language1_writing', val)
    },
    'form_data.language1_speech' (val) {
      this.$emit('language1_speech', val)
    },
    'form_data.language1_understanding' (val) {
      this.$emit('language1_understanding', val)
    },
    'form_data.language2' (val) {
      this.$emit('language2', val)
    },
    'form_data.language2_reading' (val) {
      this.$emit('language2_reading', val)
    },
    'form_data.language2_writing' (val) {
      this.$emit('language2_writing', val)
    },
    'form_data.language2_speech' (val) {
      this.$emit('language2_speech', val)
    },
    'form_data.language2_understanding' (val) {
      this.$emit('language2_understanding', val)
    }
  }
}
