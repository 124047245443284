var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.faq_channel_questions.length > 0 && _vm.show)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',[_c('v-img',{attrs:{"width":"200px","src":require("@/app/assets/forum.png")},on:{"click":function($event){$event.stopPropagation();return _vm.openFAQ()}}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.faq_dialog),callback:function ($$v) {_vm.faq_dialog=$$v},expression:"faq_dialog"}},[(_vm.faq_dialog)?_c('v-card',[_c('v-card-title',{staticClass:"ml-5"},[_c('v-spacer'),_c('span',[_vm._v("Fórum")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","small":"","elevation":"1"},on:{"click":function($event){return _vm.closeFaq()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-tabs',{staticClass:"d-flex justify-center",attrs:{"background-color":"white","color":"blue","center":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.faq_channel),function(item,index){return _c('span',{key:index},[_c('v-tab',{style:(item.channel_name === 'Aula ao Vivo'
                    ? 'height: 50px; color: red'
                    : 'height: 50px')},[_vm._v(_vm._s(item.channel_name))])],1)}),0),_c('v-divider'),(
              _vm.faq_channel_questions[_vm.tab] !== null &&
              _vm.faq_channel_questions[_vm.tab] !== undefined &&
              _vm.faq_channel_questions[_vm.tab].my_questions !== null
            )?_c('span',{staticClass:"body-1 d-flex justify-center font-weight-bold mt-5"},[_vm._v("Minhas Perguntas")]):_vm._e(),(
              _vm.faq_channel_questions[_vm.tab] !== null &&
              _vm.faq_channel_questions[_vm.tab] !== undefined &&
              _vm.faq_channel_questions[_vm.tab].my_questions !== null
            )?_c('div',{staticClass:"mt-5"},_vm._l((_vm.faq_channel_questions[_vm.tab].my_questions),function(item,index){return _c('v-card',{key:index,staticClass:"mb-2"},[_c('v-card-title',{staticClass:"body-1",staticStyle:{"cursor":"pointer"},domProps:{"innerHTML":_vm._s(item.question)},on:{"click":function($event){$event.stopPropagation();return _vm.openQuestion(item)}}}),_c('v-divider'),(_vm.question_show[item.id])?_c('div',[(item.answer !== null)?_c('v-card-text',{domProps:{"innerHTML":_vm._s('&nbsp;&nbsp;&nbsp;&nbsp;' + item.answer)}}):_c('v-card-text',{staticClass:"caption text-center"},[_vm._v(" Ops! Parece que você ainda está sem resposta :( ")])],1):_vm._e()],1)}),1):_vm._e(),_c('v-divider'),(
              _vm.faq_channel_questions[_vm.tab] !== null &&
              _vm.faq_channel_questions[_vm.tab] !== undefined &&
              _vm.faq_channel_questions[_vm.tab].questions !== null
            )?_c('span',{staticClass:"body-1 d-flex justify-center font-weight-bold mt-5"},[_vm._v("Perguntas da Disciplina")]):_vm._e(),(
              _vm.faq_channel_questions[_vm.tab] !== null &&
              _vm.faq_channel_questions[_vm.tab] !== undefined
            )?_c('div',{staticClass:"mt-5",attrs:{"multiple":""}},_vm._l((_vm.faq_channel_questions[_vm.tab].questions),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"body-1",domProps:{"innerHTML":_vm._s(item.question)}}),(item.answer !== null)?_c('div',[_c('v-container',[_vm._v(" "+_vm._s(item.answer)+" ")])],1):_c('div',{staticClass:"caption text-center"},[_vm._v(" Ops! Parece que você ainda está sem resposta :( ")])])}),0):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-5"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.create_question = true}}},[_vm._v("Quero fazer uma pergunta!")])],1),(_vm.create_question)?_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.create_question),callback:function ($$v) {_vm.create_question=$$v},expression:"create_question"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('span',[_vm._v("Fórum")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","small":"","elevation":"1"},on:{"click":function($event){_vm.create_question = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-select',{attrs:{"disabled":"","label":"Canal da Pergunta","items":_vm.faq_channel,"item-value":"id","item-text":"channel_name"},model:{value:(_vm.channel_id),callback:function ($$v) {_vm.channel_id=$$v},expression:"channel_id"}}),_c('v-textarea',{attrs:{"label":"Digite sua pergunta aqui!"},model:{value:(_vm.question),callback:function ($$v) {_vm.question=$$v},expression:"question"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.sendQuestion()}}},[_vm._v("Enviar")])],1)],1)],1):_vm._e()],1)],1):_vm._e()],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }