<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container style="margin-top: 50px;" v-if="!loading">
      <v-card>
        <v-card-title>Documentação: Comissário de Voo</v-card-title>

        <v-card-text>
          <v-alert type="info">Você deve abrir todas as apostilas para continuar.</v-alert>
          <v-row class="d-flex justify-center">
            <v-col cols="12" md="3" sm="4" v-for="(item, index) in documentations" :key="index">
              <v-card color="#f4f4f4" max-width="300">
                <v-icon size="100" class="d-flex justify-center pt-5">mdi-file-pdf-box-outline</v-icon>

                <v-card-title class="d-flex justify-center pb-2">{{ item.title }}</v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click.stop="openLink(item)" text>Abrir apostila</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <v-progress-linear
                style="border-radius: 25px; width: 80%"
                :value="value"
                height="20px"
                color="success"
                striped
              >
                <template v-slot="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <v-btn :disabled="value < 100" color="primary" @click.stop="finish()">Avançar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text></v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: true,
    value: 50,
    documentations: [],
    progress: null
  }),
  methods: {
    getDocumentation () {
      this.loading = true
      const url = '/student/documentation/findByCourseId/'
      const courseId = this.$router.currentRoute.params.course_id

      this.$api.get(url + courseId).then(res => {
        this.documentations = res.data.filter(item => item.file !== null)
      }).finally(() => {
        this.loading = false
      })
    },
    getProgress () {
      this.loading = true
      this.$api
        .get(
          `/student/documentation_doc/get_progress/${this.$router.currentRoute.params.course_id}/${this.$router.currentRoute.params.registration_id}`
        )
        .then(res => {
          this.value = res.data.percentage
          if (this.value === 100) {
            this.finish()
          }
        }).finally(() => {
          this.loading = false
        })
    },
    openLink (item) {
      this.loading = true
      this.$api
        .post('/student/documentation_doc/register', {
          documentation_id: item.id,
          registration_id: this.$router.currentRoute.params.registration_id
        })
        .then(() => {
          window.open(this.$storage + item.file)

          this.getDocumentation()
          this.getProgress()
        }).finally(() => {
          this.loading = false
        })
    },
    finish () {
      this.loading = true
      return this.$router.push({
        name: 'Documentação',
        params: {
          course_id: this.$router.currentRoute.params.course_id,
          registration_id: this.$router.currentRoute.params.registration_id
        }
      }).catch(() => {})
    }
  },
  beforeMount () {
    this.getDocumentation()
    this.getProgress()
  }
}
</script>
