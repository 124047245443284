<template>
  <v-row>
    <v-col cols="12">
      <v-divider></v-divider>
      <span class="d-flex justify-center headline mt-8">CURSOS DE APERFEIÇOAMENTO</span>
    </v-col>

    <v-col cols="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.course1_name" label="CURSO DE" />
    </v-col>

    <v-col cols="12" md="6" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.course1_entity" label="ENTIDADE" />
    </v-col>

    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-select v-model="form_data.course1_uf" :items="states" label="UF"></v-select>
    </v-col>

    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <Date v-model="form_data.course1_of_period" label="PERÍODO (Início)" />
    </v-col>
    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <Date v-model="form_data.course1_to_period" label="PERÍODO (Fim)" />
    </v-col>

    <v-col cols="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.course2_name" label="CURSO DE" />
    </v-col>

    <v-col cols="12" md="6" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.course2_entity" label="ENTIDADE" />
    </v-col>

    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <v-select v-model="form_data.course2_uf" :items="states" label="UF"></v-select>
    </v-col>

    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <Date v-model="form_data.course2_of_period" label="PERÍODO (Início)" />
    </v-col>
    <v-col cols="12" md="2" sm="12" style="border: 1px solid #000;">
      <Date v-model="form_data.course2_to_period" label="PERÍODO (Fim)" />
    </v-col>
  </v-row>
</template>

<script>
import MatriculationImprovementCoursesMixin from '@/app/components/Course/mixins/MatriculationImprovementCourses'
import Date from '@/shared/components/text-fields/Date'

export default {
  components: { Date },
  mixins: [MatriculationImprovementCoursesMixin],
  data: () => ({
    states: [
      'AC',
      'AL',
      'AP',
      'AM',
      'BA',
      'CE',
      'DF',
      'ES',
      'GO',
      'MA',
      'MT',
      'MS',
      'MG',
      'PR',
      'PB',
      'PA',
      'PE',
      'PI',
      'RN',
      'RS',
      'RJ',
      'RO',
      'RR',
      'SC',
      'SE',
      'SP',
      'TO'
    ]
  })
}
</script>
