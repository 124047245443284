export default {
  namespaced: true,
  state: {
    main_courses: [],
    course_loaded: [],
    course_terms: [],
    accepted_terms: false,
    course_terms_dialog: false,
    course_mode: {
      course_class: null,
      ead_course: 1,
      installment: false,
      installment_number: 1,
      subscription: false,
      cost: 0
    },
    cupom: null
  },
  getters: {
    paymentInfo (state) {
      return state.course_loaded.has_course_class === 1
        ? state.course_mode.course_class !== null
          ? Object.values(state.course_loaded.course_class).find(
            (item) => item.id === state.course_mode.course_class
          )
          : null
        : state.course_loaded.course_ead
    },
    onlySubscription (state, getters) {
      return getters.paymentInfo !== null &&
        getters.paymentInfo.installment === 0 &&
        getters.paymentInfo.cost_credits === 0 &&
        getters.paymentInfo.subscription === 1
    },
    cost (state, getters) {
      if (getters.onlySubscription) {
        return getters.paymentInfo.cost_subscription
      }

      if (!getters.onlySubscription && state.course_mode.installment) {
        return state.course_loaded.has_course_class === 1
          ? state.course_mode.course_class !== null ? getters.paymentInfo.installment_cost : 0
          : getters.paymentInfo.installment_cost
      }

      if (!getters.onlySubscription && !state.course_mode.installment) {
        return state.course_loaded.has_course_class === 1
          ? state.course_mode.course_class !== null ? getters.paymentInfo.cost_credits : 0
          : getters.paymentInfo.cost_credits
      }
    },
    discount (state, getters) {
      return state.cupom !== null
        ? getters.cost > 0
          ? state.cupom[0].value
          : 0
        : 0
    },
    entryPayment (state, getters) {
      return state.course_mode.installment === true
        ? (getters.paymentInfo.installment_cost - getters.discount) /
            (state.course_mode.installment_number + 1)
        : null
    }
  },
  mutations: {
    async loadCourseStore (state) {
      var selfState = state
      let url = '/student/store/main_courses'
      await this._vm.$api.get(url)
        .then(res => {
          selfState.main_courses = res.data
        })
    },
    selectCourse (state, payload) {
      state.course_loaded = payload
      state.accepted_terms = false
      state.course_terms = []
      state.course_terms_dialog = false
      state.cupom = null
      state.course_mode = {
        cost: 0,
        course_class: null,
        ead_course: 1,
        installment: false,
        installment_number: 1,
        subscription: false
      }
    },
    loadCourseTerms (state) {
      var selfState = state
      this._vm.$api.get('/student/store/course_terms/findByCourseId/' + state.course_loaded.id)
        .then(res => {
          selfState.course_terms = res.data
        }).finally(() => {
          if (selfState.course_terms.length === 0) {
            selfState.accepted_terms = true
          }
        })
    },
    setCourseClass (state, payload) {
      state.course_mode.course_class = payload
    },
    setCupom (state, payload) {
      state.cupom = payload
    },
    setInstallment (state, payload) {
      state.course_mode.installment = !!payload
      state.course_mode.installment_number = 2
    },
    setInstallmentNumber (state, payload) {
      state.course_mode.installment_number = payload
    },
    dialogCourseTerms (state, payload) {
      state.accepted_terms = false
      if (state.course_terms.length > 0) {
        state.course_terms_dialog = payload
      } else {
        state.course_terms_dialog = false
        state.accepted_terms = true
      }
    },
    setAcceptedTerms (state, payload) {
      state.accepted_terms = payload
    }
  },
  actions: {
    loadCourseStore ({ commit }) {
      commit('loadCourseStore')
    },
    async selectCourse ({ commit }, payload) {
      await commit('selectCourse', payload)
    },
    loadCourseTerms ({ commit }) {
      commit('loadCourseTerms')
    },
    setCourseClass ({ commit }, payload) {
      commit('setCourseClass', payload)
    },
    setCupom ({ commit }, payload) {
      commit('setCupom', payload)
    },
    setInstallment ({ commit }, payload) {
      commit('setInstallment', payload)
    },
    setInstallmentNumber ({ commit }, payload) {
      commit('setInstallmentNumber', payload)
    },
    dialogCourseTerms ({ commit }, payload) {
      commit('dialogCourseTerms', payload)
    },
    setAcceptedTerms ({ commit }, payload) {
      commit('setAcceptedTerms', payload)
    }
  }
}
