export default {
  data: () => ({
    form_data: {
      address: '',
      cep: '',
      city: '',
      uf: '',
      phone: '',
      birthday: '',
      marital_status: '',
      nationality: '',
      mothers_name: '',
      fathers_name: '',
      work_company: '',
      work_role: '',
      work_company_address: '',
      work_company_cep: '',
      work_company_city: '',
      work_company_uf: '',
      work_company_phone: '',
      work_company_fax: '',
      work_company_email: ''
    }
  }),
  watch: {
    'form_data.address' (val) {
      this.$emit('address', val)
    },
    'form_data.cep' (val) {
      this.$emit('cep', val)
    },
    'form_data.city' (val) {
      this.$emit('city', val)
    },
    'form_data.uf' (val) {
      this.$emit('uf', val)
    },
    'form_data.phone' (val) {
      this.$emit('phone', val)
    },
    'form_data.birthday' (val) {
      this.$emit('birthday', val)
    },
    'form_data.marital_status' (val) {
      this.$emit('marital_status', val)
    },
    'form_data.nationality' (val) {
      this.$emit('nationality', val)
    },
    'form_data.mothers_name' (val) {
      this.$emit('mothers_name', val)
    },
    'form_data.fathers_name' (val) {
      this.$emit('fathers_name', val)
    },
    'form_data.work_company' (val) {
      this.$emit('work_company', val)
    },
    'form_data.work_role' (val) {
      this.$emit('work_role', val)
    },
    'form_data.work_company_address' (val) {
      this.$emit('work_company_address', val)
    },
    'form_data.work_company_cep' (val) {
      this.$emit('work_company_cep', val)
    },
    'form_data.work_company_city' (val) {
      this.$emit('work_company_city', val)
    },
    'form_data.work_company_uf' (val) {
      this.$emit('work_company_uf', val)
    },
    'form_data.work_company_phone' (val) {
      this.$emit('work_company_phone', val)
    },
    'form_data.work_company_fax' (val) {
      this.$emit('work_company_fax', val)
    },
    'form_data.work_company_email' (val) {
      this.$emit('work_company_email', val)
    }
  }
}
