import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/app/views/Home'
import Course from '@/app/views/Course'
import CourseStore from '@/app/views/CourseStore'
import PurchaseHistory from '@/app/views/PurchaseHistory'
import Discipline from '@/app/views/Discipline.vue'
import PrismaClub from '@/app/views/PrismaClub.vue'
import Documentation from '@/app/views/Documentation'
import DocumentationDocs from '@/app/views/DocumentationDocs'
import EditProfile from '@/app/views/EditProfile'

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem('_token')) {
    next()
    return
  }
  window.location.href = '/login'
}

const routes = [
  {
    name: 'Página Inicial',
    path: '/',
    component: Home,
    beforeEnter: ifAuthenticated
  },
  {
    name: 'Index',
    path: '/cursos',
    component: Course,
    beforeEnter: ifAuthenticated
  },
  {
    name: 'Loja de Cursos',
    path: '/loja',
    component: CourseStore,
    beforeEnter: ifAuthenticated
  },
  {
    name: 'Histórico de compras',
    path: '/compras/historico',
    component: PurchaseHistory,
    beforeEnter: ifAuthenticated
  },
  {
    name: 'Disciplinas',
    path: '/curso/:course_id/:registration_id',
    component: Discipline
  },
  {
    name: 'Prisma Club',
    path: '/prisma_club/:course_id/:registration_id',
    component: PrismaClub
  },
  {
    name: 'Apostilas da documentação',
    path: '/documentation_docs/:course_id/:registration_id',
    component: DocumentationDocs
  },
  {
    name: 'Documentação',
    path: '/documentation/:course_id/:registration_id',
    component: Documentation
  },
  {
    name: 'Editar Perfil',
    path: '/editar_perfil',
    component: EditProfile
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  duplicateNavigationPolicy: 'ignore'
})

export default router
