import ping from 'web-pingjs'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    ping: 0,
    accessInternet: true,
    interval: null
  },
  getters: {
    status: state => {
      var status = ''

      if (state.ping <= 300) {
        status = 'low'
      }

      if (state.ping > 300 && state.ping <= 800) {
        status = 'medium'
      }

      if (state.ping > 800) {
        status = 'high'
      }

      return status
    },
    color: (state, getters) => {
      var color = ''

      if (getters.status === 'low') {
        color = 'green darken-2'
      }

      if (getters.status === 'medium') {
        color = 'yellow darken-2'
      }

      if (getters.status === 'high') {
        color = 'red darken-2'
      }

      return color
    }
  },
  mutations: {
    startPing (state) {
      state.interval = setInterval(() => {
        axios.get('/favicon.png?d=' + Date.now()).then(() => {
          state.accessInternet = true
        }).catch(() => {
          state.accessInternet = false
        })

        ping('/favicon.png?d=' + Date.now())
          .then(delta => {
            if (navigator.onLine && state.accessInternet === true) {
              state.ping = delta
              this.accessInternet = true
            }
          })
      }, 3000)
    }
  },
  actions: {
    startPing ({ commit }) {
      commit('startPing')
    }
  }
}
