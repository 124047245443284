<template>
  <v-row>
    <v-col cols="12">
      <v-divider></v-divider>
      <span class="d-flex justify-center headline mt-8">NÍVEL DE INSTRUÇÃO</span>
    </v-col>

    <v-col cols="12" style="border: 1px solid red;">
      <v-select v-model="form_data.instruction_level" :items="instruction_level_items">
        <template v-slot:label>
          NÍVEL DE INSTRUÇÃO
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-select>
    </v-col>

    <v-col cols="12" md="6" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.period" label="PERÍODO (SE INCOMPLETO)" />
    </v-col>

    <v-col cols="12" md="6" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.course" label="CURSO" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    instruction_level_items: [
      'Fundamental Incompleto',
      'Fundamental Completo',
      'Médio Incompleto',
      'Médio Completo',
      'Superior Incompleto',
      'Superior Completo',
      'Mestrado',
      'Doutorado'
    ],
    form_data: {
      instruction_level: '',
      period: '',
      course: ''
    }
  }),
  watch: {
    'form_data.instruction_level' (val) {
      this.$emit('instruction_level', val)
    },
    'form_data.period' (val) {
      this.$emit('period', val)
    },
    'form_data.course' (val) {
      this.$emit('course', val)
    }
  }
}
</script>
