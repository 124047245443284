<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-center" v-for="(item, index) in blocks" :key="index">
        <v-btn color="red" @click="openExam(item)" dark>{{ item.title }}</v-btn>
      </v-col>
    </v-row>
      <Exam @updateDiscipline="updateDiscipline($event)" :exams="selected_exam" :dialog="dialog" />
  </div>
</template>

<script>
import Exam from './Exam'

export default {
  components: { Exam },
  props: ['blocks'],
  data: () => ({
    dialog: false,
    selected_exam: []
  }),
  methods: {
    openExam (item) {
      this.selected_exam = { ...item }
      this.dialog = true
    },
    updateDiscipline ($event) {
      this.$emit('updateDiscipline', $event)
    }
  }
}
</script>
