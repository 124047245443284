<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-center title">Assinatura Digital</v-col>
      <v-col cols="12" class="d-flex justify-center" v-if="!signature_finished">
        <v-btn @click="signature_dialog = true" color="primary" v-html="button"></v-btn>
      </v-col>

      <v-col cols="12" class="d-flex justify-center" v-if="signature_finished">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <span style="color: red;" class="display-1">
              <span class="mr-4" style="text-transform: uppercase;">{{ first_name }} {{ last_name }}</span>
            </span>
          </v-col>

          <v-col cols="12" class="d-flex justify-center">
            <hr width="500px" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog max-width="500px" v-model="signature_dialog" v-if="!signature_finished">
      <v-card>
        <v-card-title class="d-flex justify-center">Assinar ficha de matrícula</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="d-flex justify-center text-center">
              <span class="body-1">Você reconhece essas letras como as iniciais do seu nome?</span>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <div
                style="width: auto; height: 75px; background-color: #F6F193; text-align: center;"
              >
                <v-container>
                  <span style="color: red;" class="display-1">
                    <span class="mr-4" style="text-transform: uppercase;">{{ first_name }} {{ last_name }}</span>
                  </span>

                  <br />

                  <span class="text-center red--text">INICIAIS DO NOME</span>
                </v-container>
              </div>
            </v-col>

            <v-col cols="12" class="text-center">
              <span
                class="blue--text text--darken-1"
              >Reconhecendo as iniciais acima, você concorda, para todos efeitos legais, que está assinado digitalmente sua ficha de matrícula</span>
            </v-col>

            <br />
            <br />

            <v-col cols="12" class="text-center">
              <span>
                <b>Digite sua senha de acesso à sua área do aluno:</b>
              </span>
            </v-col>

            <v-col cols="12">
              <v-text-field label="Sua senha:" type="password" v-model="password"></v-text-field>
            </v-col>

            <v-col cols="12" v-if="errors === true">
              <p style="border: 1px solid red; text-align: center;" class="body-1 red--text text--lighten-1">Senha incorreta!</p>
            </v-col>

          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn style="padding: 18px" @click.stop="signature_dialog = false">NÃO RECONHEÇO</v-btn>
          <v-btn style="padding: 18px" color="primary" @click.stop="checkPassword()">RECONHEÇO</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['button'],
  data: () => ({
    signature_dialog: false,
    signature_finished: false,
    first_name: '',
    last_name: '',
    password: '',
    errors: false
  }),
  methods: {
    checkPassword () {
      this.$api
        .post('/student/user/check_password', {
          password: this.password
        })
        .then((res) => {
          if (res.data.access === true) {
            this.errors = false
            this.$emit('conclusion', 'true')
            this.signature_dialog = false
            this.signature_finished = true
          } else if (res.data.access === false) {
            this.errors = true
          }
        })
    }
  },
  beforeMount () {
    var userData = this.$store.state.user.userData
    this.first_name = userData.personal_data.first_name
      .split(' ')
      .map((nome) => {
        if (!nome.match(/(^d[a|e|o]s?$)/i)) return nome[0]
      })
      .join('')

    this.last_name = userData.personal_data.last_name
      .split(' ')
      .map((nome) => {
        if (!nome.match(/(^d[a|e|o]s?$)/i)) return nome[0]
      })
      .join('')
  }
}
</script>
