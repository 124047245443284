<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <banner-magazine class="d-flex justify-center" />
    <v-container v-if="!loading">
      <v-card>
        <div
          v-if="
            courses.main_courses.length === 0 &&
              courses.complementary_courses.length === 0
          "
        >
          <div class="pt-10">
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <img src="@/app/assets/logo.png" width="300px" />
              </v-col>
              <v-col cols="12" class="d-flex justify-center text-center">
                <span>
                  Ops! Parece que você ainda não adquiriu nenhum curso!
                  <br />
                  <v-btn
                    @click.stop="$router.push('/loja').catch(() => {})"
                    color="primary"
                    class="mt-4 mb-4"
                  >
                    <v-icon class="mr-4">mdi-store</v-icon>Ir para a loja!
                  </v-btn>
                  <br />Caso você já tenha adquirido um curso e o mesmo ainda
                  não está presente <br />por favor note que o procedimento pode
                  levar até 30 minutos no cartão de crédito e <br />no boleto,
                  podendo levar até 3 dias úteis para ser confirmado.
                </span>
              </v-col>
            </v-row>
          </div>
        </div>

        <v-tabs
          v-model="tab"
          background-color="white"
          color="blue"
          right
          show-arrows
        >
          <v-tab v-if="courses.main_courses.length > 0"
            >Cursos Principais</v-tab
          >
          <v-tab v-if="courses.complementary_courses.length > 0"
            >Cursos Complementares</v-tab
          >

          <v-tab-item v-if="courses.main_courses.length > 0">
            <v-container fluid>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  sm="4"
                  xs="12"
                  v-for="(item, index) in courses.main_courses"
                  :key="index"
                >
                  <a @click.stop="checkCourse(item)">
                    <v-img
                      :src="$storage + item.image"
                      aspect-ratio="1"
                    ></v-img>
                  </a>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item v-if="courses.complementary_courses.length > 0">
            <v-container fluid>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  sm="4"
                  xs="12"
                  v-for="(item, index) in courses.complementary_courses"
                  :key="index"
                >
                  <a @click.stop="checkCourse(item)">
                    <v-img
                      :src="$storage + item.image"
                      aspect-ratio="1"
                    ></v-img>
                  </a>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card>

      <v-dialog v-model="contract_dialog" :scrollable="true">
        <v-card style="overflow: hidden">
          <v-card-title class="d-flex justify-center">{{
            item_selected.title
          }}</v-card-title>
          <v-card-text v-html="item_selected.contract_text"></v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-checkbox
                  v-model="acceptBox"
                  label="Declaro que li e estou de acordo com os termos do contrato."
                ></v-checkbox>
              </v-col>

              <v-col cols="12" class="d-flex justify-center">
                <v-btn color="primary" @click.stop="acceptContract()"
                  >Confirmar</v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  text
                  color="primary"
                  @click.stop="contract_dialog = false"
                  >Fechar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="matriculation_dialog" :scrollable="true">
        <Matriculation
          @matriculation_finished="matriculation_finished($event)"
        />
      </v-dialog>

      <v-dialog
        v-model="commitment_terms_dialog"
        max-width="768px"
        :scrollable="true"
      >
        <CommitmentTerm
          @commitmentTerm_finished="commitmentTerm_finished($event)"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CheckCourse from '@/app/components/Course/mixins/CheckCourse'
import CheckContract from '@/app/components/Course/mixins/CheckContract'
import CheckMatriculation from '@/app/components/Course/mixins/CheckMatriculation'
import CheckCommitmentTerms from '@/app/components/Course/mixins/CheckCommitmentTerms'
import checkDocumentation from '@/app/components/Course/mixins/checkDocumentation'
import Matriculation from '@/app/components/Course/Matriculation'
import CommitmentTerm from '@/app/components/Course/CommitmentTerm'
import BannerMagazine from '@/app/components/BannerMagazine'

export default {
  components: {
    Matriculation,
    CommitmentTerm,
    BannerMagazine
  },
  mixins: [
    CheckCourse,
    CheckContract,
    CheckMatriculation,
    CheckCommitmentTerms,
    checkDocumentation
  ],
  data: () => ({
    tab: 0,
    loading: false,
    studentCoursesInterval: null,
    matriculation_dialog: false,
    commitment_terms_dialog: false,
    contract_dialog: false,
    acceptBox: false,
    contractAccepted: false,
    item_selected: []
  }),
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('course', ['courses', 'course_loaded'])
  },
  methods: {
    ...mapActions('course', ['loadCourses', 'selectCourse']),
    acceptContract() {
      this.loading = true
      if (!this.acceptBox) {
        return
      }

      const courseId =
        this.course_loaded.course_type === 1
          ? this.course_loaded.course_id
          : this.course_loaded.course_father.id

      this.$api
        .get(
          `/student/course_contract/check/${courseId}/${this.course_loaded.registration_id}`
        )
        .then(res => {
          if (!res.data.access) {
            this.$api.post('/student/course_contract', {
              ip: this.$ipModule.getIp(),
              course_contract_id: this.item_selected.id,
              registration_id: this.course_loaded.registration_id
            })
          }

          console.log('OLAAAAAA')

          if (this.course_loaded.name.includes('Prisma Club')) {
            this.$router
              .push({
                name: 'Prisma Club',
                params: {
                  course_id: this.course_loaded.course_id,
                  registration_id: this.course_loaded.registration_id
                }
              })
              .catch(() => {})
          }
        })

      this.contract_dialog = false

      setTimeout(() => {
        if (this.course_loaded.name === 'Prisma Club') {
          this.$router
            .push({
              name: 'Prisma Club',
              params: {
                course_id: this.course_loaded.course_id,
                registration_id: this.course_loaded.registration_id
              }
            })
            .catch(() => {})
          return
        }
        this.checkMatriculation()
      }, 100)

      this.loading = false
    },
    matriculation_finished(val) {
      this.matriculation_dialog = false
      this.checkCommitmentTerms()
    },
    commitmentTerm_finished(val) {
      this.checkDocumentation(
        this.course_loaded.course_id,
        this.course_loaded.registration_id
      )
    }
  },
  beforeMount() {
    this.loadCourses()
  },
  beforeDestroy() {
    this.$userHistories.end(`Página Cursos`)
  }
}
</script>

<style scoped>
.button-clicked {
  border-radius: 0px;
  border-bottom: 3px solid #1877f2;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
