<template>
  <v-card>
    <v-card-title>Termos</v-card-title>
    <v-card-text>
      <v-col cols="12">
        <div>
          <v-checkbox
            style="margin-top: -5px"
            v-for="(item, index) in course_terms" :key="index"
            v-model="checkbox[index]"
          >
          <template v-slot:label>
            <span class="body-2">{{ item.title }}</span>
          </template>
          </v-checkbox>
        </div>
      </v-col>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" width="150px" height="50px" @click.stop="check()">Prosseguir</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" height="50px" @click="openRegulamentation()">Ler Regulamentação do Curso</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    checkbox: []
  }),
  computed: {
    ...mapState('courseStore', ['course_loaded', 'course_terms'])
  },
  methods: {
    ...mapActions('courseStore', ['setAcceptedTerms', 'dialogCourseTerms']),
    check () {
      const acceptedTerms = this.checkbox.filter(item => item === true)
      if (acceptedTerms.length === this.course_terms.length) {
        this.dialogCourseTerms(false)
        this.setAcceptedTerms(true)
      } else {
        this.$snackbar.error(
          'Você deve concordar com todos os termos para poder continuar com a compra!'
        )
      }
    },
    openRegulamentation () {
      window.open(this.$storage + this.course_loaded.regulamentation)
    }
  }
}
</script>
