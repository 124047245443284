import moment from 'moment'

export default {
  data: () => ({
    loading: {
      discipline: false,
      disciplineContent: false,
      registration: false,
      course: false,
      finalExam: false,
      exams: false
    }
  }),
  watch: {
    loading () {
      this.loadingQueue()
    }
  },
  computed: {
    show () {
      return this.loading.discipline === true &&
        this.loading.disciplineContent === true &&
        this.loading.registration === true &&
        this.loading.course === true &&
        this.loading.finalExam === true &&
        this.loading.exams === true
    }
  },
  methods: {
    async loadingQueue () {
      if (this.loading.course === false) {
        await this.findCourse().finally(() => {
          if (moment().isAfter(this.course_loaded.end)) {
            this.course_ended = true
            this.$loading.loaded()
          }

          this.loading.course = true
        })
      }

      if (this.course_ended === true) {
        return
      }

      if (this.loading.course === true && this.loading.discipline === false) {
        await this.nextDiscipline().finally(() => {
          this.loading.discipline = true
        })
      }

      if (this.loading.discipline === true && this.loading.disciplineContent === false) {
        await this.disciplineContent(this.discipline.discipline_id).finally(() => {
          this.loading.disciplineContent = true
        })
      }

      if (this.loading.disciplineContent === true && this.loading.exams === false) {
        await this.courseDisciplineExams(this.discipline.discipline_id).finally(() => {
          this.loading.exams = true
        })
      }

      if (this.loading.discipline === true && this.loading.course === true) {
        this.$userHistories.setHistory({
          id: `Disciplina: ${this.discipline.id}`,
          text: `Abriu a Disciplina: "${this.discipline.name}" do Curso "${this.course_loaded.name}"`,
          tag: this.course_loaded.name
        })
        this.$userHistories.start(`Disciplina: ${this.discipline.id}`)
      }

      if (this.loading.course === true && this.loading.registration === false) {
        this.loadRegistration()
      }

      if (this.loading.course === true && this.loading.finalExam === false) {
        await this.getFinalExamBlocks().finally(() => {
          this.loading.finalExam = true
        })
      }
    },
    async loadRegistration () {
      await this.getRegistrationInfo().then(() => {
        this.loading.registration = true
      }).catch(() => {
        this.loadRegistration()
      })
    }
  },
  beforeMount () {
    this.loadingQueue()
  },
  beforeDestroy () {
    if (this.loading.discipline === true) {
      this.$userHistories.end(`Disciplina: ${this.discipline.id}`)
    }
  }
}
