<template>
  <v-dialog ref="dialog" v-model="modal" :return-value.sync="bind.value" persistent width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="dateFormatted"
        prepend-icon="mdi-calendar"
        readonly
        v-on="on"
      >
        <template v-slot:label>
          <span v-html="label"></span>
        </template>
      </v-text-field>
    </template>
    <v-date-picker v-bind="bind" @input="input" reactive scrollable locale="pt-br">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
      <v-btn text color="primary" @click="$refs.dialog.save(bind.value)">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import TextFieldDefaultMixin from './Mixins/textfield-default-mixin'

export default {
  props: ['value', 'label'],
  mixins: [TextFieldDefaultMixin],
  data: () => ({
    modal: false
  }),
  computed: {
    bind () {
      return {
        ...this.$attrs,
        ...this.$props,
        value: this.$options.$parseInput(this.$props.value)
      }
    },
    dateFormatted () {
      return this.formatDate(this.value)
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    }
  }
}
</script>
