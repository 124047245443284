// Pega o última Disciplina disponível ao usuário baseado em seu progresso
export const nextDiscipline = (api, courseId, registrationId) => {
  return api.get(`/student/discipline_progress/next_discipline/${courseId}/${registrationId}`)
}

// Pega os conteúdos da disciplina solicitado caso o usuário tenha acesso.
export const disciplineContent = (api, courseId, disciplineId, registrationId) => {
  return api.get(`/student/discipline_content/${courseId}/${disciplineId}/${registrationId}`)
}

// Pega todos as disciplinas do usuário com a informação do progresso de cada um.
export const courseDisciplineProgress = (api, courseId, registrationId) => {
  return api.get(`/student/discipline_progress/course/${courseId}/${registrationId}`)
}

// Pega as informações da disciplina com a posição do módulo especificado "1 / 2 / 3 ..."
export const disciplinePosition = (api, courseId, position, registrationId) => {
  return api.get(`/student/discipline_progress/discipline_position/${courseId}/${position}/${registrationId}`)
}

// Registra o progresso do conteúdo
export const RegisterProgress = (api, courseId, registrationId, disciplineId, disciplineContentId) => {
  if (disciplineContentId.videoLesson && disciplineContentId.videoLesson.video_local === 'StartMeeting') {
    return api.post('/student/discipline_progress/register_progress', {
      course_id: courseId,
      registration_id: registrationId,
      discipline_id: disciplineId,
      discipline_content_id: null,
      startmeeting_id: disciplineContentId.id,
      status: 1
    })
  } else {
    disciplineContentId = disciplineContentId.id
  }
  return api.post('/student/discipline_progress/register_progress', {
    course_id: courseId,
    registration_id: registrationId,
    discipline_id: disciplineId,
    discipline_content_id: disciplineContentId,
    status: 1
  })
}

// Filtra os conteúdos dos módulo para facilitar o entendimento separando por responsabilidades
// sendo books/apostilas do type 1, summaries/resumos do tipo 2 e videoLesson/video-aulas do tipo 3
export const filterDisciplineContent = (content) => {
  const books = content.filter(item => item.type_content === 1).map(item => {
    return {
      id: item.id,
      name: item.title,
      discipline_id: item.discipline_id,
      file: item.book.file,
      content_already_finished: item.content_already_finished
    }
  })

  const summaries = content.filter(item => item.type_content === 2).map(item => {
    return {
      id: item.id,
      name: item.title,
      discipline_id: item.discipline_id,
      summary: item.summary,
      content_already_finished: item.content_already_finished
    }
  })

  const videoLesson = content.filter(item => item.type_content === 3).map(item => {
    return {
      id: item.id,
      name: item.title,
      discipline_id: item.discipline_id,
      videoLesson: item.video_lesson,
      content_already_finished: item.content_already_finished
    }
  })

  const curiosity = content.filter(item => item.type_content === 4).map(item => {
    return {
      id: item.id,
      name: item.title,
      discipline_id: item.discipline_id,
      curiosity: item.curiosity,
      content_already_finished: item.content_already_finished
    }
  })

  const complementaryMaterial = content.filter(item => item.type_content === 5).map(item => {
    return {
      id: item.id,
      name: item.title,
      discipline_id: item.discipline_id,
      complementary_material: item.complementary_material,
      content_already_finished: item.content_already_finished
    }
  })

  return { books, summaries, videoLesson, curiosity, complementaryMaterial }
}

// Pega todas as provas do módulo
export const courseDisciplineExams = (api, courseId, disciplineId, registrationId) => {
  return api.get(`/student/exams/${courseId}/${disciplineId}/${registrationId}`)
}
