<template>
  <v-card>
    <v-card-title class="d-flex justify-center"
      >Tempo restante do curso</v-card-title
    >
    <v-card-text>
      <div class="d-flex justify-center">
        {{ timer | coutdown }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  data: () => ({
    timer: 0
  }),
  filters: {
    coutdown (val) {
      const timer = moment.duration(val).format('D:HH:mm').split(':')
      const firstText =
        // verifica se tem mais de um dia
        timer[0] >= 1
          ? `Falta${timer[0] > 1 ? 'm ' : ' '}` // se tiver, a frase muda para faltam, se não fica falta
          : timer[1] > 0 // se não tiver dias, verifica se tem horas
            ? timer[1] > 1
              ? 'faltam' // se tiver mais de 1 hora, retorna "faltam"
              : 'falta' // se tiver equivalente a 1 hora, retorna "falta"
            : timer[2] > 0 // se não tiver horas
              ? timer[2] > 1 // verifica se tem minutos
                ? 'faltam' // se tiver mais de 1 minuto, retorna "faltam"
                : 'falta' // se tiver equivalente a 1 minuto, retorna "falta"
              : '' // caso não tenha nenhum, retorna vazio.

      // Verifica se tem mais de um dia, se tiver o texto informado é "dias" se não "dia"
      const daysText =
        timer[0] >= 1 ? `${parseInt(timer[0])} ${timer[0] > 1 ? 'dias' : 'dia'}` : ' '

      // Verifica se tem mais de uma hora, se tiver o texto informado é "horas" se não "hora"
      const hoursText =
        timer[1] >= 1
          ? `${timer[0] > 0 ? ', ' : ''}${parseInt(timer[1])} ${
            timer[1] > 1 ? 'horas' : 'hora'
          }`
          : ' '

      // Verifica se tem mais de um minuto, se tiver o texto informado é "minutos" se não "minuto"
      const minutesText =
        timer[2] >= 1
          ? `${timer[1] > 0 ? ' e ' : ''}${parseInt(timer[2])} ${
            timer[2] > 1 ? 'minutos' : 'minuto'
          }`
          : ' '

      return `${firstText}${daysText}${hoursText}${minutesText}`
    }
  },
  computed: {
    ...mapState('course', ['course_loaded'])
  },
  mounted () {
    this.timer = moment(this.course_loaded.end).diff()

    setInterval(() => {
      this.timer -= 1000
    }, 1000)
  }
}
</script>
