var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold title",staticStyle:{"color":"#424242"}},[_vm._v("Selecione a modalidade do curso")]),_c('br'),_c('span',{staticClass:"font-weight-bold body-2",staticStyle:{"color":"#424242"}},[_vm._v("Observação: Dependendo da modalidade que escolher o preço pode variar, você pode assistir essa mudança ao ver o valor total da compra no carrinho antes de finalizar a compra.")])]),_c('v-col',[(_vm.course_loaded.has_course_class === 1)?_c('v-radio-group',{on:{"change":function($event){return _vm.setCourseClass($event)}},model:{value:(_vm.course_class_selected),callback:function ($$v) {_vm.course_class_selected=$$v},expression:"course_class_selected"}},_vm._l((_vm.course_loaded.course_class),function(item,index){return _c('div',{key:index,staticClass:"mb-5",staticStyle:{"background-color":"#e0e0e0"}},[_c('v-radio',{staticClass:"ml-5",staticStyle:{"height":"100px"},attrs:{"value":item.id},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:_vm.mobile
                  ? 'body-1 font-weight-regular'
                  : 'ml-10 body-1 font-weight-regular',staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(item.name))]),_c('v-btn',{style:(_vm.mobile
                  ? _vm.$vuetify.breakpoint.xsOnly
                    ? 'position: absolute; left: 55%; top: 110%;'
                    : 'position: absolute; left: 55%;'
                  : 'position: absolute; left: 80%'),attrs:{"text":"","rounded":""}},[_c('v-avatar',{staticStyle:{"border-radius":"1px solid #ccc"},attrs:{"width":"28","height":"28"}},[_c('v-icon',[_vm._v("mdi-coin-outline")])],1),_c('b',[_vm._v(_vm._s(item.cost_credits))])],1)]},proxy:true}],null,true)})],1)}),0):_vm._e(),(_vm.course_loaded.has_course_class === 0)?_c('v-radio-group',{attrs:{"value":1}},[_c('div',{staticClass:"mb-5",staticStyle:{"background-color":"#e0e0e0"}},[_c('v-radio',{staticClass:"ml-5",staticStyle:{"height":"100px"},attrs:{"value":1},scopedSlots:_vm._u([{key:"label",fn:function(){return [(!_vm.onlySubscription)?_c('span',{class:_vm.mobile
                  ? 'body-1 font-weight-regular'
                  : 'ml-10 body-1 font-weight-regular',staticStyle:{"text-transform":"uppercase"}},[_vm._v("Ensino a distância")]):_vm._e(),_c('span',{staticClass:"ml-10 body-1 font-weight-regular",staticStyle:{"text-transform":"uppercase"},attrs:{"else":"!onlySubscription"}},[_vm._v(_vm._s(_vm.course_loaded.name)+" (Assinatura)")]),_c('v-btn',{style:(_vm.mobile
                  ? _vm.$vuetify.breakpoint.xsOnly
                    ? 'position: absolute; left: 55%; top: 110%;'
                    : 'position: absolute; left: 55%;'
                  : 'position: absolute; left: 80%'),attrs:{"text":"","rounded":""}},[_c('v-avatar',{staticStyle:{"border-radius":"1px solid #ccc"},attrs:{"width":"28","height":"28"}},[_c('v-icon',[_vm._v("mdi-coin-outline")])],1),(!_vm.onlySubscription)?_c('b',[_vm._v(_vm._s(_vm._f("currency")(_vm.course_loaded.course_ead.cost_credits,"")))]):_c('b',[_vm._v(_vm._s(_vm._f("currency")(_vm.cost,"")))])],1)]},proxy:true}],null,false,3791033753)})],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }