export default {
  methods: {
    goToCheckDocumentation () {
      return this.checkDocumentation(
        this.course_loaded.course_id,
        this.course_loaded.registration_id
      )
    },
    checkIfHasDocumentation () {
      if (this.course_loaded.documentation === 1) {
        this.goToCheckDocumentation()
      } else {
        this.$router.push({
          name: 'Disciplinas',
          params: {
            course_id: this.course_loaded.course_id,
            registration_id: this.course_loaded.registration_id
          }
        }).catch(() => {})
      }
    },
    checkCommitmentTerms () {
      if (this.course_loaded.commitment_terms === 1) {
        let url = '/student/commitment_terms/check/'

        return this.$api
          .get(
            `${url}${this.course_loaded.course_id}/${this.course_loaded.registration_id}`
          )
          .then((res) => {
            if (res.data.access === true) {
              this.checkIfHasDocumentation()
            } else {
              this.commitment_terms_dialog = true
              this.loading = false
            }
          })
      } else {
        this.checkIfHasDocumentation()
      }
    }
  }
}
