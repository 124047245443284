export default {
  data: () => ({
    form_data: {
      rg: '',
      issuing_body: '',
      issuance_date: '',
      cpf: '',
      reservist: '',
      category: '',
      voter_title: '',
      zone: '',
      section: ''
    }
  }),
  watch: {
    'form_data.rg' (val) {
      this.$emit('rg', val)
    },
    'form_data.issuing_body' (val) {
      this.$emit('issuing_body', val)
    },
    'form_data.issuance_date' (val) {
      this.$emit('issuance_date', val)
    },
    'form_data.cpf' (val) {
      this.$emit('cpf', val)
    },
    'form_data.reservist' (val) {
      this.$emit('reservist', val)
    },
    'form_data.category' (val) {
      this.$emit('category', val)
    },
    'form_data.voter_title' (val) {
      this.$emit('voter_title', val)
    },
    'form_data.zone' (val) {
      this.$emit('zone', val)
    },
    'form_data.section' (val) {
      this.$emit('section', val)
    }
  }
}
