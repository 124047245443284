import Vue from 'vue'
import axios from 'axios'

Vue.use({
  install (Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('_token')}`
      }
    })

    Vue.prototype.$apiClean = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    Vue.prototype.$viacep = axios.create({
      baseURL: 'https://viacep.com.br'
    })
  }
})
