<template>
  <v-row>
    <v-col cols="12" md="4" sm="12">
      <v-text-field label="DENOMINAÇÃO DA ESCOLA" disabled value="CEAB – ESCOLA DE AVIAÇÃO" />
    </v-col>

    <v-col cols="12" md="4" sm="12">
      <v-text-field label="ANAC" disabled value="CMV" />
    </v-col>

    <v-col cols="12" md="4" sm="12">
      <v-text-field label="INSCRIÇÃO Nº" disabled value="319" />
    </v-col>

    <v-col cols="12" md="6" sm="12">
      <v-text-field
        label="CURSO"
        disabled
        :value="course_loaded.name"
      />
    </v-col>

    <v-col cols="12" md="6" sm="12" style="border: 1px solid #000;">
      <v-text-field v-model="form_data.habilitation" label="HABILITAÇÃO (SE FOR O CASO)"></v-text-field>
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
      <v-text-field disabled :value="`${userData.personal_data.first_name} ${userData.personal_data.last_name}`">
        <template v-slot:label>
          NOME
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid #000;">
      <Date v-model="form_data.cvv_validation" label="VALIDADE DO CCF - 2ª CLASSE" />
    </v-col>

    <v-col cols="12" md="4" sm="12" style="border: 1px solid red;">
      <v-select v-model="form_data.gender" :items="['Masculino', 'Feminino']">
        <template v-slot:label>
          SEXO
          <span style="color: red; font-size: 24px">*</span>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import MatriculationInfoMixin from '@/app/components/Course/mixins/MatriculationInfo'
import Date from '@/shared/components/text-fields/Date'

export default {
  components: { Date },
  mixins: [MatriculationInfoMixin]
}
</script>
