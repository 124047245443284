<template>
  <v-row>
    <v-col cols="7" class="d-flex justify-start">
      <h1 style="color: #424242" class="body-1">RESUMO DO PEDIDO</h1>
    </v-col>

    <v-col cols="4" class="d-flex justify-end ml-5" style="margin-top: -5px">
      <v-btn fab small elevation="1" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="5">
      <v-img
        style="border-radius: 5px"
        :src="$storage + course_loaded.image"
        height="120px"
        width="112px"
      ></v-img>
    </v-col>

    <v-col cols="7">
      <div
        style="color: #424242; margin-left: -10%"
        class="body-1 font-weight-bold mt-4"
      >
        {{ course_loaded.name }}
      </div>
    </v-col>

    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>

    <Cupom />

    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>

    <slot name="courseMode" />

    <v-col cols="6" class="d-flex justify-start">
      <div class="font-weight-light" style="color: #616161">Preço listado</div>
    </v-col>
    <v-col cols="0" class="d-flex justify-end">
      <div class="font-weight-light mr-2" style="color: #616161">
        <v-btn text rounded>
          <v-avatar
            width="28"
            height="28"
            style="border-radius: 1px solid #ccc"
          >
            <v-icon>mdi-coin-outline</v-icon>
          </v-avatar>
          <b>{{ cost | currency("") }}</b>
        </v-btn>
      </div>
    </v-col>

    <v-col cols="6" class="d-flex justify-start">
      <div class="font-weight-light" style="color: #616161">Desconto</div>
    </v-col>
    <v-col cols="0" class="d-flex justify-end">
      <div class="font-weight-light mr-2" style="color: #616161">
        <v-btn text rounded>
          <v-avatar
            width="28"
            height="28"
            style="border-radius: 1px solid #ccc"
          >
            <v-icon>mdi-coin-outline</v-icon>
          </v-avatar>
          <b>{{ discount | currency("") }}</b>
        </v-btn>
      </div>
    </v-col>

    <v-col cols="6" class="d-flex justify-start">
      <div class="font-weight-bold" style="color: #616161">Total a pagar</div>
    </v-col>
    <v-col cols="0" class="d-flex justify-end">
      <div class="font-weight-light mr-2" style="color: #616161">
        <v-btn text rounded>
          <v-avatar
            width="28"
            height="28"
            style="border-radius: 1px solid #ccc"
          >
            <v-icon>mdi-coin-outline</v-icon>
          </v-avatar>
          <b>{{ (cost - discount) | currency("") }}</b>
        </v-btn>
      </div>
    </v-col>

    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>

    <v-col
      v-if="
        course_mode.course_class !== null ||
        course_loaded.has_course_class === 0
      "
    >
      <div v-if="paymentInfo !== null && paymentInfo.installment === 1">
        <v-checkbox
          @change="setInstallment($event)"
          :value="course_mode.installment"
          label="Boleto Parcelado"
        ></v-checkbox>
      </div>

      <div
        v-if="paymentInfo.installment === 1 && course_mode.installment === true"
      >
        <p>
          Observação: a entrada do parcelamento em boleto é feito por ceab
          créditos.
        </p>
        <v-row>
          <v-col cols="6" class="d-flex justify-start">
            <div class="font-weight-light" style="color: #616161">
              Valor total
            </div>
          </v-col>
          <v-col cols="0" class="d-flex justify-end">
            <div class="font-weight-light mr-2" style="color: #616161">
              <v-btn text rounded>
                <b>{{
                  (paymentInfo.installment_cost - discount) | currency
                }}</b>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="3" class="d-flex justify-start">
            <div class="font-weight-light" style="color: #616161">Entrada</div>
          </v-col>
          <v-col cols="0" class="d-flex justify-end">
            <div class="font-weight-light mr-2" style="color: #616161">
              <v-btn text rounded>
                <v-avatar
                  width="28"
                  height="28"
                  style="border-radius: 1px solid #ccc"
                >
                  <v-icon>mdi-coin-outline</v-icon>
                </v-avatar>
                <b>{{
                  ((paymentInfo.installment_cost - discount) /
                    (course_mode.installment_number + 1))
                    | currency
                }}</b>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="d-flex justify-start">
            <v-select
              label="Parcelas"
              item-text="message"
              item-value="index"
              @change="setInstallmentNumber($event)"
              :items="installmentList"
              :value="course_mode.installment_number"
            ></v-select>
          </v-col>
        </v-row>
      </div>

      <br v-if="paymentInfo.installment === 1" />
      <v-divider v-if="paymentInfo.installment === 1"></v-divider>
    </v-col>

    <v-col cols="12" class="d-flex justify-center">
      <v-btn
        :disabled="!course_loaded.available_for_sale === true"
        block
        color="success"
        height="50px"
        @click="check()"
        >Prosseguir</v-btn
      >
    </v-col>
    <v-dialog
      persistent
      v-model="purchaseDialog"
      max-width="500px"
      v-if="purchaseDialog"
    >
      <Purchase @close="closePurchase()" />
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Cupom from './Cupom'
import installments from './installments'
import Purchase from './purchase'

export default {
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  components: { Cupom, Purchase },
  data: () => ({
    purchaseDialog: false
  }),
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('courseStore', [
      'course_loaded',
      'course_mode',
      'course_terms',
      'accepted_terms',
      'cupom'
    ]),
    ...mapGetters('courseStore', [
      'paymentInfo',
      'onlySubscription',
      'cost',
      'discount'
    ]),
    installmentList () {
      return this.course_mode.installment === true
        ? installments(
          (this.paymentInfo.installment_cost - this.discount).toFixed(2) *
              100,
          this.paymentInfo.installment_quantity,
          'parcelas de'
        ).filter((item) => item.index !== 1)
        : null
    }
  },
  methods: {
    ...mapActions('user', ['updateUser']),
    ...mapActions('courseStore', [
      'setInstallment',
      'setInstallmentNumber',
      'dialogCourseTerms'
    ]),
    check () {
      if (!this.accepted_terms) {
        this.dialogCourseTerms(true)
        return
      }

      if (this.onlySubscription) {
        this.purchaseDialog = true
        return
      }

      return this.userData.credits <
        (this.course_mode.installment === true
          ? (this.paymentInfo.installment_cost - this.discount) /
            (this.course_mode.installment_number + 1)
          : this.cost - this.discount)
        ? (this.purchaseDialog = true)
        : this.finish()
    },
    closePurchase () {
      this.dialogCourseTerms(false)
      this.purchaseDialog = false
    },
    async finish () {
      const url = '/student/store/purchase'

      await this.$api
        .post(url, {
          course_id: this.course_loaded.id,
          course_class: this.course_mode.course_class,
          installment: this.course_mode.installment,
          installment_number: this.course_mode.installment_number,
          cupom: this.cupom !== null ? this.cupom[0].cupom : null,
          payment_method: 3,
          subscription: this.onlySubscription,
          client_name: this.userData.personal_data.full_name
        })
        .then(() => {
          this.updateUser()
        })
        .finally(() => {
          this.$router.push('/compras/historico')
        })
    }
  },
  watch: {
    accepted_terms (val) {
      if (this.accepted_terms && this.course_terms.length > 0) {
        if (this.onlySubscription) {
          this.purchaseDialog = true
          return
        }
        if (this.course_loaded.id === 21) {
          this.purchaseDialog = true
          return
        }
        return this.userData.credits <
          (this.course_mode.installment === true
            ? (this.paymentInfo.installment_cost - this.discount) /
              (this.course_mode.installment_number + 1)
            : this.cost - this.discount)
          ? (this.purchaseDialog = true)
          : this.finish()
      }
    }
  }
}
</script>
