import Vue from 'vue'
import $store from '../store'

Vue.use({
  install (Vue) {
    Vue.prototype.$snackbar = {
      success: (text) => $store.dispatch('snackbar/success', text),
      error: (text) => $store.dispatch('snackbar/error', text)
    }
  }
})
