<template>
  <v-text-field
    v-bind="bind"
    @input="input"
    :rules="[rules.required]"
    :color="color"
    required
    @keyup.enter="$emit('enter', true)"
  >
    <template v-slot:label>
      <span v-html="label"></span>
    </template>
  </v-text-field>
</template>

<script>
import TextFieldDefaultMixin from './Mixins/textfield-default-mixin'
import { mask } from 'vue-the-mask'

export default {
  props: ['item', 'color', 'label'],
  mixins: [TextFieldDefaultMixin],
  directives: { mask },
  data: () => ({
    rules: {
      required: v => !!v || 'Este campo é obrigatório!'
    }
  })
}
</script>
