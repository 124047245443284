<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-center">{{ selected.name }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center mb-10"
          >A turma do curso {{ selected.name }} ainda não começou, veja abaixo o cronometro para o inicio da turma.</v-col>

          <v-col cols="12" class="d-flex justify-center">
              <div>
                  <span style="font-size: 48px; color: grey">{{ dhms.split(':')[0] }}</span> <br />
                  <span class="d-flex justify-center">Dia(s)</span>
              </div>
              <div class="ml-8">
                  <span style="font-size: 48px; color: grey">{{ dhms.split(':')[1] }}</span> <br />
                  <span class="d-flex justify-center">Hora(s)</span>
              </div>
              <div class="ml-8">
                  <span style="font-size: 48px; color: grey">{{ dhms.split(':')[2] }}</span> <br />
                  <span class="d-flex justify-center">Minuto(s)</span>
              </div>
              <div class="ml-8">
                  <span style="font-size: 48px; color: grey">{{ dhms.split(':')[3] }}</span> <br />
                  <span class="d-flex justify-center">Segundo(s)</span>
              </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import * as timer from '@/shared/utils/Timer'

export default {
  props: ['selected'],
  data: () => ({
    dhms: ''
  }),
  methods: {
    getTime () {
      setInterval(() => {
        this.dhms = timer.secondsToTime(
          timer.dateDiff(
            `${timer.changeTimezone(timer.dateNow(), timer.timeNow(), '+03:00').date} ${timer.changeTimezone(timer.dateNow(), timer.timeNow(), '+03:00').time}`,
            this.selected.start
          ),
          'd:h:m:s'
        )
      }, 1000)
    }
  },
  beforeMount () {
    this.getTime()
  },
  beforeDestroy () {
    clearInterval()
  }
}
</script>
