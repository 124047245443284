import Dinero from 'dinero.js'

export default (value, installments, message) => {
  const cost = Dinero({
    amount: value,
    currency: 'BRL',
    precision: 2
  })

  let installment = []

  for (let i = 0; i < installments; i++) {
    installment.push({
      index: i + 1,
      message:
                i +
                1 +
                ` ${message} ` +
                cost
                  .divide(i + 1)
                  .setLocale('pt-BR')
                  .toFormat()
    })
  }

  return installment
}
