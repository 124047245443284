<template>
  <div>
    <v-row>
      <v-col cols="12">
        <TextField
          v-model="cardNumber"
          maskData="#### #### #### ####"
          :filled="true"
          label="Número do cartão"
        />
      </v-col>

      <v-col cols="12">
        <TextField
          v-model="cardName"
          :filled="true"
          label="Nome impresso no cartão"
        />
      </v-col>

      <v-col cols="4">
        <v-select :items="MM" v-model="cardMonth" filled label="MM"></v-select>
      </v-col>

      <v-col cols="4">
        <v-select :items="YY" v-model="cardYear" filled label="YY"></v-select>
      </v-col>

      <v-col cols="4">
        <TextField label="CVV" maskData="####" :filled="true" v-model="cvv" />
      </v-col>

      <v-col cols="12">
        <v-select
          v-if="selected.installments > 1"
          label="Parcelas"
          item-text="message"
          item-value="index"
          filled
          :items="installmentList"
          v-model="installment"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <TextField
          :filled="true"
          label="Nome Completo do titular do cartão"
          v-model="name"
          :required="true"
          :min="5"
          :max="100"
          :counter="100"
        />
      </v-col>

      <v-col cols="12">
        <TextField
          :email="true"
          :filled="true"
          label="Email do titular do cartão"
          v-model="email"
        />
      </v-col>

      <v-col cols="12">
        <TextField
          :filled="true"
          label="CPF do titular do cartão"
          maskData="###.###.###-##"
          v-model="cpf"
        />
      </v-col>

      <v-col cols="12">
        <TextField
          :filled="true"
          maskData="## #####-####"
          label="Telefone Celular do titular do cartão"
          v-model="phone"
        />
      </v-col>

      <v-col cols="12">
        <TextField
          :filled="true"
          maskData="#####-###"
          label="CEP da fatura do cartão"
          v-model="cep"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import Card from '@/shared/components/Card/index'
import { mapState } from 'vuex'
import TextField from '@/shared/components/text-fields/text-field'
import installments from './installments'
export default {
  props: ['selected', 'extend'],
  components: { TextField },
  data: () => ({
    cardNumber: '',
    cardName: '',
    cardMonth: null,
    cardYear: null,
    cvv: null,
    name: '',
    email: '',
    cpf: '',
    phone: '',
    cep: '',
    installment: 1,
    personal_data: false
  }),
  computed: {
    ...mapState('user', ['userData']),
    MM () {
      let arr = []
      for (let i = 1; i <= 12; i++) arr.push(`${i}`)
      return arr
    },
    YY () {
      let arr = []
      for (let i = 2020; i <= 2050; i++) arr.push(i)
      return arr
    },
    installmentList () {
      return installments(
        this.selected.cost,
        this.selected.installments,
        'parcela de'
      )
    }
  },
  watch: {
    $data: {
      handler: function (val, oldVal) {
        this.$emit('output', val)
      },
      deep: true
    }
  }
}
</script>
