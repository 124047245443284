<template>
  <v-container fluid>
    <v-simple-table :fixed-header="true">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Assinatura</th>
            <th class="text-left">Próximo vencimento</th>
            <th class="text-left">Status</th>
            <th class="text-left">Cobranças</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.name }}</td>
            <td>
              {{ item.subscription.nextDueDate | date }}
            </td>
            <td>{{ item.subscription.status | status }}</td>
            <td>
              <v-btn @click.stop="open(item)" color="primary"
                >Abrir Cobranças</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-if="cobrancasDialog" v-model="cobrancasDialog">
      <v-card>
          <v-card-title>Cobranças</v-card-title>
        <v-card-text>
          <v-simple-table :fixed-header="true">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Cobrança</th>
                  <th class="text-left">Valor</th>
                  <th class="text-left">Vencimento</th>
                  <th class="text-left">Tipo de pagamento</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Pagar</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in selected_subscription.cobranca"
                  :key="index"
                >
                  <td>
                    Nº {{ selected_subscription.cobranca.length - index }}
                  </td>
                  <td>{{ item.value | currency }}</td>
                  <td>{{ item.dueDate | date }}</td>
                  <td>{{ item.billingType | billingType }}</td>
                  <td>{{ item.status | paymentStatus }}</td>
                  <td>
                    <v-btn
                      @click.stop="openPaymentHandler(item)"
                      color="primary"
                      :disabled="
                        item.status === 'CONFIRMED' ||
                        item.status === 'RECEIVED' ||
                        item.status === 'REFUNDED'
                      "
                      >Pagar</v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      style="overflow: hidden"
      v-if="paymentAsaasDialog"
      v-model="paymentAsaasDialog"
      max-width="700px"
      persistent
    >
      <v-card>
        <v-btn
          fab
          small
          @click.stop="paymentAsaasDialog = false"
          style="position: absolute; top: 10px; right: 25px"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <iframe
          width="100%"
          class="myIframe"
          :src="selected_payment.invoiceUrl"
          frameborder="0"
        ></iframe>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y')
    },
    status (val) {
      switch (val) {
        case 'ACTIVE':
          return 'Ativo'
      }
    },
    paymentStatus (val) {
      var status

      switch (val) {
        case 'PENDING':
          status = 'Pendente'
          break
        case 'RECEIVED':
          status = 'Recebido'
          break
        case 'CONFIRMED':
          status = 'Confirmado'
          break
        case 'OVERDUE':
          status = 'Vencido'
          break
        case 'REFUNDED':
          status = 'Estornado'
      }

      return status
    },
    billingType (val) {
      var newValue

      switch (val) {
        case 'CREDIT_CARD':
          newValue = 'Cartão de Crédito'
          break
        case 'BOLETO':
          newValue = 'Boleto'
      }

      return newValue
    }
  },
  data: () => ({
    items: [],
    selected_subscription: [],
    cobrancasDialog: false,
    selected_payment: [],
    paymentAsaasDialog: false
  }),
  methods: {
    async getSubscriptions () {
      const url = '/student/purchases/subscriptions'

      await this.$api.get(url).then((res) => {
        this.items = res.data
      })
    },
    open (item) {
      this.selected_subscription = item
      this.cobrancasDialog = true
    },
    openPaymentHandler (item) {
      if (item.billingType === 'CREDIT_CARD') {
        this.selected_payment = item
        this.paymentAsaasDialog = true
      } else {
        window.open(item.bankSlipUrl)
      }
    }
  },
  beforeMount () {
    this.getSubscriptions()
  }
}
</script>

<style scoped>
.myIframe {
  height: 500px;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
</style>
