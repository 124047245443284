<template>
  <div v-if="active">
    <v-fab-transition>
      <v-btn :style="$vuetify.breakpoint.smAndDown ? 'margin-bottom: 50px' : ''" color="primary" dark fixed bottom right fab @click.stop="top()">
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
export default {
  data: () => ({
    active: false
  }),
  methods: {
    top () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    handleScroll (event) {
      this.active = window.pageYOffset > 250
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
