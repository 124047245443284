<template>
  <v-container v-if="show">
    <a href="https://clube.magazineluiza.com.br/parceiros" target="_blank">
      <img :width="$vuetify.breakpoint.xsOnly ? 375 : 500" height="200" src="@/app/assets/banner_1.png" />
    </a>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('course', ['courses', 'course_loaded']),
    show () {
      return (
        this.courses.all_courses.length > 0 ||
        this.course_loaded !== null
      )
    }
  }
}
</script>
