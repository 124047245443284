<template>
  <v-container>
    <banner-magazine class="d-flex justify-center" />
    <v-card>
      <v-tabs
        v-model="tab"
        background-color="white"
        color="blue"
        right
        show-arrows
      >
        <v-tab>Entradas / Faturas</v-tab>
        <v-tab>Assinaturas</v-tab>
        <v-tab>Ceab Créditos</v-tab>
      </v-tabs>

      <div v-if="tab === 0">
        <span class="title d-flex justify-center font-weight-medium"
          >Entrada(s)</span
        >
        <Courses />
        <v-divider></v-divider>
        <span class="title d-flex mt-3 justify-center font-weight-medium"
          >Parcela(s)</span
        >
        <Invoices />
      </div>

      <div v-if="tab === 1">
        <Subscriptions />
      </div>

      <div v-if="tab === 2">
        <CeabCredits />
      </div>
    </v-card>
  </v-container>
</template>

<script>
import CeabCredits from '@/app/components/PurchaseHistory/CeabCredits'
import Courses from '@/app/components/PurchaseHistory/Courses'
import Invoices from '@/app/components/PurchaseHistory/Invoices'
import Subscriptions from '../components/PurchaseHistory/Subscriptions.vue'
import BannerMagazine from '@/app/components/BannerMagazine'

export default {
  components: { CeabCredits, Courses, Invoices, Subscriptions, BannerMagazine },
  data: () => ({
    tab: 0
  })
}
</script>

<style scoped>
.v-tabs-item {
  flex-wrap: wrap;
  width: 100%;
}
</style>
