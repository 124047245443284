<template>
  <div>
    <v-menu :close-on-content-click="false" :nudge-width="200" :nudge-right="10" offset-x>
      <template v-slot:activator="{ on }">
        <v-img width="200px" v-on="on" src="@/app/assets/resumo.png"></v-img>
      </template>

      <v-card>
        <v-card-title rounded class="body-1 d-flex justify-center">Material de Estudo</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item-group>
              <v-list-item
                @click="openSummary(item)"
                v-for="(item, index) in summaries"
                :key="index"
              >
                <v-list-item-icon>
                  <span v-if="item.content_already_finished">
                      <img src="@/shared/assets/success.png" width="15px" />
                  </span>
                  <v-icon v-else>mdi-table-of-contents</v-icon>
                </v-list-item-icon>
                <v-list-item-content color="red">
                    <span class="body-2">{{ item.name }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-dialog v-model="resumo1" persistent max-width="1360">
      <v-window
        v-model="window"
        class="elevation-1"
        :show-arrows="true"
        :reverse="false"
        v-if="summary !== undefined"
      >
        <v-window-item>
          <v-card flat>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn
                @click="loadMaterial()"
                :disabled="summary.summary[0].file === null"
                large
                color="primary"
                class="mr-5"
                elevation="1"
              >Baixar Material</v-btn>

              <v-btn fab small elevation="1" @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container style="margin-top: -50px">
                <v-row class="mb-4 ml-0" align="center">
                  <strong class="title" style="word-break: break-word">{{ summary.name }}</strong>
                </v-row>
                <div cols="12" v-for="(item, index) in summary.summary" :key="index">
                  <div v-html="item.text"></div>
                </div>
              </v-container>
            </v-card-text>
          </v-card>
        </v-window-item>
      </v-window>
    </v-dialog>
  </div>
</template>

<script>
import { RegisterProgress } from '../../utils/Discipline'
import { mapState } from 'vuex'

export default {
  props: ['discipline', 'summaries'],
  data: () => ({
    resumo1: false,
    length: 3,
    window: 0,
    summary: undefined,
    course_id: undefined,
    registration_id: undefined
  }),
  computed: {
    ...mapState('course', ['course_loaded'])
  },
  methods: {
    close () {
      this.resumo1 = false
      this.$userHistories.end(`Resumo: ${this.summary.id}`)
    },
    openSummary (summary) {
      this.summary = summary
      this.$userHistories.setHistory({
        id: `Resumo: ${this.summary.id}`,
        text: `Abriu o Resumo: "${this.summary.name}" na disciplina "${this.discipline.name}"`,
        tag: this.course_loaded.name
      })
      this.$userHistories.start(`Resumo: ${this.summary.id}`)

      if (summary.summary[0].text === null) {
        this.register(summary)
        this.loadMaterial()
        this.$userHistories.end(`Resumo: ${this.summary.id}`)
      } else {
        this.resumo1 = true
        setTimeout(() => {
          this.register(summary)
        }, 30000)
      }
    },
    async register (summary) {
      await RegisterProgress(
        this.$api,
        this.course_id,
        this.registration_id,
        summary.discipline_id,
        summary
      ).then((res) => {
        if (!res.data.content_already_finished) {
          this.$snackbar.success(
            'Parabéns! Mais um conteúdo acessado!'
          )
          this.$emit('updateDiscipline')
        }
      })
    },
    loadMaterial () {
      window.open(this.$storage + this.summary.summary[0].file)
    }
  },
  beforeMount () {
    this.course_id = this.$router.currentRoute.params.course_id
    this.registration_id = this.$router.currentRoute.params.registration_id
  }
}
</script>
