<template>
  <div v-if="blocks.length > 0">
    <v-card>
      <v-card-title class="d-flex justify-center">
        Provas Finais
        <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
        <v-btn v-if="$vuetify.breakpoint.smAndDown" fab small elevation="1" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <FinalExamBlocks @updateDiscipline="updateDiscipline($event)" :blocks="blocks" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FinalExamBlocks from './Block/FinalExamBlocks'

export default {
  props: ['blocks'],
  components: { FinalExamBlocks },
  methods: {
    updateDiscipline ($event) {
      this.$emit('updateDiscipline', $event)
    }
  }
}
</script>
