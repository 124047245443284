<template>
  <div>
    <v-btn
      @click.stop="open()"
      text
      rounded
      class="mr-3"
      v-if="userData.personal_data"
    >
      <v-avatar width="28" height="28" style="border-radius: 1px solid #ccc;">
        <v-icon>mdi-coin-outline</v-icon>
      </v-avatar>
      <b v-if="userData.personal_data">{{ userData.credits | currency('') }}</b>
    </v-btn>

    <v-dialog max-width="500px" v-model="dialog">
      <v-card>
        <v-card-title class="d-flex justify-center">Ceab Créditos</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                text
                rounded
                class="mr-3"
                v-if="userData.personal_data && !$vuetify.breakpoint.xsOnly"
              >
                <v-avatar width="28" height="28" style="border-radius: 1px solid #ccc;">
                  <v-icon>mdi-coin-outline</v-icon>
                </v-avatar>
                <b v-if="userData.personal_data">{{ userData.credits | currency('') }}</b>
              </v-btn>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <v-btn color="primary" @click.stop="addCredit_dialog = true">Precisa de mais créditos? <br v-if="$vuetify.breakpoint.xsOnly" /> Clique aqui!</v-btn>
            </v-col>

            <v-col cols="12">
              <span class="title">
                O que é Ceab Créditos?
              </span>
              <span class="body-1">
                Ceab Créditos é uma moeda virtual que pode ser recarregada sempre que necessário pelo nosso consumidor e que poderá ser utilizada na plataforma ceab para comprar cursos ou produtos na nossa loja.
              </span>
            </v-col>
            <v-col cols="12">
              <span class="title">
                Quanto vale cada Ceab Crédito?
              </span>
              <span class="body-1">
                Cada Ceab Crédito equivale a R$ 1,00. <br /> Ex: R$ 100,00 => <v-btn
                text
                rounded
              >
                <v-avatar width="28" height="28" style="border-radius: 1px solid #ccc;">
                  <v-icon>mdi-coin-outline</v-icon>
                </v-avatar>
                <b>{{ 100 | currency('') }}</b>
              </v-btn>
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500px" v-model="addCredit_dialog" persistent>
      <AddCredit @close="addCredit_dialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import AddCredit from './AddCredit'
import { mapState } from 'vuex'

export default {
  components: { AddCredit },
  data: () => ({
    dialog: false,
    addCredit_dialog: false
  }),
  computed: {
    ...mapState('user', ['userData'])
  },
  methods: {
    open () {
      this.dialog = true
      this.$emit('close_drawer')
    }
  }
}
</script>
