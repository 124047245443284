<template>
  <v-col cols="12">
    <v-text-field
      outlined
      label="Insira seu cupom aqui!"
      v-model="cupom"
      :rules="[cupom_exist]"
    ></v-text-field>
    <div class="d-flex justify-center">
      <a
        href="https://api.whatsapp.com/send?phone=5511955901600&text=Oi,%20quero%20me%20inscrever%20para%20Curso%20de%20Comiss%C3%A1rio%20de%20Voo%20EAD"
        target="_blank"
        ><img
          :width="$vuetify.breakpoint.smOnly ? '75%' : '100%'"
          src="@/app/assets/cupom.png"
      /></a>
    </div>
  </v-col>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    cupom: '',
    cupom_data: []
  }),
  computed: {
    ...mapState('courseStore', ['course_loaded']),
    ...mapGetters('courseStore', ['paymentInfo']),
    cupom_exist () {
      return this.cupom_data.length > 0 || `O Cupom é inválido`
    }
  },
  methods: {
    ...mapActions('courseStore', ['setCupom']),
    async checkCupom (val) {
      if (val.length < 3) {
        this.setCupom(null)
      }

      if (val.length >= 3) {
        await this.$api
          .post('/student/course_store_discount/checkCupom', {
            course_id: this.course_loaded.id,
            course_class_id:
              this.course_loaded.has_course_class === 1
                ? this.paymentInfo.course_class_id
                : null,
            cupom: this.cupom
          })
          .then((res) => {
            this.cupom_data = res.data
          })
          .finally(() => {
            if (this.cupom_data.length > 0) {
              this.setCupom(this.cupom_data)
            } else {
              this.setCupom(null)
            }
          })
      }
    }
  },
  watch: {
    async cupom (val) {
      await this.checkCupom(this.cupom)
    },
    'paymentInfo.course_class_id' (val) {
      this.checkCupom(this.cupom)
    }
  }
}
</script>
