import sortBy from '@/shared/utils/sortBy'

export default {
  namespaced: true,
  state: {
    courses: {
      all_courses: [],
      main_courses: [],
      prisma: [],
      complementary_courses: [],
      interval: null
    },
    course_loaded: null,
    registration: [],
    discipline: [],
    final_exam: []
  },
  getters: {
    //
  },
  mutations: {
    async loadCourses (state) {
      if (state.courses.interval !== null) {
        return
      }
      this._vm.$loading.load('Carregando cursos...')
      var selfState = state

      await this._vm.$api.get('/student/all_courses').then(res => {
        selfState.courses.all_courses = res.data.filter(item => item.course_id !== 21)
        selfState.courses.main_courses = sortBy(selfState.courses.all_courses.filter(item => item.course_type === 1 && item.father_course_id === null), 'position')
        selfState.courses.prisma = sortBy(selfState.courses.all_courses.filter(item => item.course_type === 1 && item.father_course_id !== null), 'position')
        selfState.courses.complementary_courses = sortBy(selfState.courses.all_courses.filter(item => item.course_type === 2 && item.father_course_id === null), 'position')
      }).finally(() => {
        this._vm.$loading.loaded()
      })

      state.courses.interval = setTimeout(() => {
        this._vm.$api.get('/student/all_courses').then(res => {
          selfState.courses.all_courses = res.data.filter(item => item.course_id !== 21)
          selfState.courses.main_courses = sortBy(selfState.courses.all_courses.filter(item => item.course_type === 1 && item.father_course_id === null), 'position')
          selfState.courses.prisma = sortBy(selfState.courses.all_courses.filter(item => item.course_type === 1 && item.father_course_id !== null), 'position')
          selfState.courses.complementary_courses = sortBy(selfState.courses.all_courses.filter(item => item.course_type === 2 && item.father_course_id === null), 'position')
        })
      }, 1800000 /* 30m */)
    },
    selectCourse (state, payload) {
      state.course_loaded = payload
    },
    autoAttributeCourse (state, payload) {
      state.course_loaded = state.courses.all_courses.find(
        item => item.course_id === payload.course_id &&
          item.registration_id === payload.registration_id)
    }
  },
  actions: {
    loadCourses ({ commit }) {
      commit('loadCourses')
    },
    selectCourse ({ commit }, payload) {
      commit('selectCourse', payload)
    },
    autoAttributeCourse ({ commit }, payload) {
      commit('autoAttributeCourse', payload)
    }
  }
}
