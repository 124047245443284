<template>
  <div>
    <div v-if="show">
      <v-container>
        <v-row>
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <StartMeeting
              v-if="
                course_loaded.course_type === 1 &&
                course_loaded.has_course_class === 1
              "
            />
          </v-col>
          <v-col cols="12" md="8">
            <v-card v-if="changeDisciplineLoaded">
              <v-card-text>
                <template>
                  <v-card-title
                    class="d-flex justify-center"
                    style="word-break: break-word"
                    >{{ discipline.name }}</v-card-title
                  >
                  <v-container>
                    <v-row>
                      <v-col
                        :class="
                          $vuetify.breakpoint.xsOnly
                            ? 'd-flex justify-center'
                            : ''
                        "
                        cols="12"
                        md="3"
                        sm="4"
                        v-if="Object.keys(content.books).length > 0"
                      >
                        <Books
                          :discipline="discipline"
                          :course_loaded="course_loaded"
                          :books="content.books"
                          @updateDiscipline="updateDiscipline()"
                        />
                      </v-col>
                      <v-col
                        :class="
                          $vuetify.breakpoint.xsOnly
                            ? 'd-flex justify-center'
                            : ''
                        "
                        cols="12"
                        md="3"
                        sm="4"
                        v-if="Object.keys(content.summaries).length > 0"
                      >
                        <Summaries
                          :discipline="discipline"
                          :course_loaded="course_loaded"
                          :summaries="content.summaries"
                          @updateDiscipline="updateDiscipline()"
                        />
                      </v-col>
                      <v-col
                        :class="
                          $vuetify.breakpoint.xsOnly
                            ? 'd-flex justify-center'
                            : ''
                        "
                        cols="12"
                        md="3"
                        sm="4"
                        v-if="Object.keys(content.videoLesson).length > 0"
                      >
                        <VideoLessons
                          :discipline="discipline"
                          :course_loaded="course_loaded"
                          :videoLessons="content.videoLesson"
                          @updateDiscipline="updateDiscipline()"
                        />
                      </v-col>
                      <v-col
                        :class="
                          $vuetify.breakpoint.xsOnly
                            ? 'd-flex justify-center'
                            : ''
                        "
                        cols="12"
                        md="3"
                        sm="4"
                        v-if="Object.keys(mock_exam).length > 0"
                      >
                        <Exam
                          :discipline="discipline"
                          :course_loaded="course_loaded"
                          :exams="mock_exam"
                          :mock="true"
                          @open-exam="openExam($event)"
                          @updateDiscipline="updateDiscipline()"
                        />
                      </v-col>

                      <v-col
                        :class="
                          $vuetify.breakpoint.xsOnly
                            ? 'd-flex justify-center'
                            : ''
                        "
                        cols="12"
                        md="3"
                        sm="4"
                        v-if="Object.keys(exam).length > 0"
                      >
                        <Exam
                          :discipline="discipline"
                          :course_loaded="course_loaded"
                          :exams="exam"
                          :mock="false"
                          @open-exam="openExam($event)"
                          @updateDiscipline="updateDiscipline()"
                        />
                      </v-col>
                      <v-col
                        v-if="Object.keys(content.curiosity).length > 0"
                        :class="
                          $vuetify.breakpoint.xsOnly
                            ? 'd-flex justify-center'
                            : ''
                        "
                        cols="12"
                        md="3"
                        sm="4"
                      >
                        <Curiosities
                          :course_loaded="course_loaded"
                          :discipline="discipline"
                          :curiosities="content.curiosity"
                          @updateDiscipline="updateDiscipline()"
                        />
                      </v-col>
                      <v-col
                        :class="
                          $vuetify.breakpoint.xsOnly
                            ? 'd-flex justify-center'
                            : ''
                        "
                        cols="12"
                        md="3"
                        sm="4"
                        v-if="
                          Object.keys(content.complementaryMaterial).length > 0
                        "
                      >
                        <ComplementaryMaterial
                          :course_loaded="course_loaded"
                          :discipline="discipline"
                          @updateDiscipline="updateDiscipline()"
                          :complementaryMaterial="content.complementaryMaterial"
                        />
                      </v-col>

                      <FaqChannel
                        :class="
                          $vuetify.breakpoint.xsOnly
                            ? 'd-flex justify-center'
                            : ''
                        "
                        v-if="
                          course_loaded.course_type === 1 &&
                          course_loaded.course_id === 1
                        "
                        :course_loaded="course_loaded"
                        :discipline="discipline"
                      />

                      <v-col
                        :class="
                          $vuetify.breakpoint.xsOnly
                            ? 'd-flex justify-center'
                            : ''
                        "
                        cols="12"
                        md="3"
                        sm="4"
                        v-if="
                          course_loaded.course_type === 1 &&
                          course_loaded.course_id === 1
                        "
                      >
                        <a href="skype:suporteceabonline?call">
                          <v-img
                            width="200px"
                            src="@/app/assets/skype.png"
                          ></v-img>
                        </a>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </v-card-text>
            </v-card>
            <banner-magazine class="d-flex justify-center" />
          </v-col>
          <v-col cols="12" md="4">
            <CourseTimerEnd v-if="(course_loaded.name).search('(Prisma Club)') === -1" class="mb-5" />
            <StartMeeting
              v-if="
                course_loaded.course_type === 1 &&
                course_loaded.has_course_class === 1 &&
                !$vuetify.breakpoint.smAndDown
              "
            />
            <Certified
              v-if="
                (course_loaded.course_type === 1 &&
                  registration.liberated_certified === 1 &&
                  registration.approved &&
                  course_loaded.has_course_class === 1 &&
                  course_loaded.course_id !== 1 &&
                  course_loaded.course_id !== 19 &&
                  !$vuetify.breakpoint.smAndDown) ||
                (course_loaded.course_type === 1 &&
                  registration.frequency === 100 &&
                  registration.approved &&
                  course_loaded.has_course_class === 0 &&
                  course_loaded.course_id !== 1 &&
                  course_loaded.course_id !== 19 &&
                  !$vuetify.breakpoint.smAndDown)
              "
              class="mb-5"
              :registration="registration"
            />
            <ComplementaryCourseCertified
              v-if="
                course_loaded.course_type === 2 &&
                registration.frequency === 100 &&
                registration.approved &&
                course_loaded.certified === 1 &&
                !$vuetify.breakpoint.smAndDown
              "
              class="mb-5"
              :registration="registration"
            />
            <FinalExam
              v-if="courseResumeReset && !$vuetify.breakpoint.smAndDown"
              class="mb-5"
              @updateDiscipline="updateDiscipline()"
              :blocks="final_exams"
            />
            <course-resume
              :discipline="discipline"
              class="mb-5"
              v-if="courseResumeReset && !$vuetify.breakpoint.smAndDown"
              @changeDiscipline="changeDiscipline($event, true)"
            />
          </v-col>
        </v-row>
        <v-dialog
          v-if="courseResumeReset && $vuetify.breakpoint.smAndDown"
          v-model="courseResumeDialog"
        >
          <course-resume
            :course_loaded="course_loaded"
            :discipline="discipline"
            @changeDiscipline="changeDiscipline($event, true)"
            @close="courseResumeDialog = false"
          />
        </v-dialog>

        <v-dialog
          v-if="courseResumeReset && show && $vuetify.breakpoint.smAndDown"
          v-model="finalExamDialog"
        >
          <FinalExam
            v-if="courseResumeReset"
            class="mb-5"
            @updateDiscipline="updateDiscipline()"
            :blocks="final_exams"
            @close="finalExamDialog = false"
          />
        </v-dialog>

        <v-dialog
          v-if="courseResumeReset && show && $vuetify.breakpoint.smAndDown"
          v-model="certifiedDialog"
        >
          <v-card>
            <v-card-text>
              <Certified
                v-if="
                  (course_loaded.course_type === 1 &&
                    registration.liberated_certified === 1 &&
                    registration.approved &&
                    course_loaded.has_course_class === 1 &&
                    course_loaded.course_id !== 1 &&
                    course_loaded.course_id !== 19) ||
                  (course_loaded.course_type === 1 &&
                    registration.frequency === 100 &&
                    registration.approved &&
                    course_loaded.has_course_class === 0 &&
                    course_loaded.course_id !== 1 &&
                    course_loaded.course_id !== 19)
                "
                class="mb-5"
                :registration="registration"
                @expiredCertificate="expiredCertificate = $event"
              />
              <span v-if="expiredCertificate">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <span class="title">Seu certificado expirou!</span>
                  </v-col>
                </v-row>
              </span>
              <ComplementaryCourseCertified
                v-if="
                  course_loaded.course_type === 2 &&
                  course_loaded.certified === 1
                "
                class="mb-5"
                :registration="registration"
                :show="complementary_course_certified"
              />
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-bottom-navigation
          class="overflow-hidden"
          v-model="bottom_nav"
          v-if="$vuetify.breakpoint.smAndDown && show"
          app
          grow
          fixed
          dark
        >
          <v-btn @click.stop="courseResumeDialog = true">
            <span>Disciplinas</span>
            <v-icon>mdi-view-list</v-icon>
          </v-btn>
          <v-btn
            @click.stop="certifiedDialog = true"
            v-if="
              (course_loaded.course_type === 1 &&
                registration.liberated_certified === 1 &&
                registration.approved &&
                course_loaded.has_course_class === 1 &&
                course_loaded.course_id !== 1 &&
                course_loaded.course_id !== 19) ||
              (course_loaded.course_type === 1 &&
                registration.frequency === 100 &&
                registration.approved &&
                course_loaded.has_course_class === 0 &&
                course_loaded.course_id !== 1 &&
                course_loaded.course_id !== 19) ||
              (course_loaded.course_type === 2 &&
                registration.frequency === 100 &&
                registration.approved &&
                course_loaded.certified === 1)
            "
          >
            <span>Certificado</span>
            <v-icon>mdi-certificate</v-icon>
          </v-btn>
          <v-btn
            @click.stop="finalExamDialog = true"
            v-if="final_exams.length > 0"
          >
            <span>Provas Finais</span>
            <v-icon>mdi-battlenet</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-container>

      <v-container
        fluid
        id="overlay-exam"
        v-if="openedExam_container"
      ></v-container>

      <v-container fluid id="exam" v-if="openedExam_container">
        <ExamLoaded
          @close-update="closeExamUpdate()"
          @close="closeExam()"
          @update="updateDiscipline()"
          id="openedExam"
          :discipline="discipline"
          :openedExam="openedExam_content"
          :course_id="$router.currentRoute.params.course_id"
          :registration_id="$router.currentRoute.params.registration_id"
        />
      </v-container>

      <ExamError :registration="registration" />
    </div>

    <v-dialog v-model="course_ended" persistent max-width="500px">
      <v-card>
        <v-card-title class="d-flex justify-center">Curso encerrado!</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="$router.push('/cursos')" color="primary">Voltar a página de cursos</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Books from '../components/Discipline/Books'
import Summaries from '../components/Discipline/Summaries'
import VideoLessons from '../components/Discipline/VideoLessons'
import Curiosities from '../components/Discipline/Curiosities'
import ComplementaryMaterial from '../components/Discipline/ComplementaryMaterial'
import Exam from '../components/Discipline/Exam'
import ExamLoaded from '../components/Discipline/Exam/ExamLoaded'
import FaqChannel from '../components/Discipline/FaqChannel'
import CourseResume from '../components/CourseResume'
import Certified from '../components/Certified'
import ComplementaryCourseCertified from '../components/ComplementaryCourseCertified'
import FinalExam from '../components/FinalExam/FinalExam'
import StartMeeting from '../components/StartMeeting'
import ExamError from '@/app/components/ExamError'
import BannerMagazine from '@/app/components/BannerMagazine'
import {
  nextDiscipline,
  disciplinePosition,
  disciplineContent,
  filterDisciplineContent,
  courseDisciplineExams
} from '../utils/Discipline'
import DisciplineLoader from '../utils/DisciplineLoader'
import CourseTimerEnd from '@/app/components/CourseTimerEnd'

export default {
  mixins: [DisciplineLoader],
  components: {
    Books,
    Summaries,
    VideoLessons,
    Curiosities,
    ComplementaryMaterial,
    CourseResume,
    Exam,
    ExamLoaded,
    FaqChannel,
    Certified,
    ComplementaryCourseCertified,
    FinalExam,
    StartMeeting,
    ExamError,
    BannerMagazine,
    CourseTimerEnd
  },
  data: () => ({
    course_ended: false,
    discipline_container: false,
    openedExam_container: false,
    openedExam_content: {},
    loading: {
      discipline: false,
      registration: false,
      course: false,
      finalExam: false,
      exams: false
    },
    changeDisciplineLoaded: true,
    resumo1: false,
    courseResumeReset: true,
    length: 3,
    window: 0,
    course_id: undefined,
    registration_id: undefined,
    discipline: undefined,
    content: undefined,
    exam: undefined,
    mock_exam: undefined,
    registration: [],
    complementary_course_certified: false,
    bottom_nav: '',
    courseResumeDialog: false,
    finalExamDialog: false,
    certifiedDialog: false,
    expiredCertificate: false,
    final_exams: []
  }),
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('course', ['course_loaded'])
  },
  watch: {
    $route () {
      this.$userHistories.end(`Disciplina: ${this.discipline.id}`)
    }
  },
  methods: {
    ...mapActions('course', ['loadCourses', 'selectCourse']),
    openExam (item) {
      this.openedExam_content = { ...item }
      this.discipline_container = false
      this.openedExam_container = true
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    closeExamUpdate () {
      this.updateDiscipline()
      this.closeExam()
    },
    closeExam () {
      this.openedExam_content = {}
      this.discipline_container = true
      this.openedExam_container = false
    },
    async getRegistrationInfo () {
      if (this.show === false) {
        this.$loading.load('Obtendo dados da matrícula...')
      }
      const url = '/student/registration/getRegistrationInfo/'

      let fatherCourseId = null
      let childrenCourseId = null

      if (this.course_loaded.course_father !== null) {
        fatherCourseId = this.course_loaded.course_father.id
        childrenCourseId = this.$router.currentRoute.params.course_id
      } else {
        fatherCourseId = this.$router.currentRoute.params.course_id
      }

      let registrationId = this.$router.currentRoute.params.registration_id

      await this.$api
        .get(`${url}${fatherCourseId}/${childrenCourseId}/${registrationId}`)
        .then((res) => {
          this.registration = res.data
        })
        .finally(() => {
          this.$loading.loaded()
          this.discipline_container = true
        })
    },
    async findCourse () {
      if (this.show === false) {
        this.$loading.load('Carregando informações do curso...')
      }
      let url = '/student/find_student_course/'
      await this.$api
        .get(
          `${url}${this.$router.currentRoute.params.course_id}/${this.$router.currentRoute.params.registration_id}`
        )
        .then((res) => {
          this.selectCourse(res.data)
        })
    },
    async getFinalExamBlocks () {
      await this.$api(
        `/student/final_exam_blocks/${this.course_loaded.course_id}/${this.course_loaded.registration_id}`
      )
        .then((res) => {
          this.final_exams = res.data
        })
        .finally(() => {
          this.$loading.loaded()
        })
    },
    async nextDiscipline () {
      if (this.show === false) {
        this.$loading.load('Carregando Disciplina...')
      }
      await nextDiscipline(
        this.$api,
        this.$router.currentRoute.params.course_id,
        this.$router.currentRoute.params.registration_id
      ).then((res) => {
        this.discipline = res.data
      })
    },
    async disciplineContent (disciplineId) {
      await disciplineContent(
        this.$api,
        this.$router.currentRoute.params.course_id,
        disciplineId,
        this.$router.currentRoute.params.registration_id
      ).then((res) => {
        this.content = filterDisciplineContent(res.data)
      })
    },
    async courseDisciplineExams (disciplineId) {
      if (this.show === false) {
        this.$loading.load('Carregando provas...')
      }
      await courseDisciplineExams(
        this.$api,
        this.$router.currentRoute.params.course_id,
        disciplineId,
        this.$router.currentRoute.params.registration_id
      )
        .then((res) => {
          this.exam = res.data.exam
          this.mock_exam = res.data.mock_exam
        })
        .finally(() => {
          this.changeDisciplineLoaded = true
          this.$loading.loaded()
        })
    },
    changeDiscipline (position, showMessage) {
      if (this.show === false || showMessage === true) {
        this.$loading.load('Trocando de disciplina...')
      }
      disciplinePosition(
        this.$api,
        this.$router.currentRoute.params.course_id,
        position,
        this.$router.currentRoute.params.registration_id
      )
        .then((res) => {
          this.changeDisciplineLoaded = false
          if (res.data.access === false) {
            this.$snackbar.error(
              'Esta disciplina ainda está bloqueado! <br /> Conclua a disciplina anterior para acessar.'
            )
            this.$loading.loaded()
          } else {
            this.discipline = res.data
            this.updateDiscipline()
          }
        })
        .finally(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })

          this.courseResumeDialog = false
        })
    },
    updateDiscipline () {
      this.courseResumeReset = false
      this.disciplineContent(this.discipline.discipline_id)
      this.courseDisciplineExams(this.discipline.discipline_id)

      setTimeout(() => {
        this.courseResumeReset = true
      }, 0)
    }
  },
  mounted () {
    console.log(!(this.course_loaded.name).search('(Prisma Club)') !== -1)
  }
}
</script>

<style scoped>
#overlay-exam {
  z-index: 1000;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
}

#exam {
  z-index: 1001;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
</style>
