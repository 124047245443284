<template slot="content">
  <span>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-data-iterator
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      hide-default-footer
      no-data-text="Ops! Parece que não tem nada aqui :("
      no-results-text="Ops! Parece que não tem nada aqui :("
      style="padding-left: 0px; padding-right: 20px"
    >
      <template v-slot:header>
        <v-toolbar dark color="primary lighten-1">
          <v-text-field
            v-model="search"
            append-outer-icon="mdi-clear"
            hide-details
            prepend-inner-icon="mdi-search-web"
            :label="search_label"
            autocomplete="off"
          ></v-text-field>

          <slot name="mainHeader" />
        </v-toolbar>
        <slot name="secondayHeader" />
        <br />
        <br />
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <slot name="table" :items="props.items"> </slot>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="center">
          <span class="grey--text" style="padding-left: 15px"
            >Itens por página</span
          >
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn dark text color="primary" class="ml-2" v-on="on">
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <div style="padding-right: 30px; margin-top: 5px">
            <v-col xs="12">
              <span class="mr-4 grey--text"
                >Página {{ page }} de {{ numberOfPages }}</span
              >
            </v-col>

            <v-col xs="12">
              <v-btn fab dark color="primary" class="mr-2" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn fab dark color="primary" class="ml-2" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </div>
        </v-row>
      </template>
    </v-data-iterator>
  </span>
</template>

<script>
export default {
  props: {
    apiURL: { type: String, default: '' },
    itemsPerPageArray: { type: Array, default: () => [5] },
    itemsPerPageValue: { type: Number, default: 5 },
    search_label: { type: String, default: 'Pesquisar' },
    update: { type: Boolean, default: false },
    filters: { type: Array, default: () => [] }
  },
  data: () => ({
    loading: true,
    keydown: false,
    words: 0,
    clearTimeout: null,
    search: '',
    itemsPerPage: 10,
    page: 1,
    request: null,
    items: []
  }),
  watch: {
    update (val) {
      if (val) {
        this.getData()
      }
    },
    search (val) {
      this.words = val.length
      if (val.length >= 2 || val.length === 0) {
        this.clearTimeout = setTimeout(() => {
          if (this.words === val.length) {
            this.getData()
          }
        }, 750)
      }
    }
  },
  computed: {
    numberOfPages () {
      return this.request !== null ? this.request.last_page : 1
    },
    filteredKeys () {
      return this.keys.filter((key) => key !== `Name`)
    }
  },
  methods: {
    async getData () {
      let filters = ''

      for (let item of this.filters) {
        filters += `&${item}`
      }

      await this.$api
        .get(
          this.apiURL +
            `?page=${this.page}&itemsPerPage=${this.itemsPerPage}&q=${this.search}` + filters
        )
        .then((res) => {
          this.request = res.data
          this.items = this.request.data
          this.$emit('data', this.items)
        })
        .finally(() => {
          this.loading = false
        })
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) {
        this.page += 1
        this.loading = true
        this.getData()

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    },
    formerPage () {
      if (this.page - 1 >= 1) {
        this.page -= 1
        this.loading = true
        this.getData()

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
      this.loading = true
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      this.getData()
    }
  },
  beforeMount () {
    this.itemsPerPage = this.itemsPerPageValue
    this.getData()
  }
}
</script>
