<template>
  <v-row>
    <v-col
      class="d-flex justify-center mt-5"
      cols="12"
    >
      <div
        style="border: 10px solid #ccc; border-radius: 15px; width: 300px"
        class="d-flex justify-center text-center title"
      >
        <v-row>
          <v-col cols="12">
            <span class="font-weight-bold">NOTA: {{ getNote() }}</span>
          </v-col>

          <v-col cols="12">
            <span
              >ACERTOS:
              {{
                openedExam.content_already_finished.attempt_info.correct_answers
              }}
              de
              {{
                openedExam.content_already_finished.attempt_info
                  .total_correct_answers
              }}</span
            >
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['openedExam'],
  methods: {
    getNote () {
      const note = (
        this.openedExam.content_already_finished.attempt_info
          .percentage_correct_answers / 10
      ).toString()

      let newNote = note.split('.')

      if (newNote.length === 1) {
        newNote = note
      }

      if (parseInt(newNote[1]) === 5) {
        newNote = `${parseInt(newNote[0])}.${parseInt(newNote[1])}`
      }

      if (parseInt(newNote[1]) < 5) {
        newNote = newNote[0]
      }

      if (parseInt(newNote[1]) > 5) {
        newNote = parseInt(newNote[0]) + 1
      }

      return note === '10' ? note : newNote
    }
  }
}
</script>
