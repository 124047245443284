<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title class="d-flex justify-center"
        >Ops! Parece que sua internet está fraca!</v-card-title
      >
      <v-card-text>
        <p class="text-center">
          Clique em "TENTAR NOVAMENTE" após o cronômetro abaixo ou tente novamente mais tarde.
        </p>

        <div class="d-flex justify-center">
          <v-btn text color="primary" class="display-1 font-weight-bold">
            {{ time | timer }}
          </v-btn>
        </div>

        <br />

        <div class="d-flex justify-center">
          <v-btn
            :disabled="time > 0"
            color="primary"
            @click.stop="retryAccess()"
            >Tentar novamente</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

export default {
  data: () => ({
    dialog: false,
    time: 0,
    retry: 1,
    interval: null
  }),
  filters: {
    timer (val) {
      return moment.utc(val * 1000).format('HH:mm:ss')
    }
  },
  computed: {
    ...mapState('Exam', ['errorInExam'])
  },
  watch: {
    errorInExam (val) {
      if (val === true) {
        this.dialog = val
        this.time = 30 * this.retry
        this.retry++
        this.interval = setInterval(() => {
          if (this.time !== 0) {
            this.time--
          } else if (this.time === 0) {
            clearInterval(this.interval)
          }
        }, 1000)
      }
    }
  },
  methods: {
    ...mapActions('Exam', ['setErrorInExam']),
    retryAccess () {
      if (this.$pingModule.checkAccessInternet() && this.$pingModule.getPing() < 800) {
        this.dialog = false
        this.setErrorInExam(false)
      } else {
        this.time = 30 * this.retry
        this.retry++
        this.interval = setInterval(() => {
          if (this.time !== 0) {
            this.time--
          } else if (this.time === 0) {
            clearInterval(this.interval)
          }
        }, 1000)
      }
    }
  }
}
</script>
