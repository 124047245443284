import moment from 'moment'

export default {
  data: () => ({
    courseBeforeStart_dialog: false,
    courseAfterEnd_dialog: false,
    contract_dialog: false
  }),
  methods: {
    checkCourse(item) {
      this.loading = true
      // this.course_selected = { ...item }
      this.selectCourse(item)

      if (this.course_loaded.block_student === 1) {
        this.loading = false
        return this.$snackbar.error('Seu curso está bloqueado.')
      }

      if (this.course_loaded.name === 'Prisma Club') {
        this.checkContract(item)
        return
      }

      if (
        moment().isAfter(`${this.course_loaded.start_content} 00:00:00`) &&
        this.course_loaded.has_course_class === 0
      ) {
        this.checkContract(item)
        this.loading = false
      }

      if (
        moment().isBefore(`${this.course_loaded.start_content} 00:00:00`) &&
        this.course_loaded.course_type === 1
      ) {
        this.checkContract(this.course_loaded)
        this.loading = false
      }

      if (
        (moment().isBefore(`${this.course_loaded.start_content} 00:00:00`) &&
          this.course_loaded.course_type === 2) ||
        (moment().isBefore(`${this.course_loaded.end} 00:00:00`) &&
          this.course_loaded.course_type === 2)
      ) {
        this.loading = false
        this.checkContract(this.course_loaded)
        // return this.$router.push({
        //   name: 'Disciplinas',
        //   params: {
        //     course_id: this.course_loaded.course_id,
        //     registration_id: this.course_loaded.registration_id
        //   }
        // }).catch(() => {})
      }

      // if ((moment().isBefore(`${item.start} 00:00:00`) && item.course_type === 2) || (moment().isBefore(`${item.end} 00:00:00`) && item.course_type === 2)) {
      //   console.log(this.courses.map(item => item.course_id))
      //   let fatherId = item.course_id_father
      //   console.log(this.courses.find(item => item.course_id === fatherId))
      //   return
      // }

      if (
        moment().isAfter(`${this.course_loaded.start_content} 00:00:00`) &&
        moment().isBefore(`${this.course_loaded.end} 00:00:00`) &&
        this.course_loaded.course_type === 1
      ) {
        this.checkContract(this.course_loaded)
        this.loading = false
      }

      if (moment().isAfter(`${this.course_loaded.end} 00:00:00`)) {
        this.loading = false
        this.courseAfterEnd_dialog = true
      }
    }
  }
}
