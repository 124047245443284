<template>
  <v-dialog max-width="1000px" v-model="dialog" persistent>
    <v-card>
      <v-card-title class="d-flex justify-center display-1"
        >AVISO!</v-card-title
      >
      <v-card-text>
        <p class="title text-center mt-3">
          VOCÊ NÃO ENCERROU SUA PROVA CORRETAMENTE, PORTANTO SUA PROVA FICOU
          ZERADA ENTRE EM CONTATO COM pedagogia@ceabbrasil.com.br solicitando
          liberação.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.stop="open()">Entrar em contato.</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['registration'],
  data: () => ({
    dialog: false
  }),
  methods: {
    open () {
      this.dialog = false
      let email = `pedagogia@ceabbrasil.com.br`
      let subject = 'Prova encerrada incorretamente.'
      window.open(`mailto:${email}?subject=${subject}`)
    }
  },
  mounted () {
    this.dialog = this.registration.exam_error
  }
}
</script>
