import axios from 'axios'

export default {
  namespaced: true,
  state: {
    ip: '',
    interval: null
  },
  getters: {
    //
  },
  mutations: {
    updateIp (state) {
      axios.get('https://www.cloudflare.com/cdn-cgi/trace').then(res => {
        state.ip = res.data
          .replace(/=/g, ': ')
          .split('\n')[2]
          .split(': ')[1]
      })
      clearInterval(state.interval)
      state.interval = setInterval(() => {
        axios.get('https://www.cloudflare.com/cdn-cgi/trace').then(res => {
          state.ip = res.data
            .replace(/=/g, ': ')
            .split('\n')[2]
            .split(': ')[1]
        })
      }, 300000)
    }
  },
  actions: {
    updateIp ({ commit }) {
      commit('updateIp')
    }
  }
}
