export const secondsToTime = (seconds) => {
  return [
    parseInt(seconds / 60 / 60),
    parseInt(seconds / 60 % 60),
    parseInt(seconds % 60)
  ]
    .join(':')
    .replace(/\b(\d)\b/g, '0$1')
}

export const timeToSeconds = (hms) => {
  var time = hms.split(':')
  return (+time[0]) * 60 * 60 + (+time[1]) * 60 + (+time[2])
}

export const increaseTime = (hms, add) => {
  return hms === 'now'
    ? secondsToTime(timeToSeconds(new Date().toLocaleString().slice(11, 19)) + timeToSeconds(add))
    : secondsToTime(timeToSeconds(hms) + timeToSeconds(add))
}

export const decreaseTime = (hms, add) => {
  return hms === 'now'
    ? secondsToTime(timeToSeconds(new Date().toLocaleString().slice(11, 19)) - timeToSeconds(add))
    : secondsToTime(timeToSeconds(hms) - timeToSeconds(add))
}

export const TimeUTC = () => {
  const date = new Date()
  const UTCSeconds = (Math.floor(date.getTime() / 1000) + date.getTimezoneOffset() * 60)
  return new Date(UTCSeconds * 1000).toLocaleString()
}

export const TimeUTCSeconds = () => {
  const date = new Date()
  const UTCSeconds = (Math.floor(date.getTime() / 1000) + date.getTimezoneOffset() * 60)

  return UTCSeconds
}
