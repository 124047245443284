<template>
  <div>
    <v-container style="margin-top: 50px">
      <v-card>
        <v-row>
          <v-col cols="12" lg="3" md="3" sm="12">
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <img
                  v-if="userData.personal_data.photo"
                  :src="$storage + userData.personal_data.photo"
                  :width="$vuetify.breakpoint.smAndDown ? '35%' : '75%'"
                />
                <img
                  v-else
                  src="../assets/avatar-big.png"
                  :width="$vuetify.breakpoint.smAndDown ? '35%' : '75%'"
                />
              </v-col>

              <v-col cols="12" class="d-flex justify-center body-2">
                <span>
                  <b
                    >{{ userData.personal_data.first_name }}
                    {{ userData.personal_data.last_name }}</b
                  >
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            lg="9"
            md="9"
            sm="12"
            style="border-left: 1px solid #ccc"
          >
            <v-row>
              <v-col
                cols="12"
                :class="
                  $vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''
                "
              >
                <v-btn
                  v-if="!isEditing"
                  :style="
                    $vuetify.breakpoint.smAndDown
                      ? ''
                      : 'float: right; margin-right: 25px; margin-top: -5px'
                  "
                  color="primary"
                  @click.stop="editProfile()"
                  >Editar Perfil</v-btn
                >
                <span
                  v-if="!$vuetify.breakpoint.smAndDown"
                  class="title"
                  style="margin-left: 10px"
                  >Informações do Perfil</span
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-center"
                v-if="$vuetify.breakpoint.smAndDown"
              >
                <span class="title" style="margin-left: 10px"
                  >Informações do Perfil</span
                >
              </v-col>

              <v-col cols="12" v-if="!isEditing && userData">
                <v-container>
                  <span class="body-1 d-flex justify-center">
                    <b>Dados Pessoais</b>
                  </span>
                  <center>
                    <v-divider width="30%"></v-divider>
                  </center>
                  <span class="body-2 mt-2">
                    <b>Nome</b>
                    : {{ userData.personal_data.first_name }}
                  </span>
                  <br />
                  <span class="body-2">
                    <b>Sobrenome</b>
                    : {{ userData.personal_data.last_name }}
                  </span>
                  <br />
                  <span class="body-2">
                    <b>CPF</b>
                    : {{ userData.cpf }}
                  </span>
                  <br />
                  <span class="body-2">
                    <b>Email</b>
                    : {{ userData.email }}
                  </span>
                  <br />
                  <span class="body-2">
                    <b>Telefone</b>
                    : {{ userData.personal_data.phone }}
                  </span>
                  <br />
                  <span class="body-2">
                    <b>Data de nascimento</b>
                    : {{ userData.personal_data.birthday | date }}
                  </span>
                  <br />

                  <span class="body-1 d-flex justify-center mt-5">
                    <b>Endereço</b>
                  </span>
                  <center>
                    <v-divider width="30%"></v-divider>
                  </center>
                  <span class="body-2 mt-2">
                    <b>CEP</b>
                    : {{ userData.address.cep }}
                  </span>
                  <br />
                  <span class="body-2">
                    <b>Estado (UF)</b>
                    : {{ userData.address.state }}
                  </span>
                  <br />
                  <span class="body-2">
                    <b>Cidade</b>
                    : {{ userData.address.city }}
                  </span>
                  <br />
                  <span class="body-2">
                    <b>Bairro</b>
                    : {{ userData.address.neighborhood }}
                  </span>
                  <br />
                  <span class="body-2">
                    <b>Logradouro</b>
                    : {{ userData.address.street }}
                  </span>
                  <br />
                  <span class="body-2">
                    <b>Número da Casa</b>
                    : {{ userData.address.house_number }}
                  </span>
                  <br />
                  <span class="body-2">
                    <b>Complemento</b>:
                    <span v-if="userData.address.complement !== null">{{
                      userData.address.complement
                    }}</span>
                    <span v-else>Sem informações especificadas</span>
                  </span>
                  <br />
                </v-container>
              </v-col>

              <v-col cols="12" v-else>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <span class="body-1 d-flex justify-center">
                        <b>Dados Pessoais</b>
                      </span>
                      <center>
                        <v-divider width="40%"></v-divider>
                      </center>
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        label="Nome"
                        v-model="personal_data.first_name"
                      />
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <TextField
                        label="Sobrenome"
                        v-model="personal_data.last_name"
                      />
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        label="CPF"
                        v-model="profile.cpf"
                        disabled
                      />
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        label="Email"
                        v-model="profile.email"
                        disabled
                      />
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <v-col cols="12">
                        <Phone
                          style="margin-top: -24px"
                          :item="{
                            ddi: profile.personal_data.ddi,
                            number: profile.personal_data.phone,
                          }"
                          @output="setPhone($event)"
                          @enter="edit()"
                        />
                      </v-col>
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <Date
                        v-model="profile.personal_data.birthday"
                        label="Data de nascimento"
                      />
                    </v-col>

                    <v-col cols="12">
                      <span class="body-1 d-flex justify-center">
                        <b>Endereço</b>
                      </span>
                      <center>
                        <v-divider width="30%"></v-divider>
                      </center>
                    </v-col>

                    <v-col cols="12" md="4" sm="12">
                      <CEP
                        label="CEP"
                        v-model="profile.address.cep"
                        @find="setAddress($event)"
                      />
                    </v-col>

                    <v-col cols="12" md="4" sm="12">
                      <State
                        label="Estado (UF)"
                        v-model="profile.address.state"
                      />
                    </v-col>

                    <v-col cols="12" md="4" sm="12">
                      <City label="Cidade" v-model="profile.address.city" />
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <Neighborhood
                        label="Bairro"
                        v-model="profile.address.neighborhood"
                      />
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <Street
                        label="Logradouro"
                        v-model="profile.address.street"
                      />
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <HouseNumber
                        label="Número da Casa"
                        v-model="profile.address.house_number"
                      />
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <Complement
                        label="Complemento"
                        v-model="profile.address.complement"
                      />
                    </v-col>

                    <v-col cols="12">
                      <Photo @output="img_base64 = $event" />
                    </v-col>

                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>

                    <v-col cols="12">
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="isEditing"
                        style="
                          float: right;
                          margin-right: 25px;
                          margin-top: -5px;
                        "
                        color="success"
                        @click.stop="edit()"
                        >Salvar</v-btn
                      >
                      <v-btn
                        v-if="isEditing"
                        style="
                          float: right;
                          margin-right: 25px;
                          margin-top: -5px;
                        "
                        color="primary"
                        @click.stop="isEditing = false"
                        >voltar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TextField from '@/shared/components/text-fields/text-field'
import Phone from '@/shared/components/text-fields/PhoneDDI'
import CEP from '@/shared/components/text-fields/Address/CEP'
import State from '@/shared/components/text-fields/Address/State'
import City from '@/shared/components/text-fields/Address/City'
import Neighborhood from '@/shared/components/text-fields/Address/Neighborhood'
import Street from '@/shared/components/text-fields/Address/Street'
import HouseNumber from '@/shared/components/text-fields/Address/HouseNumber'
import Complement from '@/shared/components/text-fields/Address/Complement'
import Photo from '@/shared/components/text-fields/Photo'
import Date from '@/shared/components/text-fields/Date'
import moment from 'moment'

export default {
  components: {
    TextField,
    Phone,
    CEP,
    State,
    City,
    Neighborhood,
    Street,
    HouseNumber,
    Complement,
    Photo,
    Date
  },
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y')
    }
  },
  data: () => ({
    isEditing: false,
    profile: {},
    personal_data: {},
    img_base64: null
  }),
  computed: {
    ...mapState('user', ['userData'])
  },
  methods: {
    ...mapActions('user', ['updateUser']),
    setPhone (payload) {
      this.profile.personal_data.ddi = payload.ddi
      this.profile.personal_data.phone = payload.number
    },
    setAddress (payload) {
      this.profile.address.state = payload.state
      this.profile.address.city = payload.city
      this.profile.address.neighborhood = payload.neighborhood
      this.profile.address.street = payload.street
    },
    editProfile () {
      Object.assign(this.profile, this.userData)
      Object.assign(this.personal_data, this.userData.personal_data)
      this.isEditing = true
    },
    edit () {
      this.$api
        .patch('/profile/update', {
          personal_data: {
            first_name: this.personal_data.first_name,
            last_name: this.personal_data.last_name,
            full_name: `${this.personal_data.first_name} ${this.personal_data.last_name}`,
            photo: this.img_base64,
            ddi: this.profile.personal_data.ddi,
            phone: this.profile.personal_data.phone,
            birthday: this.profile.personal_data.birthday
          },
          address: {
            country: this.profile.address.country,
            city: this.profile.address.city,
            state: this.profile.address.state,
            cep: this.profile.address.cep,
            neighborhood: this.profile.address.neighborhood,
            street: this.profile.address.street,
            house_number: this.profile.address.house_number,
            complement: this.profile.address.complement
          }
        })
        .then((res) => {
          this.updateUser()
          this.$snackbar.success('Perfil editado com sucesso!')
          if (this.img_base64 !== '') {
            setTimeout(() => {
              window.location.reload()
            }, 500)
          }

          this.isEditing = false
        })
    }
  },
  beforeMount () {
    this.$userHistories.setHistory({
      id: `Edição de Perfil`,
      text: `Abriu a página do Perfil`,
      tag: 'Plataforma'
    })
    this.$userHistories.start(`Edição de Perfil`)
  },
  beforeDestroy () {
    this.$userHistories.end(`Edição de Perfil`)
  }
}
</script>
