<template>
  <v-form>
    <v-card v-if="startedPayment === false">
      <v-card-title>Ceab Créditos</v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <p class="text-center">
          Mínimo de compra: 20,00 Ceab Créditos equivalente a 20 reais.
        </p>
        <p class="text-center mb-5" style="margin-top: -10px">
          Máximo de compra: 5.000,00 Ceab Créditos equivalente a 5 mil reais.
        </p>
        <TextField
          type="number"
          v-model="credits"
          label="Digite quantos ceab créditos precisa: "
          :min="1"
          :max="4"
          :counter="4"
          mask-data="#####"
        />
        Valor a pagar: {{ credits | currency }}
        <v-radio-group v-model="paymentMethod" :mandatory="false">
          <v-radio label="Cartão de Crédito" :value="1"></v-radio>
          <v-radio label="Boleto" :value="2"></v-radio>
        </v-radio-group>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="!isValid" @click.stop="startPayment()"
          >Fazer pedido</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.stop="closeDialog()">Fechar</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-card v-if="paymentMethod === 1 && startedPayment === true">
      <v-card-title></v-card-title>
      <v-card-text>
        <CreditCard
          :selected="{
            cost: credits * 100,
            installments: installmentRules,
          }"
          :extend="true"
          @output="creditCard = $event"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" x-large @click.stop="payment()"
          >Realizar pagamento</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" x-large @click.stop="back()">Voltar</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="successDialog" max-width="500px" persistent>
      <v-card>
        <v-card-text>
          <br />
          <v-row
            style="
              background-color: #4caf50;
              border: 1px solid #66bb6a;
              border-radius: 5px;
            "
          >
            <v-col cols="2" class="d-flex justify-center">
              <v-icon x-large dark>mdi-check-circle-outline</v-icon>
            </v-col>
            <v-col cols="10" class="d-flex justify-center">
              <p class="body-2" style="color: #fff">
                Pagamento realizado com sucesso! Estamos processando seu
                pagamento e isso pode levar até 40 minutos para suas moedas
                serem creditadas na sua conta.
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text large color="primary" @click.stop="closeDialog()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import TextField from '@/shared/components/text-fields/text-field'
import CreditCard from '@/shared/components/Card/CreditCard'

export default {
  components: { CreditCard, TextField },
  data: () => ({
    credits: 0,
    creditCard: null,
    paymentMethod: null,
    startedPayment: false,
    successDialog: false
  }),
  computed: {
    installmentRules () {
      var installment

      if (this.credits < 100) {
        installment = 1
      }

      if (this.credits >= 200) {
        installment = 7
      }

      if (this.credits < 200 && this.credits >= 100) {
        installment = 2
      }

      return installment
    },
    isValid () {
      return (
        this.credits >= 20 &&
        this.credits <= 5000 &&
        this.paymentType !== null &&
        this.credits.split('')[0] > 0
      )
    }
  },
  watch: {
    creditCard (val) {
      console.log(val)
    }
  },
  methods: {
    startPayment () {
      if (this.paymentMethod === 1) {
        this.startedPayment = true
      }

      if (this.paymentMethod === 2) {
        this.payment()
      }
    },
    async payment () {
      this.$loading.load('Prcessando pagamento...')
      if (this.paymentMethod === 1) {
        const url = '/student/credits/transactions/credit_card'

        const params = {
          credits: this.credits,
          cardName: this.creditCard.cardName,
          cardNumber: this.creditCard.cardNumber,
          cardMonth: this.creditCard.cardMonth,
          cardYear: this.creditCard.cardYear,
          cvv: this.creditCard.cvv,
          installmentCount: this.credits > 200 ? this.creditCard.installment : 1,
          name: this.creditCard.name,
          email: this.creditCard.email,
          cpf: this.creditCard.cpf,
          phone: this.creditCard.phone,
          cep: this.creditCard.cep
        }

        await this.$api
          .post(url, params)
          .then((res) => {
            if (res.data.error) {
              this.$snackbar.error(res.data.error)
            }

            if (res.data.response && res.data.response.status === 'CONFIRMED') {
              this.closeDialog()
              this.successDialog = true
              this.$snackbar.success(
                'Pagamento realizado com sucesso! Estamos processando seu pagamento e isso pode levar até 40 minutos para suas moedas serem creditadas na sua conta.'
              )
            }
          })
          .finally(() => {
            this.$loading.loaded()
          })
      }

      if (this.paymentMethod === 2) {
        const url = '/student/credits/transactions/billet'

        const params = {
          credits: this.credits
        }

        await this.$api
          .post(url, params)
          .then((res) => {
            window.open(res.data.billet_url)
          })
          .finally(() => {
            this.$loading.loaded()
          })
      }
    },
    closeDialog () {
      this.credits = 0
      this.paymentMethod = null
      this.creditCard = null
      this.startedPayment = false
      this.successDialog = false
      this.$emit('close')
    },
    back () {
      this.startedPayment = false
    }
  }
}
</script>
