<template>
  <v-text-field
    v-bind="bind"
    @input="input"
    :rules="[rules.required, rules.length(3)]"
    color="primary"
    label="Bairro"
    required
    @keyup.enter="$emit('enter', true)"
  ></v-text-field>
</template>

<script>
import TextFieldDefaultMixin from '../Mixins/textfield-default-mixin'

export default {
  props: ['value'],
  mixins: [TextFieldDefaultMixin],
  data: () => ({
    model: '',
    rules: {
      length: len => v => (v || '').length >= len || `Mínimo ${len} caracteres`,
      required: v => !!v || 'Este campo é obrigatório!'
    }
  })
}
</script>
