<template>
  <div>
    <v-menu :close-on-content-click="false" :nudge-width="200" :nudge-right="10" offset-x>
      <template v-slot:activator="{ on }">
        <v-img width="200px" v-on="on" src="@/app/assets/curiosidades.png"></v-img>
      </template>

      <v-card>
        <v-card-title rounded class="body-1 d-flex justify-center">Curiosidades</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, index) in curiosities"
                :key="index"
                @click="openCuriosity(item)"
              >
                <v-list-item-icon>
                  <span v-if="item.content_already_finished">
                    <img src="@/shared/assets/success.png" width="15px" />
                  </span>
                  <v-icon v-else>mdi-table-of-contents</v-icon>
                </v-list-item-icon>
                <v-list-item-content color="red">
                  <span class="body-2">{{ item.name }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-dialog v-model="video" max-width="1360" v-if="selected !== undefined">
      <v-card>
        <v-card-title v-if="$vuetify.breakpoint.smAndDown">
          <v-btn
            @click="loadMaterial()"
            :disabled="selected.curiosity.file === null"
            large
            color="primary"
            class="mr-5"
            elevation="1"
          >Baixar Material</v-btn>

          <v-spacer></v-spacer>

          <v-btn fab small elevation="1" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title class="ml-5">
          <p style="word-break: break-word">{{ selected.name }}</p>
          <v-spacer></v-spacer>

          <v-btn
            v-if="!$vuetify.breakpoint.smAndDown"
            @click="loadMaterial()"
            :disabled="selected.curiosity.file === null"
            large
            color="primary"
            class="mr-5"
            elevation="1"
          >Baixar Material</v-btn>

          <v-btn v-if="!$vuetify.breakpoint.smAndDown" fab small elevation="1" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title class="d-flex justify-center">
          <span
            class="font-weight-bold primary--text"
            v-if="selected.content_already_finished === false && time > 0"
          >{{ time | hms }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <div class="embed-container" v-if="selected.curiosity.video_local === 2">
              <iframe
                :src="`https://player.vimeo.com/video/${selected.curiosity.url}?autoplay=1&loop=1&background=1`"
                frameborder="0"
                webkitAllowFullScreen
                mozallowfullscreen
                allowfullscreen
              ></iframe>
            </div>

            <div class="embed-container" v-if="selected.curiosity.video_local === 1">
              <iframe
                width="100%"
                height="100%"
                :src="`https://www.youtube.com/embed/${selected.curiosity.url}`"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                webkitAllowFullScreen
                mozallowfullscreen
              ></iframe>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { RegisterProgress } from '../../utils/Discipline'
import * as timer from '@/shared/utils/Timer'
import { mapState } from 'vuex'

export default {
  props: ['discipline', 'curiosities'],
  data: () => ({
    video: false,
    selected: undefined,
    course_id: undefined,
    registration_id: undefined,
    time: 30,
    timerInterval: null
  }),
  filters: {
    hms (val) {
      return timer.secondsToTime(val, 'h:m:s')
    }
  },
  computed: {
    ...mapState('course', ['course_loaded'])
  },
  methods: {
    close () {
      this.$userHistories.end(`Curiosidade: ${this.selected.id}`)
      this.video = false
      clearInterval(this.timerInterval)
      this.time = 30
    },
    openCuriosity (item) {
      this.selected = item
      this.video = true

      this.$userHistories.setHistory({
        id: `Curiosidade: ${this.selected.id}`,
        text: `Abriu a curiosidade: "${this.selected.name}" na disciplina "${this.discipline.name}"`,
        tag: this.course_loaded.name
      })
      this.$userHistories.start(`Curiosidade: ${this.selected.id}`)

      if (item.content_already_finished === false) {
        this.timerInterval = setInterval(() => {
          if (this.time === 0) {
            clearInterval(this.timerInterval)
            RegisterProgress(
              this.$api,
              this.course_id,
              this.registration_id,
              item.discipline_id,
              item
            ).then((res) => {
              if (!res.data.content_already_finished) {
                this.$snackbar.success('Parabéns! Mais um conteúdo acessado!')
                this.$emit('updateDiscipline')
              }
            })
          } else {
            this.time--
          }
        }, 1000)
      }
    },
    loadMaterial () {
      window.open(this.$storage + this.selected.curiosity.file)
    }
  },
  beforeMount () {
    this.course_id = this.$router.currentRoute.params.course_id
    this.registration_id = this.$router.currentRoute.params.registration_id
  }
}
</script>

<style scoped>
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
